import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Container,
  Card,
  Button,
  Form,
  Alert,
} from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "../../utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faBan,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
require("moment/locale/fr");

function Videos({ values, user }) {
  const { t } = useTranslation();

  const [dataResponse, setDataResponse] = useState([]);
  const [update, setUpdate] = useState(true);
  const [videoPost, setVideoPost] = useState({
    postVideoId: null,
    postId: values.postId,
    title: "",
    url: "",
    createdAt: moment().format("YYYY-MM-DD"),
    active: "",
    submit: false,
    eventTime: moment().format("LT"),
  });

  useEffect(() => {
    if (typeof values.postId !== "undefined" && update) {
      axios
        .get(`/news/getPostsVideos/${values.postId}`)
        .then((response) => {
          setDataResponse(response.data.data);
        })
        .catch((error) => console.log(error));
      setUpdate(false);
    }
  }, [values, update, t]);

  const handleActive = (active, postVideoId) => {
    axios
      .put(`/news/postVideo/update`, { active: !active, postVideoId })
      .then((response) => {
        setUpdate(true);
      })
      .catch((error) => console.log(error));
  };

  const handleEdit = (item) => {
    setVideoPost({
      postVideoId: item.postVideoId,
      postId: item.postId,
      title: item.title,
      url: item.url,
      createdAt: moment(item.createdAt).format("YYYY-MM-DD"),
      eventTime: moment(item.createdAt).format("LT"),
      active: item.active,
      submit: false,
    });
  };

  const handleRemove = (postVideoId) => {
    axios
      .delete(`/news/trash/postVideo/${postVideoId}`)
      .then((response) => {
        setVideoPost({
          postVideoId: null,
          postId: values.postId,
          title: "",
          url: "",
          createdAt: moment().format("YYYY-MM-DD"),
          eventTime: moment().format("LT"),
          active: null,
          submit: false,
        });
        setUpdate(true);
      })
      .catch((error) => console.log(error));
  };

  var stateList = [
    { title: "Enabled", value: 1 },
    { title: "Disabled", value: 0 },
  ];

  function youtube_parser(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
  }

  return (
    <Container>
      <Row>
        <Col>
          <h3>{t("Form")}</h3>

          <Button
            className="mt-1 mb-3"
            type="submit"
            variant="primary"
            size="sm"
            disabled={videoPost.postVideoId ? false : true}
            onClick={() =>
              setVideoPost({
                postVideoId: null,
                postId: values.postId,
                title: "",
                url: "",
                createdAt: moment().format("YYYY-MM-DD"),
                eventTime: moment().format("LT"),
                active: null,
                submit: false,
              })
            }
          >
            {t("New")}
          </Button>

          <Formik
            enableReinitialize={true}
            initialValues={videoPost}
            validationSchema={Yup.object().shape({
              title: Yup.string()
                .min(12, t("less_char", { n: 12 }))
                .max(120)
                .required("Title is required"),
              url: Yup.string().max(200),
              createdAt: Yup.date()
                .max(new Date())
                .required("Message is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                console.log(values);
                console.log(
                  `${values.createdAt} ${
                    values.eventTime ? values.eventTime : "00:00"
                  }:00 ${user.utc}`
                );
                const params = {
                  postVideoId: values.postVideoId,
                  postId: values.postId,
                  title: values.title,
                  url: values.url,
                  createdAt: moment(
                    `${values.createdAt} ${
                      values.eventTime ? values.eventTime : "00:00"
                    }:00 ${user.utc}`,
                    "YYYY-MM-DD HH:mm:ss ZZ"
                  ),
                  active: values.active,
                };

                console.log(params);
                if (params.postVideoId === null) {
                  delete params.postVideoId;
                  axios
                    .put(`/news/postVideo/insert`, params)
                    .then((response) => {
                      let data = response.data.data;
                      setVideoPost({
                        postVideoId: data.postVideoId,
                        postId: data.postId,
                        title: data.title,
                        url: data.url,
                        createdAt: moment(data.createdAt).format("YYYY-MM-DD"),
                        eventTime: moment(data.createdAt).format("LT"),
                        active: data.active,
                        submit: false,
                      });
                      setUpdate(true);
                    })
                    .catch((error) => console.log(error));
                } else {
                  axios
                    .put(`/news/postVideo/update`, params)
                    .then((response) => {
                      setUpdate(true);
                    })
                    .catch((error) => console.log(error));
                }
              } catch (error) {
                const message = error.message || "Something went wrong";

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <Form onSubmit={handleSubmit} className="row">
                {errors.submit && (
                  <Alert className="my-3" variant="danger">
                    {errors.submit}
                  </Alert>
                )}
                <Form.Group className="mb-3 col-md-7">
                  <Form.Label>{t("Title")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder={t("Title")}
                    value={values.title}
                    disabled={values.sended ? true : false}
                    isInvalid={Boolean(touched.title && errors.title)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.title && (
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-3 col-md-5">
                  <Form.Label>{t("URL")}</Form.Label>
                  <Form.Control
                    type="url"
                    name="url"
                    placeholder={t("URL")}
                    value={values.url}
                    disabled={values.sended ? true : false}
                    isInvalid={Boolean(touched.url && errors.url)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.url && (
                    <Form.Control.Feedback type="invalid">
                      {errors.url}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-3 col-md-4">
                  <Form.Label>{t("Date")}</Form.Label>
                  <Form.Control
                    type="date"
                    name="createdAt"
                    value={moment(values.createdAt).format("YYYY-MM-DD")}
                    disabled={values.sended ? true : false}
                    isInvalid={Boolean(touched.createdAt && errors.createdAt)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.createdAt && (
                    <Form.Control.Feedback type="invalid">
                      {errors.createdAt}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 col-md-2">
                  <Form.Label>{t("Time")}</Form.Label>
                  <Form.Control
                    type="time"
                    name="eventTime"
                    value={values.eventTime != null ? values.eventTime : ""}
                    disabled={values.sended ? true : false}
                    isInvalid={Boolean(touched.eventTime && errors.eventTime)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.eventTime && (
                    <Form.Control.Feedback type="invalid">
                      {errors.eventTime}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                {values.postVideoId === null ? (
                  <Form.Group className="mb-3 col-md-3">
                    <Form.Label>{t("State")}</Form.Label>
                    <Form.Control
                      as="select"
                      name="active"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.active}
                      aria-label="Default select example"
                      disabled={values.sended ? true : false}
                    >
                      <option>{t("Open this select menu")}</option>
                      {stateList.map((state, index) => {
                        return (
                          <option value={state.value} key={index}>
                            {t(state.title)}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                ) : null}

                <div className="text-center mt-3">
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    disabled={isSubmitting}
                  >
                    {t("Save")}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      {Object.keys(dataResponse).length !== 0 ? (
        <>
          <h3>{t("Videos List")}</h3>
          <Row xs={1} md={2} lg={3} className="g-3 pt-4">
            {dataResponse.map((video, index) => (
              <Col key={index}>
                <Card>
                  <Card.Header>
                    <div className="ratio ratio-16x9">
                      <iframe
                        title="Responsive embed video 16:9"
                        src={`https://www.youtube.com/embed/${youtube_parser(
                          video.url
                        )}`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        frameBorder="0"
                      ></iframe>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>{video.title ? video.title : null}</Card.Title>
                    {/*<Card.Text>{!image.legend ? t("Any legend") : null}</Card.Text>*/}
                  </Card.Body>
                  <Card.Footer>
                    <Row>
                      <Col className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          onClick={() =>
                            handleActive(video.active, video.postVideoId)
                          }
                          variant={
                            video.active
                              ? "outline-success"
                              : "outline-secondary"
                          }
                          className="border-0 rounded"
                        >
                          <FontAwesomeIcon
                            icon={video.active ? faCheckCircle : faBan}
                            fixedWidth
                          />
                        </Button>

                        <Button
                          onClick={() => handleEdit(video)}
                          variant="outline-primary"
                          className="border-0 rounded"
                        >
                          <FontAwesomeIcon icon={faEdit} fixedWidth />
                        </Button>

                        <Button
                          onClick={() => handleRemove(video.postVideoId)}
                          variant="outline-danger"
                          className="border-0 rounded"
                        >
                          <FontAwesomeIcon icon={faTrashAlt} fixedWidth />
                        </Button>
                      </Col>
                    </Row>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      ) : null}
    </Container>
  );
}

export default Videos;
