import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "../../utils/axios";
import { Form } from "react-bootstrap";

const ScopeForm = ({ values, user }) => {
  const { t } = useTranslation();

  const [select, setSelect] = useState(<></>);

  useEffect(() => {
    const handleScope = (notificationId, zoneId, checked) => {
      axios
        .put(`/notifications/setScope`, {
          notificationId: notificationId,
          zoneId,
          checked,
        })
        .then((response) => {
          //console.log(response);
        })
        .catch((error) => console.log(error));
    };

    if (typeof user.customerId !== "undefined") {
      axios
        .get(`/notifications/getScope/${values.notificationId}`)
        .then((response0) => {
          axios
            .get(`/notifications/getScope/customer/${user.customerId}`)
            .then((response) => {
              let a = (
                <Form.Group className="mb-3 col-md-12">
                  <Form.Label>{t("Scope")}</Form.Label>

                  {response.data.data.map((post, index) => {
                    return (
                      <Form.Check
                        key={index}
                        id={post.zoneId}
                        type="checkbox"
                        name="zonesIds"
                        aria-label="Default select example"
                        disabled={values.sended ? true : false}
                        label={t(post.name)}
                        onChange={(e) =>
                          handleScope(
                            values.notificationId,
                            post.zoneId,
                            e.target.checked
                          )
                        }
                        defaultChecked={response0.data.data.includes(
                          post.zoneId
                        )}
                      />
                    );
                  })}
                </Form.Group>
              );

              setSelect(a);
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }
  }, [user, t, values]);

  return select;
};

export default ScopeForm;
