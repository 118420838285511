import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "../../utils/axios";
import { Form } from "react-bootstrap";

const SelectZoneId = ({ values, user, handleBlur, handleChange }) => {
  const { t } = useTranslation();

  const [select, setSelect] = useState(<></>);

  useEffect(() => {
    if (typeof user.customerId !== "undefined") {
      axios
        .get(`/hazards/getZonesList`)
        .then((response) => {
          setSelect(
            <Form.Select
              name="zoneId"
              onBlur={handleBlur}
              onChange={handleChange}
              defaultValue={values.zoneId}
              aria-label="Default select example"
              disabled={values.sended ? true : false}
            >
              <option>{t("Open this select menu")}</option>
              {response.data.data.map((zone, index) => {
                return (
                  <option value={parseInt(zone.zoneId)} key={index}>
                    {t(zone.name)}
                  </option>
                );
              })}
            </Form.Select>
          );
        })
        .catch((error) => {
          setSelect(
            <Form.Select
              defaultValue={values.hazardId}
              aria-label="Default select example"
              disabled={values.sended ? true : false}
            >
              <option>{t("Open this select menu")}</option>
            </Form.Select>
          );
        });
    }
  }, [user, handleBlur, handleChange, t, values]);

  return select;
};

export default SelectZoneId;
