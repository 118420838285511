import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Card, Row, Col, Image } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faPhone,
  faEnvelope,
  faQuoteLeft,
  faQuoteRight,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
require("moment/locale/fr");

function Map({ data, user }) {
  const { t } = useTranslation();
  const [markers, setMarkers] = useState([]);

  const Marker = ({ title, legend, content, icon, lat, lng }) => (
    <Container>
      <Row>
        <Col>
          <Image
            fluid
            src={`https://maps.google.com/mapfiles/ms/micons/${icon}.png`}
          />
        </Col>
      </Row>
    </Container>
  );

  useEffect(() => {
    var d = [];
    var newy = (
      <Marker
        lat={data.latitude}
        lng={data.longitude}
        title={t("Report")}
        content={data.comment}
        icon="red-dot"
        key={data.reportId}
      />
    );

    d = [newy];

    setMarkers(d);
  }, [data, t]);

  const getMapOptions = (maps) => {
    return {
      fullscreenControl: true,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      scrollwheel: false,
      streetViewControl: true,
    };
  };

  const loadMap = (map, maps) => {};

  const getImageName = (generatedAt) => {
    var date = moment.utc(`${generatedAt}`);
    var image = moment(date.toISOString()).unix();
    return image;
  };

  return (
    <Card className="flex-fill w-full">
      <Card.Header>
        <Card.Title>{t("Overview")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row className="mb-3">
          <Col xs={5} className="fs-1">
            <Row>
              <Col xs={2}>
                <FontAwesomeIcon icon={faUser} fixedWidth size="lg" />
              </Col>
              <Col>
                <Row>
                  <Col>
                    {data.firstname} {data.lastname}
                  </Col>
                </Row>
                <Row>
                  <Col className="text-sm">
                    {data.area},{" "}
                    {moment(data.generatedAt, "YYYY-MM-DD HH:mm:ss").format(
                      "llll"
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={2} className="text-center text-sm">
            <Row>
              <Col>
                <a href={`tel: ${data.phone}`} className="fs-1">
                  <FontAwesomeIcon icon={faPhone} fixedWidth />
                </a>
              </Col>
            </Row>
            <Row>
              <Col>{data.phone}</Col>
            </Row>
          </Col>
          <Col xs={3} className="text-center text-sm">
            <Row>
              <Col>
                <a href={`mailto: ${data.email}`} className="fs-1">
                  <FontAwesomeIcon icon={faEnvelope} fixedWidth />
                </a>
              </Col>
            </Row>
            <Row>
              <Col>{data.email}</Col>
            </Row>
          </Col>
        </Row>
        {data.comment !== "null" ? (
          <Row className="blockquote">
            <Col>
              <FontAwesomeIcon icon={faQuoteLeft} fixedWidth /> {data.comment}
              <FontAwesomeIcon icon={faQuoteRight} fixedWidth />{" "}
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col>
            <Image
              fluid
              src={`https://objects.pwenter.io/uploads/${getImageName(
                data.generatedAt
              )}.jpg`}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ height: 500, width: "100%" }}>
              <GoogleMapReact
                options={getMapOptions}
                bootstrapURLKeys={{
                  key: "AIzaSyCF7Np6MtgOFp8Yp8RqYyXAOwOw_YOLns4",
                  language: "fr",
                  region: "fr",
                  libraries: ["visualization"],
                }}
                onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
                defaultCenter={{
                  lat: data.latitude,
                  lng: data.longitude,
                }}
                defaultZoom={16}
                yesIWantToUseGoogleMapApiInternals
              >
                {markers}
              </GoogleMapReact>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default Map;
