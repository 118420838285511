import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import DateTime from "react-datetime";
require("moment/locale/fr");

const Calendar = () => {
  const { t } = useTranslation();
  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        {/*<div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Action</Dropdown.Item>
              <Dropdown.Item>Another Action</Dropdown.Item>
              <Dropdown.Item>Something else here</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
  </div>*/}
        <Card.Title className="mb-0">{t("Calendar")}</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        <div className="align-self-center w-100">
          <DateTime
            input={false}
            defaultValue={DateTime.moment()}
            dateFormat="L"
            timeFormat={false}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default Calendar;
