import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Container, Image, Card, Button } from "react-bootstrap";
import axios from "../../utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faCheckCircle,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

function DragDropFile({ values, user }) {
  const { t } = useTranslation();
  // drag state
  const [dragActive, setDragActive] = useState(false);
  const [dataResponse, setDataResponse] = useState([]);
  const [cover, setCover] = useState(null);
  const [update, setUpdate] = useState(true);
  // ref
  const inputRef = useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files[0], values.postId);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files[0], values.postId);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleFiles = (file, postId) => {
    const formData = new FormData();
    formData.append("postId", postId);
    formData.append("fileData", file);
    formData.append("cover", dataResponse.length > 0 ? false : true);

    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    axios
      .post(`/news/postGallery`, formData)
      .then((response) => {
        setUpdate(true);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (typeof values.postId !== "undefined" && update) {
      axios
        .get(`/news/getPostGallery/${values.postId}`)
        .then((response) => {
          setDataResponse(response.data.data);

          let cover = response.data.data.filter((image) => image.cover);
          if (typeof cover[0] !== "undefined") {
            setCover(cover[0]);
          }
        })
        .catch((error) => console.log(error));
      setUpdate(false);
    }
  }, [values, update, t]);

  const handleActive = (active, postGalleryId) => {
    axios
      .put(`/news/update/postGallery`, { active: !active, postGalleryId })
      .then(() => {
        setUpdate(true);
      })
      .catch((error) => console.log(error));
  };

  const handleCover = (postGalleryId) => {
    axios
      .put(`/news/update/postGallery`, {
        cover: true,
        postGalleryId,
        currentCover: cover === null ? null : cover.postGalleryId,
      })
      .then(() => {
        setUpdate(true);
      })
      .catch((error) => console.log(error));
  };

  const handleRemove = (postGalleryId) => {
    axios
      .delete(`/news/trash/postGallery/${postGalleryId}`)
      .then((response) => {
        setUpdate(true);
      })
      .catch((error) => console.log(error));
  };

  return (
    <Container>
      <Row>
        <Col>
          <h3>{t("Add an image")}</h3>
          <form
            id="form-file-upload"
            onDragEnter={handleDrag}
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              ref={inputRef}
              type="file"
              id="input-file-upload"
              multiple={false}
              onChange={handleChange}
            />
            <label
              id="label-file-upload"
              htmlFor="input-file-upload"
              className={dragActive ? "drag-active" : ""}
            >
              <div>
                <p>{t("Drag and drop your image here or")}</p>
                <button className="upload-button" onClick={onButtonClick}>
                  {t("Upload an image")}
                </button>
              </div>
            </label>
            {dragActive && (
              <div
                id="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </form>
        </Col>
        <Col>
          <h3>{t("Featured")}</h3>
          {cover !== null ? (
            <Image
              src={cover.url}
              fluid
              thumbnail
              style={{ maxHeight: "260px" }}
            />
          ) : (
            <p>Aucune image de couverture</p>
          )}
        </Col>
      </Row>
      <Row xs={1} md={2} lg={3} className="g-3 pt-3">
        {dataResponse.map((image, index) => (
          <Col key={index}>
            <Card>
              <div
                style={{
                  height: "240px",
                  textAlign: "center",
                  overflow: "hidden",
                }}
              >
                <Card.Img
                  variant="top"
                  src={image.url}
                  style={{
                    objectFit: "cover",
                    minWidth: "100%",
                    minHeight: "100%",
                  }}
                />
              </div>

              <Card.Footer>
                <Row>
                  <Col className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <Button
                      onClick={() =>
                        handleActive(image.active, image.postGalleryId)
                      }
                      variant={
                        image.active ? "outline-success" : "outline-secondary"
                      }
                      className="border-0 rounded"
                    >
                      <FontAwesomeIcon
                        icon={image.active ? faCheckCircle : faBan}
                        fixedWidth
                      />
                    </Button>
                    {image.cover ? null : (
                      <Button
                        onClick={() => handleCover(image.postGalleryId)}
                        variant="outline-warning"
                        className="border-0 rounded"
                      >
                        <FontAwesomeIcon icon={faStar} fixedWidth />
                      </Button>
                    )}
                    <Button
                      onClick={() => handleRemove(image.postGalleryId)}
                      variant="outline-danger"
                      className="border-0 rounded"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} fixedWidth />
                    </Button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default DragDropFile;
