import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
  Alert,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import axios from "../../utils/axios";
import * as Yup from "yup";
import { Formik } from "formik";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck, faCircle } from "@fortawesome/free-regular-svg-icons";

const Navigation = ({ t }) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">{t("Profile Settings")}</Card.Title>
      </Card.Header>
      <ListGroup variant="flush">
        <ListGroup.Item tag="a" href="../account/organization" action>
          {t("Account")}
        </ListGroup.Item>
        <ListGroup.Item tag="a" href="../account/profile" action active>
          {t("User Profile")}
        </ListGroup.Item>
        <ListGroup.Item tag="a" href="../account/password" action>
          {t("Password")}
        </ListGroup.Item>
        <ListGroup.Item tag="a" href="#" action>
          {t("Privacy and safety")}
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

const PublicInfo = ({ userInfo, t, user, onButtonClick, randomId }) => {
  const countries = [
    { code: "GPE", title: "Guadeloupe" },
    { code: "MTQ", title: "Martinique" },
    { code: "GUF", title: "Guyane" },
    { code: "BLM", title: "Saint Barthélémy" },
    { code: "MAF", title: "Saint Martin" },
    { code: "REU", title: "Réunion" },
    { code: "NCL", title: "Nouvelle Calédonie" },
    { code: "FRA", title: "France" },
  ];

  const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">{t("Public info")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          initialValues={userInfo}
          enableReinitialize
          validationSchema={Yup.object().shape({
            firstname: Yup.string()
              .min(3, t("less_char", { n: 3 }))
              .max(120)
              .required("Name is required"),
            lastname: Yup.string()
              .min(3, t("less_char", { n: 3 }))
              .max(120)
              .required("Short Name is required"),
            address: Yup.string()
              .min(12, t("less_char", { n: 12 }))
              .max(256)
              .required("Address is required"),
            postalCode: Yup.string()
              .min(5)
              .max(5)
              .required("Postal Code is required"),
            phone: Yup.string().matches(
              phoneRegExp,
              "Phone number is not valid"
            ),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm }
          ) => {
            try {
              const params = {
                firstname: values.firstname,
                lastname: values.lastname,
                address: values.address,
                email: values.email,
                phone: values.phone,
                city: values.city,
                postalCode: values.postalCode,
                country: values.country,
                active: values.active,
                userId: values.userId,
              };

              if (params.userId === null) {
                delete params.userId;
                axios
                  .put(`/profile/insert`, params)
                  .then((response) => {
                    setErrors({
                      submit: response.data.message,
                      status: "success",
                    });

                    resetForm({
                      values: {
                        ...values,
                        customerId: response.data.data.customerId,
                      },
                    });
                  })
                  .catch((error) => console.log(error));
              } else {
                console.log(params);
                axios
                  .put(`/profile/update`, params)
                  .then((response) => {
                    if (response.status === 200) {
                      setStatus({ success: true });
                      setErrors({
                        submit: response.data.message,
                        status: "success",
                      });
                    }
                  })
                  .catch((error) => {
                    setStatus({ success: false });
                    setErrors({
                      submit: error,
                      status: "danger",
                    });
                    setSubmitting(false);
                  });
              }
            } catch (error) {
              const message = error.message || "Something went wrong";
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit} className="row">
              {errors.submit && (
                <Alert
                  className="my-3 p-2"
                  variant={
                    typeof errors.status === "undefined"
                      ? "danger"
                      : errors.status
                  }
                >
                  {errors.submit}
                </Alert>
              )}

              <Row>
                <Col md={7}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="firstname">
                      {t("Firstname")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="firstname"
                      id="firstname"
                      placeholder={t("Firstname")}
                      value={values.firstname}
                      disabled={isSubmitting}
                      isInvalid={Boolean(touched.firstname && errors.firstname)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="lastname">{t("Lastname")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastname"
                      id="lastname"
                      placeholder={t("Lastname")}
                      value={values.lastname}
                      disabled={isSubmitting}
                      isInvalid={Boolean(touched.lastname && errors.lastname)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="5">
                  <div className="text-center">
                    {typeof user.userId !== "undefined" ? (
                      <img
                        alt={user.userId}
                        src={`https://objects.pwenter.io/users/${user.userId}/images/pp.jpg?cache=${randomId}`}
                        className="rounded-circle img-responsive mt-2"
                        width="192"
                        height="192"
                      />
                    ) : null}
                    <div className="mt-2">
                      <Button
                        variant="primary"
                        onClick={onButtonClick}
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faUpload} /> {t("Upload")}
                      </Button>
                    </div>
                    <small>
                      {t(
                        "For best results, use an image at least 128px by 128px in .jpg format"
                      )}
                    </small>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={5}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="phone">{t("Phone")}</Form.Label>
                    <Form.Control
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder={t("Phone")}
                      value={values.phone}
                      disabled={isSubmitting}
                      isInvalid={Boolean(touched.phone && errors.phone)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} sm={7}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="email">{t("Email")}</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      id="email"
                      placeholder={t("Email")}
                      value={values.email}
                      disabled={isSubmitting}
                      isInvalid={Boolean(touched.email && errors.email)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="address">{t("Address")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      id="address"
                      placeholder=""
                      value={values.address}
                      disabled={isSubmitting}
                      isInvalid={Boolean(touched.address && errors.address)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={3} sm={4}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="postalCode">{t("Zip")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="postalCode"
                      id="postalCode"
                      value={values.postalCode}
                      disabled={isSubmitting}
                      isInvalid={Boolean(
                        touched.postalCode && errors.postalCode
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4} sm={8}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="city">{t("City")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      id="city"
                      value={values.city}
                      disabled={isSubmitting}
                      isInvalid={Boolean(touched.city && errors.city)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={5}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="country">{t("Country")}</Form.Label>
                    <Form.Select
                      name="country"
                      id="country"
                      value={values.country}
                      disabled={isSubmitting}
                      isInvalid={Boolean(touched.country && errors.country)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {countries.map((country, index) => {
                        return (
                          <option value={country.code} key={index}>
                            {country.title}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isSubmitting}
                  >
                    {t("Save")}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

const ZonesCustomer = ({ t, zones, zonesCustomer, userId }) => {
  const [zo, setZo] = useState([]);

  useEffect(() => {
    var zoneLoop = [];
    zonesCustomer.forEach((e) => {
      zoneLoop.push(e.zoneId);
    });

    setZo(zoneLoop);
  }, [setZo, zonesCustomer]);

  const updateZone = (zoneId, active) => {
    axios
      .post(`/profile/zone/update`, { zoneId, active, userId })
      .then((response) => {
        if (response.data.data.length > 0 && response.data.data[0] === 1) {
          // UPDATE
          if (!active) {
            setZo(zo.filter((item) => item !== zoneId));
          } else {
            var zo3 = zo;
            zo3.push(zoneId);
            setZo(zo3);
            setZo(zo3.filter((item) => item !== "r"));
          }
        } else {
          // CREATE
          var zo2 = zo;
          zo2.push(zoneId);
          setZo(zo2);
          setZo(zo2.filter((item) => item !== "r"));
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">{t("Zones")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          {zones !== null &&
            zones.map((z, index) => {
              return (
                <Col key={index}>
                  <Row className="mb-3 fs-2">
                    <Col sm={1}>
                      <FontAwesomeIcon
                        icon={
                          z.zoneId !== 11
                            ? zo.includes(z.zoneId)
                              ? faCircleCheck
                              : faCircle
                            : faCircleCheck
                        }
                        color={
                          z.zoneId !== 11
                            ? zo.includes(z.zoneId)
                              ? "#008000"
                              : "#FF0000"
                            : "#008000"
                        }
                        onClick={
                          z.zoneId !== 11
                            ? () =>
                                updateZone(
                                  z.zoneId,
                                  zo.includes(z.zoneId) ? false : true
                                )
                            : null
                        }
                      />
                    </Col>
                    <Col sm={11}>{z.name}</Col>
                  </Row>
                  <Row>
                    {z.subZones.map((z1, index) => {
                      return (
                        <Col
                          lg={z.zoneId !== 11 ? 6 : 12}
                          md={12}
                          key={index}
                          className="mb-5"
                        >
                          <Row className="mb-3 fs-3">
                            <Col sm={1}>
                              <FontAwesomeIcon
                                icon={
                                  zo.includes(z1.zoneId)
                                    ? faCircleCheck
                                    : faCircle
                                }
                                color={
                                  zo.includes(z1.zoneId) ? "#008000" : "#FF0000"
                                }
                                onClick={() =>
                                  updateZone(
                                    z1.zoneId,
                                    zo.includes(z1.zoneId) ? false : true
                                  )
                                }
                              />
                            </Col>
                            <Col sm={11}>{z1.name}</Col>
                          </Row>

                          <Row className="ps-2">
                            {z1.subZones.map((z2, index) => {
                              return (
                                <Col
                                  md={z.zoneId !== 11 ? 6 : 12}
                                  key={index}
                                  className="pt-3 bg-light rounded-lg mb-4"
                                >
                                  <Row
                                    className={
                                      z.zoneId !== 11
                                        ? "mb-3 text-lg"
                                        : `mb-3 fs-4`
                                    }
                                  >
                                    <Col sm={z.zoneId !== 11 ? 2 : 1}>
                                      <FontAwesomeIcon
                                        icon={
                                          zo.includes(z2.zoneId)
                                            ? faCircleCheck
                                            : faCircle
                                        }
                                        color={
                                          zo.includes(z2.zoneId)
                                            ? "#008000"
                                            : "#FF0000"
                                        }
                                        onClick={() =>
                                          updateZone(
                                            z2.zoneId,
                                            zo.includes(z2.zoneId)
                                              ? false
                                              : true
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col sm={10}>{z2.name}</Col>
                                  </Row>
                                  {typeof z2.subZones !== "undefined" ? (
                                    <Row className="ps-4">
                                      {z2.subZones.map((z3, index) => {
                                        return (
                                          <Col md={4} key={index}>
                                            <Row className="mb-3 text-lg">
                                              <Col sm={z.zoneId !== 11 ? 2 : 1}>
                                                <FontAwesomeIcon
                                                  icon={
                                                    zo.includes(z3.zoneId)
                                                      ? faCircleCheck
                                                      : faCircle
                                                  }
                                                  color={
                                                    zo.includes(z3.zoneId)
                                                      ? "#008000"
                                                      : "#FF0000"
                                                  }
                                                  onClick={() =>
                                                    updateZone(
                                                      z3.zoneId,
                                                      zo.includes(z3.zoneId)
                                                        ? false
                                                        : true
                                                    )
                                                  }
                                                />
                                              </Col>
                                              <Col sm={10}>{z3.name}</Col>
                                            </Row>
                                          </Col>
                                        );
                                      })}
                                    </Row>
                                  ) : null}
                                </Col>
                              );
                            })}
                          </Row>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              );
            })}
        </Row>
      </Card.Body>
    </Card>
  );
};

const Profile = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const inputRef = useRef(null);

  const onButtonClick = () => {
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files[0], user.customerId);
    }
  };

  const [random, setRandom] = useState();

  const handleFiles = (file, customerId) => {
    const formData = new FormData();
    formData.append("customerId", customerId);
    formData.append("fileData", file);

    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    axios
      .post(`/profile/pp`, formData)
      .then((response) => {
        const randomId = function (length = 6) {
          return Math.random()
            .toString(36)
            .substring(2, length + 2);
        };

        setRandom(randomId);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    const randomId = function (length = 6) {
      return Math.random()
        .toString(36)
        .substring(2, length + 2);
    };
    setRandom(randomId);
  }, [setRandom]);

  const [userInfo, setUserInfo] = useState(null);
  const [zonesCustomer, setZonesCustomer] = useState(null);

  useEffect(() => {
    if (typeof user.customerId !== "undefined") {
      axios
        .get(`profile/${user.userId}`)
        .then((response) => {
          if (response.data.data.country !== null) {
            axios
              .get(`profile/zones/FRA`)
              .then((response) => {
                setZonesCustomer(response.data.data);
              })
              .catch((error) => console.log(error));
          }

          setUserInfo(response.data.data);
        })
        .catch((error) => console.log(error));
    }
  }, [user]);

  return (
    <React.Fragment>
      <Helmet title={t("User Profile")} />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{t("User Profile")}</h1>

        <Row>
          <Col md="3" xl="2">
            <Navigation t={t} />
          </Col>
          <Col md="9" xl="10">
            {userInfo !== null ? (
              <PublicInfo
                userInfo={userInfo}
                t={t}
                user={user}
                onButtonClick={onButtonClick}
                randomId={random}
              />
            ) : null}

            {user.levelId > 12 &&
            userInfo !== null &&
            zonesCustomer !== null ? (
              <ZonesCustomer
                t={t}
                zones={zonesCustomer}
                zonesCustomer={userInfo.Zones}
                userId={user.userId}
              />
            ) : null}
          </Col>
          <Col>
            <form id="form-file-upload" onSubmit={(e) => e.preventDefault()}>
              <input
                ref={inputRef}
                type="file"
                id="input-file-upload"
                multiple={false}
                onChange={handleChange}
              />
            </form>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Profile;
