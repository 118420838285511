import React, { useState, useCallback, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, Row, Col, Form, Alert, Button } from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "../../utils/axios";
import SelectHazardId from "./SelectHazardId";
import SelectZoneId from "./SelectZoneId";
import { LoadScript, GoogleMap, Polygon, Marker } from "@react-google-maps/api";

function Map({ values, user }) {
  const { t } = useTranslation();

  const [formType, setFormType] = useState("multipolygon");

  const [itemMap, setItemMap] = useState({
    title: "",
    email: "",
    fillColor: "#563d7c",
    opacity: 50,
    borderColor: "#563d7c",
    borderSize: 1,
    type: "multipolygon",
    hazardIds: [],
    submit: false,
    action: "insert",
    channelId: values.channelId,
  });
  // Store Polygon path in state
  const [path, setPath] = useState([]);
  //const [checked, setChecked] = useState([]);
  const [paramsG, setParamsG] = useState(JSON.parse(values.geoParams));

  const init = (type = "multipolygon") => {
    setPath([]);
    setItemMap({
      title: "",
      email: "",
      fillColor: "#563d7c",
      opacity: 50,
      borderColor: "#563d7c",
      borderSize: 1,
      type: type,
      hazardIds: [],
      submit: false,
      action: "insert",
      channelId: values.channelId,
    });
  };

  const [centerPolygon, setCenterPolygon] = useState(null);

  const [zoneIds2Select, setZoneIds2Select] = useState([]);
  useEffect(() => {
    if (typeof user.customerId !== "undefined") {
      axios
        .get(`/channels/getZones/${user.customerId}`)
        .then((response) => {
          setZoneIds2Select(response.data.data);
        })
        .catch((error) => console.log(error));
    }
  }, [user]);

  const [hazardIds2Select, setHazardIds2Select] = useState([]);
  useEffect(() => {
    if (typeof user.customerId !== "undefined") {
      axios
        .get(`/news/getHazardsList/${user.customerId}`)
        .then((response) => {
          setHazardIds2Select(response.data.data);
        })
        .catch((error) => console.log(error));
    }
  }, [user]);

  const setUpdate = (param, index) => {
    console.log(values.multipolygon);

    /*let multipolygonString = [];
    let poly = [];
    let multi = false;
    if (typeof path[0][0] !== "undefined") {
      path.forEach((p, index) => {
        poly[index] = [...p, p[0]];
      });
      multipolygonString = poly.map((polygon) => [
        ...multipolygonString,
        [polygon.lng, polygon.lat],
      ]);

      poly.forEach((p, index) => {
        multipolygonString[index] = p.map((polygon) => [
          polygon.lng,
          polygon.lat,
        ]);
      });

      multi = true;
    } else {
      poly = [...path, path[0]];
      multipolygonString = poly.map((polygon) => [
        ...multipolygonString,
        [polygon.lng, polygon.lat],
      ]);
    }

    let multipolygon = {
      type: "Polygon",
      coordinates: multi
        ? multipolygonString
        : [multipolygonString.flat(1)],
    };*/

    let polygon = [];

    var multipolygon = [];

    if (values.multipolygon.coordinates.length === 1) {
      console.log("Polygone");
      polygon = values.multipolygon.coordinates[index].slice(0, -1);
    }

    //let multi = false;

    if (values.multipolygon.coordinates.length > 1) {
      console.log("MultiPolygone");

      values.multipolygon.coordinates.forEach((p, index) => {
        //polygon[index] = [...p, p[0]].slice(0, -1);
      });

      /*multipolygon = polygon.map((coordinate) => [
        ...multipolygon,
        { lat: coordinate[1], lng: coordinate[0] },
      ]);*/

      polygon.forEach((p, index) => {
        multipolygon[index] = p.map(
          (coordinate, index) => [{ lat: coordinate[1], lng: coordinate[0] }][0]
        );
      });
      console.log(polygon);
      console.log(multipolygon);

      //multi = true;
    } else {
      multipolygon = polygon.map((coordinate) => [
        ...multipolygon,
        { lat: coordinate[1], lng: coordinate[0] },
      ]);
    }

    setPath(
      multipolygon.flat(1).length > 0 ? multipolygon.flat(1) : param.path
    );

    var hazardIdsList = [];
    /*if (typeof param.hazardIds === "undefined") {
      let hazardIdsRaw = values.Hazards;
      if (hazardIdsRaw.length > 0) {
        hazardIdsList = hazardIdsRaw.map((h) => [
          ...hazardIdsList,
          parseInt(h.hazardId),
        ]);
        hazardIdsList = hazardIdsList.flat();
      }
    } else {*/
    hazardIdsList = param.hazardIds.map((hazardId) => [
      ...hazardIdsList,
      `${hazardId}`,
    ]);
    hazardIdsList = hazardIdsList.flat();
    /* }*/

    //console.log(param.hazardIds);

    setItemMap({
      title: param.title,
      email: param.email,
      fillColor: param.fillColor,
      opacity: param.opacity,
      borderColor: param.borderColor,
      borderSize: param.borderSize,
      hazardIds: hazardIdsList,
      type: "multipolygon",
      submit: false,
      action: "update",
      channelId: values.channelId,
    });
  };

  const FormMarker = () => {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={itemMap}
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .min(3, t("less_char", { n: 3 }))
            .max(60)
            .required("Title is required"),
          email: Yup.string()
            .min(3, t("less_char", { n: 3 }))
            .max(80)
            .required("Email is required"),
          //hazardIds: Yup.object().required("Hazards is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const params = {
              title: values.title,
              email: values.email,
              type: values.type,
              action: values.action,
              hazardIds: values.hazardIds,
              fillColor: values.fillColor,
              opacity: values.opacity,
              borderColor: values.borderColor,
              borderSize: values.borderSize,
            };

            var geoParamsRaw = [];
            geoParamsRaw = [
              ...geoParamsRaw,
              {
                title: values.title,
                email: values.email,
                fillColor: values.fillColor,
                opacity: values.opacity,
                borderColor: values.borderColor,
                borderSize: values.borderSize,
                hazardIds: values.hazardIds,
                path,
              },
            ];

            if (params.action === "update" || params.action === "insert") {
              params.channelId = values.channelId;

              let multipolygonString = [];
              let poly = [];
              let multi = false;
              if (typeof path[0][0] !== "undefined") {
                path.forEach((p, index) => {
                  poly[index] = [...p, p[0]];
                });
                multipolygonString = poly.map((polygon) => [
                  ...multipolygonString,
                  [polygon.lng, polygon.lat],
                ]);

                poly.forEach((p, index) => {
                  multipolygonString[index] = p.map((polygon) => [
                    polygon.lng,
                    polygon.lat,
                  ]);
                });

                multi = true;
              } else {
                poly = [...path, path[0]];
                multipolygonString = poly.map((polygon) => [
                  ...multipolygonString,
                  [polygon.lng, polygon.lat],
                ]);
              }

              let multipolygon = {
                type: "Polygon",
                coordinates: multi
                  ? multipolygonString
                  : [multipolygonString.flat(1)],
              };

              var geoParams2 = geoParamsRaw;

              delete geoParams2.path;
              delete geoParams2.hazardIds;

              let geoParams = JSON.stringify(geoParams2);
              let p = {
                channelId: params.channelId,
                multipolygon,
                geoParams,
                hazardIds: params.hazardIds,
              };

              axios
                .put(`/channels/update`, p)
                .then((response) => {
                  if (response.status === 200) {
                    setParamsG(geoParamsRaw);
                    init();
                    setStatus({ success: true });
                    setErrors({
                      submit: response.data.message,
                      status: "success",
                    });
                  }
                })
                .catch((error) => setErrors({ submit: error }));
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit} className="row">
            {errors.submit && (
              <Alert
                className="my-3 p-2"
                variant={
                  typeof errors.status === "undefined"
                    ? "danger"
                    : errors.status
                }
              >
                {errors.submit}
              </Alert>
            )}
            <Form.Group className="mb-3 col-lg-8 col-md-12">
              <Form.Label>{t("Zone models")}</Form.Label>
              <SelectZoneId
                values={values}
                zoneIds2Select={zoneIds2Select}
                setPath={setPath}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
              {!!touched.zoneId && (
                <Form.Control.Feedback type="invalid">
                  {errors.zoneId}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3 col-lg-4 col-md-12">
              <Form.Label>{t("Title")}</Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder={t("Title")}
                value={values.title}
                disabled={values.sended ? true : false}
                isInvalid={Boolean(touched.title && errors.title)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.title && (
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3 col-lg-3 col-md-12">
              <Form.Label>{t("Email")}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder={t("Email")}
                value={values.email}
                disabled={values.sended ? true : false}
                isInvalid={Boolean(touched.email && errors.email)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3 col-lg-1 col-md-1">
              <Form.Label>{t("Color")}</Form.Label>
              <Form.Control
                name="fillColor"
                type="color"
                id="exampleColorInput"
                title="Choose your color"
                disabled={isSubmitting}
                value={values.fillColor}
                isInvalid={Boolean(touched.fillColor && errors.fillColor)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3 col-md-1">
              <Form.Label>{t("Opacity")}</Form.Label>
              <Form.Control
                as="select"
                name="opacity"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.opacity}
                aria-label="Default select example"
                disabled={isSubmitting}
              >
                {[0, 10, 20, 30, 40, 50, 60, 70, 80, 100].map((op, index) => {
                  return (
                    <option value={op} key={index}>
                      {t(op)}%
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 col-lg-1 col-md-1">
              <Form.Label>{t("Border")}</Form.Label>
              <Form.Control
                name="borderColor"
                type="color"
                id="exampleColorInput"
                title="Choose your color"
                value={values.borderColor}
                disabled={isSubmitting}
                isInvalid={Boolean(touched.borderColor && errors.borderColor)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3 col-lg-1 col-md-1">
              <Form.Label>{t("Size")}</Form.Label>
              <Form.Control
                type="number"
                name="borderSize"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.borderSize}
                aria-label="Default select example"
                disabled={isSubmitting}
              />
            </Form.Group>

            <Form.Group className="mb-3 col-md-10 col-sm-12">
              <Form.Label>{t("Hazard Type")}</Form.Label>
              <SelectHazardId
                values={values}
                hazardIds2Select={hazardIds2Select}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
              {!!touched.hazardIds && (
                <Form.Control.Feedback type="invalid">
                  {errors.hazardIds}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="col-lg-1 col-md-2 d-flex align-items-center">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isSubmitting}
              >
                {itemMap.action === "insert" ? t("Add") : t("Edit")}
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    );
  };

  const handleMapClick = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    const newCoordinate = { lat, lng };

    // Ajoutez les coordonnées cliquées à la liste des coordonnées du polygone
    setPath([...path, newCoordinate]);
  };

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    const getCenter = (polygon) => {
      // Fonction de comparaison pour les objets
      function compareObjects(obj1, obj2) {
        return obj1.lat === obj2.lat && obj1.lng === obj2.lng;
      }

      // Trouver les différences entre array2 et array1
      const differences = polygon.filter(
        (item) => !path.some((elem) => compareObjects(item, elem))
      );

      setCenterPolygon({ lat: differences[0].lat, lng: differences[0].lng });
    };

    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      getCenter(nextPath);
      setPath(nextPath);
    }
  }, [setPath, path]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title>{t("Map Overview")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row className="my-2">
          <Col>
            <Row>
              {paramsG &&
                typeof paramsG === "object" &&
                paramsG.map((param, index) => (
                  <Col
                    className={`p-2 mx-2 col-lg-3 col-md-6 col-sm-12 rounded`}
                    key={index}
                    style={{
                      backgroundColor: `${param.fillColor}${param.opacity}`,
                      border: `${param.borderSize}px solid ${param.borderColor}`,
                    }}
                    onClick={() => setUpdate(param, index)}
                  >
                    <Row>
                      <Col className="fs-4 fw-bold">{param.title}</Col>
                    </Row>
                    <Row>
                      <Col>{param.email}</Col>
                    </Row>
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>{t("Form")}</h3>
            <Button
              className="mt-1 mb-3 m-1"
              type="submit"
              variant="primary"
              size="sm"
              disabled={
                itemMap.action === "update" || formType !== "multipolygon"
                  ? false
                  : true
              }
              onClick={() => {
                init("multipolygon");
                setFormType("multipolygon");
              }}
            >
              {t("New")}
            </Button>
            {itemMap.action === "update" ? (
              <Button
                className="mt-1 mb-3 m-3"
                type="submit"
                variant="danger"
                size="sm"
                disabled={itemMap.action === "update" ? false : true}
                onClick={() => null}
              >
                {t("Delete")}
              </Button>
            ) : null}
            <FormMarker />
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ height: 800, width: "100%" }}>
              <LoadScript
                id="script-loader"
                googleMapsApiKey="AIzaSyCF7Np6MtgOFp8Yp8RqYyXAOwOw_YOLns4"
                language="fr"
                region="fr"
              >
                <GoogleMap
                  center={
                    centerPolygon !== null
                      ? centerPolygon
                      : path &&
                        path.length > 1 &&
                        typeof path[0].length === "undefined"
                      ? path[path.length - 1]
                      : { lat: 16.273495265600204, lng: -61.505592134824454 }
                  }
                  zoom={12}
                  version="weekly"
                  on
                  mapContainerStyle={{ width: "100%", height: "100%" }}
                  onClick={handleMapClick}
                >
                  {path.length < 50 &&
                    path.map((coordinate, index) => (
                      <Marker
                        key={index}
                        position={coordinate}
                        label={String(index + 1)}
                      />
                    ))}

                  {path && path.length > 2 && path[0].length > 0 ? (
                    <Polygon
                      // Make the Polygon editable / draggable
                      editable
                      draggable
                      paths={path}
                      // Event used when manipulating and adding points
                      onMouseUp={onEdit}
                      // Event used when dragging the whole Polygon
                      onDragEnd={onEdit}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                      options={{
                        fillColor: itemMap.fillColor,
                        strokeColor: itemMap.borderColor,
                        strokeSize: itemMap.borderSize,
                      }}
                    />
                  ) : (
                    <Polygon
                      // Make the Polygon editable / draggable
                      editable
                      draggable
                      path={path}
                      // Event used when manipulating and adding points
                      onMouseUp={onEdit}
                      // Event used when dragging the whole Polygon
                      onDragEnd={onEdit}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                      options={{
                        fillColor: itemMap.fillColor,
                        strokeColor: itemMap.borderColor,
                        strokeSize: itemMap.borderSize,
                      }}
                    />
                  )}
                </GoogleMap>
              </LoadScript>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default Map;
