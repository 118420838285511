import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, Modal, Button } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import EditForm from "./EditForm2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { useParams } from "react-router-dom";

const Notifications = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { paramsString } = useParams();

  const params = useMemo(
    () =>
      paramsString
        ? JSON.parse(paramsString)
        : {
            resume: "",
            title: "",
            postid: null,
          },
    [paramsString]
  );

  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [fnConfirm, setFnConfirm] = useState(null);

  useEffect(() => {
    if (params !== null && typeof user.customerId !== "undefined" && !show) {
      setShow(true);
    }
  }, [user, show, params, setShow]);

  const parser = new DOMParser();
  const [selectRowValues, setSelectedRowValues] = useState(
    params
      ? {
          notificationId: null,
          title: params.title,
          message: parser.parseFromString(params.resume, "text/html").body
            .textContent,
          "Post.postId": params.postId,
          "Hazard.postId": params.postId,
          active: 1,
          sound: 1,
          sended: null,
          modelId: null,
          category: null,
          color: "#000000",
        }
      : []
  );

  const sendNotification = (notificationId) => {
    axios
      .post(`/notifications/send`, { notificationId: notificationId })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log(error));
  };

  const handleBack = () => {
    setSelectedRowValues([]);
    setShow(false);
    navigate("/notifications");
  };
  const handleSend = (notificationId) => sendNotification(notificationId);

  const handleRemove = (notificationId) => {
    axios
      .delete(`/notifications/trash/${notificationId}`)
      .then((response) => {
        handleBack();
      })
      .catch((error) => console.log(error));
  };

  const handleCancel = () => setShowConfirm(false);

  const ConfirmationView = ({ handleCancel }) => {
    return (
      <Modal show={showConfirm} onHide={() => handleCancel()} centered>
        <Modal.Header closeButton>{t("Confirmation")}</Modal.Header>
        <Modal.Body className="m-3">{t("Are you sure?")}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleCancel()}>
            {t("Cancel")}
          </Button>

          <Button
            variant="success"
            onClick={() => {
              switch (fnConfirm) {
                case "remove":
                  handleRemove(selectRowValues.notificationId);
                  break;
                case "send":
                  handleSend(selectRowValues.notificationId);
                  break;
                default:
                  break;
              }
              setShowConfirm(false);
            }}
          >
            {t("Yes")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div>
      {console.log(selectRowValues)}
      {Object.keys(selectRowValues).length > 0 &&
      selectRowValues.notificationId !== null ? (
        <Button
          className="float-end mt-n1 ms-2"
          onClick={() => {
            setShowConfirm(true);
            setFnConfirm("remove");
          }}
          variant="danger"
        >
          <FontAwesomeIcon icon={faTrashAlt} fixedWidth /> {t("Delete")}
        </Button>
      ) : null}

      {selectRowValues.sended === null &&
      selectRowValues.notificationId !== null ? (
        <Button
          className="float-end mt-n1 ms-2"
          variant="primary"
          onClick={() => {
            setShowConfirm(true);
            setFnConfirm("send");
          }}
        >
          <FontAwesomeIcon icon={faBell} /> {t("Beam")}
        </Button>
      ) : null}
      <Button
        className="float-end mt-n1"
        variant="primary"
        onClick={() => handleBack()}
      >
        <FontAwesomeIcon icon={faChevronLeft} /> {t("Back")}
      </Button>
      <h1 className="h1 mb-3">{t("Push Messages")}</h1>

      <Card className="flex-fill w-100">
        <Card.Header>
          <Card.Title className="mb-0">
            <h2>
              {Object.keys(selectRowValues).length > 0 &&
              selectRowValues.notificationId !== null
                ? t("Edit")
                : t("New")}
            </h2>
          </Card.Title>
        </Card.Header>
        <Card.Body className="p-2 m-2">
          <EditForm
            values={selectRowValues}
            user={user}
            setSelectedRowValues={setSelectedRowValues}
            type="notification"
          />
        </Card.Body>
        <Card.Footer>
          <ConfirmationView handleCancel={handleCancel} />
        </Card.Footer>
      </Card>
    </div>
  );
};

export default Notifications;
