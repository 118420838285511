import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, Row, Col, Image } from "react-bootstrap";
import {
  LoadScript,
  GoogleMap,
  Polygon,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import Reports from "../reports/Default";
import moment from "moment";

function Feedbacks({ values, user }) {
  const { t } = useTranslation();

  const [reports, setReports] = useState([]);

  useEffect(() => {
    if (values.Reports !== null) {
      setReports(values.Reports);
    }
  }, [values.Reports]);

  const [itemMap, setItemMap] = useState({
    title: "",
    email: "",
    fillColor: "#563d7c",
    opacity: 50,
    borderColor: "#563d7c",
    borderSize: 1,
    type: "multipolygon",
    hazardIds: [],
    submit: false,
    action: "insert",
    channelId: values.channelId,
  });
  // Store Polygon path in state
  const [path, setPath] = useState([]);
  //const [checked, setChecked] = useState([]);
  const [paramsG] = useState(JSON.parse(values.geoParams));

  const setUpdate = (param, index) => {
    let polygon = values.multipolygon
      ? values.multipolygon.coordinates[index].slice(0, -1)
      : [];

    var multipolygon = [];

    multipolygon = polygon.map((coordinate) => [
      ...multipolygon,
      { lat: coordinate[1], lng: coordinate[0] },
    ]);

    setPath(
      multipolygon.flat(1).length > 0 ? multipolygon.flat(1) : param.path
    );

    var hazardIdsList = [];

    hazardIdsList = param.hazardIds.map((hazardId) => [
      ...hazardIdsList,
      `${hazardId}`,
    ]);
    hazardIdsList = hazardIdsList.flat();

    setItemMap({
      title: param.title,
      email: param.email,
      fillColor: param.fillColor,
      opacity: param.opacity,
      borderColor: param.borderColor,
      borderSize: param.borderSize,
      hazardIds: hazardIdsList,
      type: "multipolygon",
      submit: false,
      action: "update",
      channelId: values.channelId,
    });
  };

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    reports.map((position) =>
      bounds.extend({ lat: position.latitude, lng: position.longitude })
    );
    map.fitBounds(bounds);
  };

  const getImageName = (generatedAt) => {
    var date = moment.utc(`${generatedAt}`);
    var image = moment(date.toISOString()).unix();
    return image;
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title>{t("Feedbacks")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row className="my-2">
          <Col>
            <Row>
              {paramsG &&
                typeof paramsG === "object" &&
                paramsG.map((param, index) => (
                  <Col
                    className={`p-2 mx-2 col-lg-3 col-md-6 col-sm-12 rounded`}
                    key={index}
                    style={{
                      backgroundColor: `${param.fillColor}${param.opacity}`,
                      border: `${param.borderSize}px solid ${param.borderColor}`,
                    }}
                    onClick={() => setUpdate(param, index)}
                  >
                    <Row>
                      <Col className="fs-4 fw-bold">{param.title}</Col>
                    </Row>
                    <Row>
                      <Col>{param.email}</Col>
                    </Row>
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5">
            <h3>{t("Map Overview")}</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ height: 500, width: "100%" }}>
              <LoadScript
                id="script-loader"
                googleMapsApiKey="AIzaSyCF7Np6MtgOFp8Yp8RqYyXAOwOw_YOLns4"
                language="fr"
                region="fr"
              >
                <GoogleMap
                  zoom={12}
                  version="weekly"
                  mapContainerStyle={{ width: "100%", height: "100%" }}
                  onLoad={handleOnLoad}
                  onClick={() => setActiveMarker(null)}
                >
                  {reports.map((report, index) => (
                    <Marker
                      key={index}
                      position={{ lat: report.latitude, lng: report.longitude }}
                      title={moment(
                        report.generatedAt,
                        "YYYY-MM-DD HH:mm:ss"
                      ).format("LLLL")}
                      clickable
                      onClick={() => handleActiveMarker(report.reportId)}
                    >
                      {activeMarker === report.reportId ? (
                        <InfoWindow
                          onCloseClick={() => setActiveMarker(null)}
                          className="m-0 p-0"
                        >
                          <Row>
                            <Col className="">
                              <h1 className="text-lg">{t("Report")}</h1>
                              <p>
                                {moment(
                                  report.generatedAt,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format("llll")}
                              </p>
                              <p>
                                <Image
                                  thumbnail
                                  style={{
                                    maxWidth: "200px",
                                    width: "100%",
                                    padding: 0,
                                    margin: 0,
                                    objectFit: "cover",
                                  }}
                                  src={`https://objects.pwenter.io/uploads/${getImageName(
                                    report.generatedAt
                                  )}.jpg`}
                                />
                              </p>
                            </Col>
                          </Row>
                        </InfoWindow>
                      ) : null}
                    </Marker>
                  ))}

                  <Polygon
                    path={path}
                    options={{
                      fillColor: itemMap.fillColor,
                      strokeColor: itemMap.borderColor,
                      strokeSize: itemMap.borderSize,
                    }}
                  />
                </GoogleMap>
              </LoadScript>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5">
            <Reports channelId={values.channelId} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default Feedbacks;
