import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "../../utils/axios";
import { Form } from "react-bootstrap";

const SelectPostCategoryId = ({ values, user, handleBlur, handleChange }) => {
  const { t } = useTranslation();

  const [select, setSelect] = useState(<></>);

  useEffect(() => {
    axios
      .get(`/news/getPostsCategoriesList`)
      .then((response) => {
        setSelect(
          <Form.Select
            name="postCategoryId"
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.postCategoryId}
            aria-label="Default select example"
            disabled={values.sended ? true : false}
          >
            <option value="">{t("Open this select menu")}</option>
            {response.data.data.map((post, index) => {
              return (
                <option value={parseInt(post.postCategoryId)} key={index}>
                  {t(post.title)}
                </option>
              );
            })}
          </Form.Select>
        );
      })
      .catch((error) => {
        setSelect(
          <Form.Select
            defaultValue={values.postCategoryId}
            aria-label="Default select example"
            disabled={values.sended ? true : false}
          >
            <option>{t("Open this select menu")}</option>
          </Form.Select>
        );
      });
  }, [user, handleBlur, handleChange, t, values]);

  return select;
};

export default SelectPostCategoryId;
