import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
  Alert,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import axios from "../../utils/axios";
import * as Yup from "yup";
import { Formik } from "formik";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck, faCircle } from "@fortawesome/free-regular-svg-icons";

const Navigation = ({ t }) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">{t("Profile Settings")}</Card.Title>
      </Card.Header>
      <ListGroup variant="flush">
        <ListGroup.Item tag="a" href="../account/organization" action active>
          {t("Account")}
        </ListGroup.Item>
        <ListGroup.Item tag="a" href="../account/profile" action>
          {t("User Profile")}
        </ListGroup.Item>
        <ListGroup.Item tag="a" href="../account/password" action>
          {t("Password")}
        </ListGroup.Item>
        <ListGroup.Item tag="a" href="#" action>
          {t("Privacy and safety")}
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

const PublicInfo = ({
  customerInfo,
  customerParentInfo,
  t,
  user,
  changeCustomer,
  onButtonClick,
  randomId,
}) => {
  const countries = [
    { code: "GPE", title: "Guadeloupe" },
    { code: "MTQ", title: "Martinique" },
    { code: "GUF", title: "Guyane" },
    { code: "BLM", title: "Saint Barthélémy" },
    { code: "MAF", title: "Saint Martin" },
    { code: "REU", title: "Réunion" },
    { code: "NCL", title: "Nouvelle Calédonie" },
    { code: "FRA", title: "France" },
  ];

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">{t("Public info")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {customerInfo.parentId !== null ? (
          <Row className="mb-5">
            <Col sm={2}>
              {customerParentInfo.customerId !== "" ? (
                <img
                  alt={customerParentInfo.shortName}
                  src={`https://objects.pwenter.io/logos/customers/${customerParentInfo.customerId}.png?cache=${randomId}`}
                  className="img-responsive mt-2"
                  width="64"
                  height="64"
                />
              ) : null}
            </Col>
            <Col sm={10}>
              <Row>
                <Col sm={12}>
                  <h2>{customerParentInfo.name}</h2>
                </Col>
                <Col md={5}>{t("Manager account")}</Col>
                <Col md={7}>
                  {user.levelId > 10 ? (
                    <Button
                      href="#"
                      size="sm"
                      variant="outline-danger"
                      className="btn-pill me-1 mb-1"
                      onClick={() =>
                        changeCustomer(user.userId, customerInfo.parentId)
                      }
                    >
                      {t("Switcher")}
                    </Button>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}

        <Formik
          initialValues={customerInfo}
          enableReinitialize
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .min(12, t("less_char", { n: 12 }))
              .max(120)
              .required("Name is required"),
            shortName: Yup.string()
              .min(12, t("less_char", { n: 12 }))
              .max(120)
              .required("Short Name is required"),
            address: Yup.string()
              .min(12, t("less_char", { n: 12 }))
              .max(256)
              .required("Address is required"),
            siret: Yup.string().min(14).max(14).required("SIRET is required"),
            postalCode: Yup.string()
              .min(5)
              .max(5)
              .required("Postal Code is required"),
            phone: Yup.string().matches(
              phoneRegExp,
              "Phone number is not valid"
            ),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm }
          ) => {
            try {
              const params = {
                name: values.name,
                shortName: values.shortName,
                address: values.address,
                email: values.email,
                phone: values.phone,
                siret: values.siret,
                city: values.city,
                postalCode: values.postalCode,
                country: values.country,
                active: values.active,
                customerId: user.customerId,
              };

              if (params.customerId === null) {
                delete params.customerId;
                axios
                  .put(`/account/customer/insert`, params)
                  .then((response) => {
                    setStatus({ success: true });
                    setErrors({
                      submit: response.data.message,
                      status: "success",
                    });

                    resetForm({
                      values: {
                        ...values,
                        customerId: response.data.data.customerId,
                      },
                    });
                  })
                  .catch((error) => console.log(error));
              } else {
                axios
                  .put(`/account/customer/update`, params)
                  .then((response) => {
                    if (response.status === 200) {
                      setStatus({ success: true });
                      setErrors({
                        submit: response.data.message,
                        status: "success",
                      });
                    }
                  })
                  .catch((error) => {
                    const message = error || "Something went wrong";
                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setSubmitting(false);
                  });
              }
            } catch (error) {
              const message = error.message || "Something went wrong";
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit} className="row">
              {errors.submit && (
                <Alert
                  className="my-3 p-2"
                  variant={
                    typeof errors.status === "undefined"
                      ? "danger"
                      : errors.status
                  }
                >
                  {errors.submit}
                </Alert>
              )}

              <Row>
                <Col md={7}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="name">{t("Name")}</Form.Label>
                    <Form.Control
                      type="text"
                      id="name"
                      name="name"
                      placeholder={t("Name")}
                      value={values.name}
                      isInvalid={Boolean(touched.name && errors.name)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="shortName">
                      {t("Short Name")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="shortName"
                      name="shortName"
                      placeholder={t("Short Name")}
                      value={values.shortName}
                      disabled={isSubmitting}
                      isInvalid={Boolean(touched.shortName && errors.shortName)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="siret">SIRET</Form.Label>
                    <Form.Control
                      type="number"
                      name="siret"
                      id="siret"
                      placeholder="SIRET"
                      value={values.siret}
                      disabled={isSubmitting}
                      isInvalid={Boolean(touched.siret && errors.siret)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="5">
                  <div className="text-center">
                    {typeof user.customerId !== "undefined" ? (
                      <img
                        alt={user.customerId}
                        src={`https://objects.pwenter.io/logos/customers/${user.customerId}.png?cache=${randomId}`}
                        className="img-responsive mt-2"
                        width="128"
                        height="128"
                      />
                    ) : null}

                    <div className="mt-2">
                      <Button
                        variant="primary"
                        onClick={onButtonClick}
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faUpload} /> {t("Upload")}
                      </Button>
                    </div>
                    <small>
                      {t(
                        "For best results, use an image at least 512px by 512px in .png format"
                      )}
                    </small>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={5}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="phone">{t("Phone")}</Form.Label>
                    <Form.Control
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder={t("Phone")}
                      value={values.phone}
                      disabled={isSubmitting}
                      isInvalid={Boolean(touched.phone && errors.phone)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} sm={7}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="email">{t("Email")}</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      id="email"
                      placeholder={t("Email")}
                      value={values.email}
                      disabled={isSubmitting}
                      isInvalid={Boolean(touched.email && errors.email)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="address">{t("Address")}</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  id="address"
                  placeholder=""
                  value={values.address}
                  disabled={isSubmitting}
                  isInvalid={Boolean(touched.address && errors.address)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Form.Group>
              <Row>
                <Col md={3} sm={4}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="postalCode">{t("Zip")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="postalCode"
                      id="postalCode"
                      value={values.postalCode}
                      disabled={isSubmitting}
                      isInvalid={Boolean(
                        touched.postalCode && errors.postalCode
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4} sm={8}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="city">{t("City")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      id="city"
                      value={values.city}
                      disabled={isSubmitting}
                      isInvalid={Boolean(touched.city && errors.city)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={5}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="country">{t("Country")}</Form.Label>
                    <Form.Select
                      name="country"
                      id="country"
                      value={values.country}
                      disabled={isSubmitting}
                      isInvalid={Boolean(touched.country && errors.country)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {countries.map((country, index) => {
                        return (
                          <option value={country.code} key={index}>
                            {country.title}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isSubmitting}
                  >
                    {t("Save")}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

const PrivateInfo = ({ t, subCustomers, user, changeCustomer, randomId }) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">{t("Assiociated accounts")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          {subCustomers.map((s, index) => {
            return (
              <Col md="6" lg="4" key={index}>
                <Row className="text-center mb-3">
                  <Col sm={12}>
                    <img
                      alt={s.shortName}
                      src={`https://objects.pwenter.io/logos/customers/${s.customerId}.png?cache=${randomId}`}
                      className="img-responsive mt-2"
                      width="128"
                      height="128"
                    />
                  </Col>
                  <Col sm={12}>{s.name}</Col>
                  {user.levelId > 8 ? (
                    <Col sm={12}>
                      <Button
                        href="#"
                        size="sm"
                        variant="outline-danger"
                        className="btn-pill me-1 mb-1 mt-1"
                        onClick={() =>
                          changeCustomer(user.userId, s.customerId)
                        }
                      >
                        {t("Switcher")}
                      </Button>
                    </Col>
                  ) : null}
                </Row>
              </Col>
            );
          })}
        </Row>
      </Card.Body>
    </Card>
  );
};

const ZonesCustomer = ({ t, zones, zonesCustomer, customerId }) => {
  const [zo, setZo] = useState([]);

  useEffect(() => {
    var zoneLoop = [];
    zonesCustomer.forEach((e) => {
      zoneLoop.push(e.zoneId);
    });

    setZo(zoneLoop);
  }, [setZo, zonesCustomer]);

  const updateZone = (zoneId, active) => {
    axios
      .post(`/account/customer/zone/update`, { zoneId, active, customerId })
      .then((response) => {
        if (response.data.data.length > 0 && response.data.data[0] === 1) {
          // UPDATE
          if (!active) {
            setZo(zo.filter((item) => item !== zoneId));
          } else {
            var zo3 = zo;
            zo3.push(zoneId);
            setZo(zo3);
            setZo(zo3.filter((item) => item !== "r"));
          }
        } else {
          // CREATE
          var zo2 = zo;
          zo2.push(zoneId);
          setZo(zo2);
          setZo(zo2.filter((item) => item !== "r"));
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">{t("Zones")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          {zones !== null &&
            zones.map((z, index) => {
              return (
                <Col key={index}>
                  <Row className="mb-3 fs-2">
                    <Col sm={1}>
                      <FontAwesomeIcon
                        icon={zo.includes(z.zoneId) ? faCircleCheck : faCircle}
                        color={zo.includes(z.zoneId) ? "#008000" : "#FF0000"}
                        onClick={() =>
                          updateZone(
                            z.zoneId,
                            zo.includes(z.zoneId) ? false : true
                          )
                        }
                      />
                    </Col>
                    <Col sm={11}>{z.name}</Col>
                  </Row>
                  <Row>
                    {z.subZones.map((z, index) => {
                      return (
                        <Col lg="6" md={12} key={index} className="mb-5">
                          <Row className="mb-3 fs-3">
                            <Col sm={1}>
                              <FontAwesomeIcon
                                icon={
                                  zo.includes(z.zoneId)
                                    ? faCircleCheck
                                    : faCircle
                                }
                                color={
                                  zo.includes(z.zoneId) ? "#008000" : "#FF0000"
                                }
                                onClick={() =>
                                  updateZone(
                                    z.zoneId,
                                    zo.includes(z.zoneId) ? false : true
                                  )
                                }
                              />
                            </Col>
                            <Col sm={11}>{z.name}</Col>
                          </Row>

                          <Row>
                            {z.subZones.map((z, index) => {
                              return (
                                <Col md="6" key={index}>
                                  <Row className="mb-3 text-lg">
                                    <Col sm={2}>
                                      <FontAwesomeIcon
                                        icon={
                                          zo.includes(z.zoneId)
                                            ? faCircleCheck
                                            : faCircle
                                        }
                                        color={
                                          zo.includes(z.zoneId)
                                            ? "#008000"
                                            : "#FF0000"
                                        }
                                        onClick={() =>
                                          updateZone(
                                            z.zoneId,
                                            zo.includes(z.zoneId) ? false : true
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col sm={10}>{z.name}</Col>
                                  </Row>
                                </Col>
                              );
                            })}
                          </Row>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              );
            })}
        </Row>
      </Card.Body>
    </Card>
  );
};

const Organization = () => {
  const { user, changeCustomer } = useAuth();
  const { t } = useTranslation();

  const inputRef = useRef(null);

  const onButtonClick = () => {
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files[0], user.customerId);
    }
  };

  const [random, setRandom] = useState();

  const handleFiles = (file, customerId) => {
    const formData = new FormData();
    formData.append("customerId", customerId);
    formData.append("fileData", file);

    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    axios
      .post(`/account/customer/logo`, formData)
      .then((response) => {
        const randomId = function (length = 6) {
          return Math.random()
            .toString(36)
            .substring(2, length + 2);
        };

        setRandom(randomId);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    const randomId = function (length = 6) {
      return Math.random()
        .toString(36)
        .substring(2, length + 2);
    };
    setRandom(randomId);
  }, [setRandom]);

  const [customerInfo, setCustomerInfo] = useState(null);
  const [zonesCustomer, setZonesCustomer] = useState(null);
  const [customerParentInfo, setCustomerParentInfo] = useState({
    name: "",
    shortName: "",
    customerId: "",
  });

  useEffect(() => {
    if (typeof user.customerId !== "undefined") {
      axios
        .get(`account/customer/${user.customerId}`)
        .then((response) => {
          if (response.data.data.parentId !== null) {
            axios
              .get(`account/customer/${response.data.data.parentId}`)
              .then((response) => {
                setCustomerParentInfo(response.data.data);
              })
              .catch((error) => console.log(error));
          }

          if (response.data.data.country !== null) {
            axios
              .get(`account/customer/zones/${response.data.data.country}`)
              .then((response) => {
                setZonesCustomer(response.data.data);
              })
              .catch((error) => console.log(error));
          }

          setCustomerInfo(response.data.data);
        })
        .catch((error) => console.log(error));
    }
  }, [user]);

  return (
    <React.Fragment>
      <Helmet title={t("Organization")} />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{t("Organization")}</h1>

        <Row>
          <Col md="3" xl="2">
            <Navigation t={t} />
          </Col>
          <Col md="9" xl="10">
            {customerInfo !== null ? (
              <PublicInfo
                customerInfo={customerInfo}
                customerParentInfo={customerParentInfo}
                changeCustomer={changeCustomer}
                t={t}
                user={user}
                onButtonClick={onButtonClick}
                randomId={random}
              />
            ) : null}

            {customerInfo !== null && customerInfo.subCustomers.length > 0 ? (
              <PrivateInfo
                t={t}
                user={user}
                subCustomers={customerInfo.subCustomers}
                changeCustomer={changeCustomer}
                randomId={random}
              />
            ) : null}

            {user.levelId > 12 &&
            customerInfo !== null &&
            zonesCustomer !== null ? (
              <ZonesCustomer
                t={t}
                zones={zonesCustomer}
                zonesCustomer={customerInfo.Zones}
                customerId={user.customerId}
              />
            ) : null}
          </Col>
          <Col>
            <form id="form-file-upload" onSubmit={(e) => e.preventDefault()}>
              <input
                ref={inputRef}
                type="file"
                id="input-file-upload"
                multiple={false}
                onChange={handleChange}
              />
            </form>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Organization;
