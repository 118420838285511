import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";

import Header from "./Header";
import Calendar from "./Calendar";
import News from "./News";
/*import BarChart from "./BarChart";
import Feed from "./Feed";
import PieChart from "./PieChart";*/
import LineChart from "./LineChart";
import Reports from "./Reports";
import Statistics from "./Statistics";
import Map from "./Map";
import { useTranslation } from "react-i18next";

const Default = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet title={t("Overview")} />
      <Container fluid className="p-0">
        <Header />
        <Statistics />
        <Row>
          <Col lg="8" className="d-flex">
            <LineChart />
          </Col>
          <Col lg="4" className="d-flex">
            <Calendar />
          </Col>
        </Row>
        <Row>
          <Col lg="6" xl="8" className="d-flex">
            <Map />
          </Col>
          <Col lg="6" xl="4" className="d-flex">
            <News />
          </Col>
        </Row>
        <Reports />
      </Container>
    </React.Fragment>
  );
};

export default Default;
