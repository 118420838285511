import React from "react";

import SidebarNavSection from "./SidebarNavSection";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";

const SidebarNav = ({ items }) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <ul className="sidebar-nav">
      {items &&
        items.map((item) => {
          if (item.level <= user.levelId) {
            return (
              <SidebarNavSection
                key={item.title}
                pages={item.pages}
                title={t(item.title)}
              />
            );
          } else {
            return null;
          }
        })}
    </ul>
  );
};

export default SidebarNav;
