import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import axios from "../../utils/axios";

const NavbarCustomers = () => {
  const { user, changeCustomer } = useAuth();
  const { t } = useTranslation();

  const [customerOptions, setCustomerOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/tools/zonescustomers");
        const dataWithFrance = [
          {
            zoneId: 11, // Zone fictive
            name: "France",
            fullName: "France",
            Customers: [{ customerId: 5, name: "France" }],
            subZones: [],
          },
          ...response.data.data,
        ];

        setCustomerOptions(dataWithFrance);
      } catch (error) {
        console.error("Erreur de chargement des zones", error);
      }
    };
    fetchData();
  }, []);

  const CurrentCustomer = ({ selectedCustomer }) => (
    <div>
      {user && user.customerId !== undefined && (
        <img
          src={`https://objects.pwenter.io/logos/customers/${user.customerId}.png`}
          alt={selectedCustomer?.name}
          className="me-1"
        />
      )}
      <span className="align-middle text-sm">{t(selectedCustomer?.name)}</span>
    </div>
  );

  // Fonction récursive pour afficher les sous-dropdowns
  const renderMenuItems = (zones) => {
    return zones.map(({ zoneId, name, subZones, Customers }) => (
      <Dropdown key={zoneId} className="dropdown-submenu">
        {subZones !== undefined && subZones.length > 0 ? (
          <div>
            <Dropdown.Toggle
              as="div"
              className="dropdown-item d-flex align-items-center"
            >
              <img
                src={`https://objects.pwenter.io/logos/customers/${
                  Customers !== undefined && Customers.length > 0
                    ? Customers[0].customerId
                    : 5
                }.png`}
                alt={t(name)}
                width="20"
                className="align-middle me-1"
                onClick={() =>
                  Customers !== undefined && Customers.length > 0
                    ? changeCustomer(user.userId, Customers[0].customerId)
                    : null
                }
              />
              <span className="align-middle pe-1">{t(name)}</span>
            </Dropdown.Toggle>
            {subZones !== undefined && subZones.length > 0 && (
              <Dropdown.Menu>{renderMenuItems(subZones)}</Dropdown.Menu>
            )}
          </div>
        ) : (
          <div className="dropdown-item d-flex align-items-center">
            <img
              src={`https://objects.pwenter.io/logos/customers/${
                Customers !== undefined && Customers.length > 0
                  ? Customers[0].customerId
                  : 5
              }.png`}
              alt={t(name)}
              width="20"
              className="align-middle me-1"
              onClick={() =>
                Customers !== undefined && Customers.length > 0
                  ? changeCustomer(user.userId, Customers[0].customerId)
                  : null
              }
            />
            <span className="align-middle">{t(name)}</span>
          </div>
        )}
      </Dropdown>
    ));
  };

  const findCustomerInZones = (zones, customerId) => {
    for (const zone of zones) {
      // Vérifier si le customerId est présent dans Customers
      if (
        zone.Customers &&
        zone.Customers.some((c) => c.customerId === customerId)
      ) {
        return zone.Customers.find((c) => c.customerId === customerId);
      }

      // Si la zone contient des subZones, chercher récursivement
      if (zone.subZones && zone.subZones.length > 0) {
        const foundCustomer = findCustomerInZones(zone.subZones, customerId);
        if (foundCustomer) return foundCustomer;
      }
    }
    return null;
  };

  const Menu = () => {
    return (
      <Dropdown className="me-2 nav-item" align="end">
        <Dropdown.Toggle as="a" className="nav-link nav-flag">
          <CurrentCustomer
            selectedCustomer={findCustomerInZones(
              customerOptions,
              user.customerId
            )}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {customerOptions &&
            customerOptions !== undefined &&
            customerOptions.length > 0 &&
            renderMenuItems(customerOptions)}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return user?.customerId && user?.levelId > 13 ? (
    <Menu />
  ) : (
    <CurrentCustomer />
  );
};

export default NavbarCustomers;
