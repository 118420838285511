import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "../../utils/axios";
import { Form } from "react-bootstrap";

const SelectPostId = ({ values, user, handleBlur, handleChange }) => {
  const { t } = useTranslation();

  const [select, setSelect] = useState(<></>);

  useEffect(() => {
    if (typeof user.customerId !== "undefined") {
      axios
        .get(`/notifications/getPostsList/${user.customerId}`)
        .then((response) => {
          setSelect(
            <Form.Select
              name="postId"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.postId === null ? "" : values.postId}
              aria-label="Default select example"
              disabled={values.sended ? true : false}
            >
              <option>{t("Open this select menu")}</option>
              {response.data.data.map((post, index) => {
                return (
                  <option value={parseInt(post.postId)} key={index}>
                    {t(post.title)} - {post["Hazard.title"]}
                  </option>
                );
              })}
            </Form.Select>
          );
        })
        .catch((error) => {
          setSelect(
            <Form.Select
              value={values.postId}
              aria-label="Default select example"
              disabled={values.sended ? true : false}
            >
              <option>{t("Open this select menu")}</option>
            </Form.Select>
          );
        });
    }
  }, [user, handleBlur, handleChange, t, values]);

  return select;
};

export default SelectPostId;
