import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Card,
  Table,
  Modal,
  Button,
  Col,
  Row,
  Fade,
  Collapse,
} from "react-bootstrap";
/*import { MoreHorizontal } from "react-feather";*/
import { useTable } from "react-table";
import useAuth from "../../hooks/useAuth";
import axios from "../../utils/axios";
import EditForm from "./EditForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCheckCircle,
  faTimesCircle,
  faPlus,
  faQuestion,
  faBellSlash,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import illustration from "../../assets/img/illustrations/help.png";

import moment from "moment";
require("moment/locale/fr");

const Notifications = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [help, setHelp] = useState(false);
  const [lastReports, setLastReports] = useState([]);
  const [dataResponse, setDataResponse] = useState([]);

  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [fnConfirm, setFnConfirm] = useState(null);

  useEffect(() => {
    if (typeof user.customerId !== "undefined" && !show) {
      axios
        .get(`/notifications/get/${user.customerId}`)
        .then((response) => {
          var d = [];
          response.data.data.map((item) => {
            var newy = {
              notificationId: item.notificationId,
              colorIcon: (
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={item.sound ? faBell : faBellSlash}
                    className={`color: ${item.color}`}
                    size="3x"
                    color={item.color}
                  />
                </div>
              ),
              title: item.title,
              date:
                item.sended !== null ? moment(item.sended).format("llll") : `-`,
              message: item.message,
              post: (
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={item.postId !== null ? faCheckCircle : faTimesCircle}
                    className={`color: ${item.color}`}
                    size="1x"
                    color={item.postId !== null ? `#008000` : `#FF0000`}
                    title={item.postId !== null ? item["Post.title"] : null}
                  />
                </div>
              ),
              hazard: (
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={
                      item.hazardId !== null ? faCheckCircle : faTimesCircle
                    }
                    className={`color: ${item.color}`}
                    size="1x"
                    color={item.hazardId !== null ? `#008000` : `#FF0000`}
                    title={item.hazardId !== null ? item["Hazard.title"] : null}
                  />
                </div>
              ),
            };
            d = [...d, newy];
            return newy;
          });
          setLastReports(d);
          setDataResponse(response.data.data);
        })
        .catch((error) => console.log(error));
    }
  }, [user, show, t]);

  const data = React.useMemo(() => lastReports, [lastReports]);

  const columns = React.useMemo(
    () => [
      {
        Header: t("#"),
        accessor: "notificationId",
      },
      {
        Header: t("Color"),
        accessor: "colorIcon", // accessor is the "key" in the data
      },
      {
        Header: t("Title"),
        accessor: "title",
      },
      {
        Header: t("Date"),
        accessor: "date",
      },
      {
        Header: t("Message"),
        accessor: "message",
      },
      {
        Header: t("Post"),
        accessor: "post",
      },
      {
        Header: t("Hazard"),
        accessor: "hazard",
      },
    ],
    [t]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const [selectRowValues, setSelectedRowValues] = useState([]);
  const handleShow = (notificationId) => {
    let values = dataResponse.filter(
      (item) => item.notificationId === notificationId
    );
    setSelectedRowValues(values[0]);
    setShow(true);
  };

  const sendNotification = (notificationId) => {
    axios
      .post(`/notifications/send`, { notificationId: notificationId })
      .then(() => {})
      .catch((error) => console.log(error));
  };

  const handleClose = () => setShow(false);
  const handleCancel = () => setShowConfirm(false);
  const handleCreate = () => {
    setSelectedRowValues([]);
    setShow(true);
  };
  const handleSend = (notificationId) => sendNotification(notificationId);

  const handleRemove = (notificationId) => {
    axios
      .delete(`/notifications/trash/${notificationId}`)
      .then((response) => {
        console.log(response);
        setShow(false);
      })
      .catch((error) => console.log(error));
  };

  const ModalView = ({ show, handleClose, setShowConfirm }) => {
    return (
      <Modal
        show={show}
        onHide={() => handleClose()}
        size="xl"
        style={{ zIndex: 1053, overlay: { zIndex: 1054 } }}
      >
        <Modal.Header closeButton>
          {Object.keys(selectRowValues).length > 0 ? t("Edit") : t("New")}
        </Modal.Header>
        <Modal.Body className="m-3">
          <EditForm
            values={selectRowValues}
            user={user}
            setShow={setShow}
            type="notification"
          />
        </Modal.Body>
        <Modal.Footer>
          {selectRowValues.sended === null ? (
            <Button
              variant="primary"
              onClick={() => {
                setShowConfirm(true);
                setFnConfirm("send");
              }}
            >
              {t("Beam")}
            </Button>
          ) : null}
          {Object.keys(selectRowValues).length > 0 ? (
            <Button
              onClick={() => {
                setShowConfirm(true);
                setFnConfirm("remove");
              }}
              variant="danger"
              className=" rounded"
            >
              <FontAwesomeIcon icon={faTrashAlt} fixedWidth /> {t("Delete")}
            </Button>
          ) : null}
          <Button variant="secondary" onClick={() => handleClose()}>
            {t("Close")}
          </Button>
        </Modal.Footer>
        <ConfirmationView handleCancel={handleCancel} />
      </Modal>
    );
  };

  const ConfirmationView = ({ handleCancel }) => {
    return (
      <Modal
        show={showConfirm}
        onHide={() => handleCancel()}
        centered
        /*style={{ zIndex: 1056, overlay: { zIndex: 1055 } }}*/
      >
        <Modal.Header closeButton>{t("Confirmation")}</Modal.Header>
        <Modal.Body className="m-3">{t("Are you sure?")}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleCancel()}>
            {t("Cancel")}
          </Button>

          <Button
            variant="success"
            onClick={() => {
              switch (fnConfirm) {
                case "remove":
                  handleRemove(selectRowValues.notificationId);
                  break;
                case "send":
                  handleSend(selectRowValues.notificationId);
                  break;
                default:
                  break;
              }
              setShowConfirm(false);
            }}
          >
            {t("Yes")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <Container fluid className="p-0">
      <Button
        className="float-end mt-n1 ms-1"
        variant="warning"
        onClick={() => setHelp(!help)}
      >
        <FontAwesomeIcon icon={faQuestion} />
      </Button>
      <Button
        className="float-end mt-n1"
        variant="primary"
        onClick={() => handleCreate()}
      >
        <FontAwesomeIcon icon={faPlus} /> {t("New")}
      </Button>
      <h1 className="h3 mb-3">Notifications</h1>

      <Fade in={help}>
        <Collapse in={help} className={`m-1`}>
          <Col className="bg-light rounded-lg ps-2 mb-4">
            <Row className="">
              <Col xs={2} className=" text-center p-0 m-0">
                <img
                  src={illustration}
                  alt="Customer Support"
                  className="img-fluid illustration-img"
                />
              </Col>
              <Col
                className="p-4 pt-2 text-lg"
                style={{ whiteSpace: "pre-line" }}
              >
                {t("HELP_NOTIFICATIONS")}
              </Col>
            </Row>
          </Col>
        </Collapse>
      </Fade>

      <Card className="flex-fill w-100">
        <Card.Header>
          <Card.Title className="mb-0">{t("Push Messages")}</Card.Title>
        </Card.Header>
        <Table
          className="my-0"
          striped
          bordered
          hover
          size="sm"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        onClick={() =>
                          handleShow(cell.row.values.notificationId)
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <ModalView
          show={show}
          handleClose={handleClose}
          setShowConfirm={setShowConfirm}
          setFnConfirm={setFnConfirm}
        />
      </Card>
    </Container>
  );
};

export default Notifications;
