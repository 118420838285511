import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "../../utils/axios";
import { Dropdown } from "react-bootstrap";
import { ChevronRight } from "react-feather";

const SelectCustomerId = ({ values, user, handleBlur, handleChange }) => {
  const { t } = useTranslation();
  const [customerOptions, setCustomerOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  useEffect(() => {
    if (typeof user.customerId !== "undefined") {
      setLoading(true);
      setError(false);

      axios
        .get(`/tools/zonescustomers`)
        .then((response) => {
          const dataWithFrance = [
            {
              zoneId: 11, // Zone fictive
              name: "France",
              fullName: "France",
              Customers: [{ customerId: 5, name: "France" }],
              subZones: [],
            },
            ...response.data.data,
          ];

          setCustomerOptions(dataWithFrance);
          const currentCustomer = response.data.data
            .flatMap((zone) => [
              ...zone.Customers,
              ...zone.subZones.flatMap((sub) => sub.Customers),
            ])
            .find((customer) => customer.customerId === values.customerId);
          setSelectedCustomer(currentCustomer || null);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user, values.customerId]);

  useEffect(() => {
    console.log(selectedCustomer);
  }, [selectedCustomer]);

  // Fonction récursive pour afficher les sous-dropdowns
  const renderDropdownItems = (zones) => {
    return zones.map(({ zoneId, name, subZones, Customers }) => (
      <Dropdown key={zoneId} as="div" className="w-100" align="end">
        {subZones && subZones.length > 0 ? (
          <Dropdown align="end" className="d-block">
            <Dropdown.Toggle
              as="button"
              className="dropdown-item text-start pe-4"
              type="button"
            >
              {Customers.length > 0 && (
                <ChevronRight
                  className="align-self-center feather-sm"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation(); // Empêche la fermeture automatique
                    setSelectedCustomer({ name: Customers.name });
                    handleChange({
                      target: {
                        name: "customerId",
                        value: Customers.customerId,
                      },
                    });
                  }}
                />
              )}{" "}
              {t(name)}
            </Dropdown.Toggle>
            <Dropdown.Menu>{renderDropdownItems(subZones)}</Dropdown.Menu>
          </Dropdown>
        ) : (
          /* Sinon, c'est un item sélectionnable */
          Customers &&
          Customers.length > 0 &&
          Customers.map((customer) => (
            <Dropdown.Item
              key={customer.customerId}
              as="button"
              type="button"
              className="text-start"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation(); // Empêche la fermeture automatique
                setSelectedCustomer(customer);
                handleChange({
                  target: { name: "customerId", value: customer.customerId },
                });
              }}
            >
              {customer.customerId !== undefined && (
                <ChevronRight className="align-self-center feather-sm" />
              )}{" "}
              {t(name)}
            </Dropdown.Item>
          ))
        )}
      </Dropdown>
    ));
  };

  return (
    <Dropdown className="me-2 nav-item w-100" align="end">
      <Dropdown.Toggle
        as="button"
        type="button"
        className="btn btn-outline-primary text-truncate d-flex align-items-center justify-content-between pe-4"
      >
        {loading
          ? t("Loading...")
          : error
          ? t("Error loading customers")
          : selectedCustomer
          ? t(selectedCustomer.name)
          : t("Select a customer")}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {loading ? (
          <Dropdown.ItemText>{t("Loading...")}</Dropdown.ItemText>
        ) : error ? (
          <Dropdown.ItemText>{t("Error loading customers")}</Dropdown.ItemText>
        ) : (
          renderDropdownItems(customerOptions)
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectCustomerId;
