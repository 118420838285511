import React, { useRef } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import SelectPostCategoryId from "./SelectPostCategoryId";
import SelectHazardId from "./SelectHazardId";
import SelectCustomerId from "./SelectCustomerId";
import { Editor } from "@tinymce/tinymce-react";
import moment from "moment";
require("moment/locale/fr");

function EditForm({ values, user, setSelectedRowValues }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const titleRef = useRef(null);
  const aliasRef = useRef(null);

  var pushList = [
    { title: "Yes", value: 1 },
    { title: "No", value: 0 },
  ];

  var stateList = [
    { title: "Enabled", value: 1 },
    { title: "Disabled", value: 0 },
  ];

  const init = () => {
    if (Object.keys(values).length === 0) {
      return {
        postId: null,
        title: "",
        dateStart: "",
        timeStart: "",
        dateEnd: "",
        timeEnd: "",
        alias: "",
        content: "",
        hazardId: "",
        customerId: "",
        push: 0,
        auto: 0,
        active: 1,
        submit: false,
        postCategoryId: 6,
      };
    } else {
      return {
        postId: values.postId,
        title: values.title,
        alias: values.alias,
        content: values.content,
        dateStart: moment(
          values.dateStart.replace(".000Z", `.000${user.utc}`),
          "YYYY-MM-DD HH:mm:ss Z"
        ).format("YYYY-MM-DD"),
        timeStart:
          typeof values.dateStart === "undefined" || values.dateStart === null
            ? ""
            : moment(
                values.dateStart.replace(".000Z", `.000${user.utc}`),
                "YYYY-MM-DD HH:mm:ss Z"
              ).format("LT"),
        dateEnd:
          values.dateEnd === null
            ? ""
            : moment(
                values.dateEnd.replace(".000Z", `.000${user.utc}`),
                "YYYY-MM-DD HH:mm:ss Z"
              ).format("YYYY-MM-DD"),
        timeEnd:
          typeof values.dateEnd === "undefined" || values.dateEnd === null
            ? ""
            : moment(values.dateEnd.replace(".000Z", `.000${user.utc}`)).format(
                "LT"
              ),
        hazardId: values.hazardId,
        customerId: user.levelId > 13 ? user.customerId : user.customerId,
        push: values.push,
        auto: values.auto,
        active: values.active,
        submit: false,
        postCategoryId: values.postCategoryId,
      };
    }
  };

  const handleAlias = (e) => {
    let str = e.target.value;
    e.target.value = str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/([^\w]+|\s+)/g, "-") // Replace space and other characters by hyphen
      .replace(/--+/g, "-") // Replaces multiple hyphens by one hyphen
      .replace(/(^-+|-+$)/g, "") // Remove extra hyphens from beginning or end of the string
      .toLowerCase();
  };
  const handleAlias2 = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/([^\w]+|\s+)/g, "-") // Replace space and other characters by hyphen
      .replace(/--+/g, "-") // Replaces multiple hyphens by one hyphen
      .replace(/(^-+|-+$)/g, "") // Remove extra hyphens from beginning or end of the string
      .toLowerCase();
  };

  const handleForecastButtonClick = (editor) => {
    var iso2 = "gp";

    if (user.customerId === 4) {
      iso2 = "mq";
    } else if (user.customerId === 6) {
      iso2 = "sm";
    } else if (user.customerId === 9) {
      iso2 = "sb";
    } else if (user.customerId === 10) {
      iso2 = "gf";
    } else if (user.customerId === 8) {
      iso2 = "nc";
    } else if (user.customerId === 7) {
      iso2 = "re";
    }

    axios
      .get(`/news/importData/${iso2}`)
      .then((response) => {
        editor.insertContent(response.data.data);
      })
      .catch((error) => console.log(error));
  };

  const handlePushButtonClick = (editor, title, postId) => {
    const resume = editor.selection.getContent();
    if (resume && title !== "") {
      const params = { postId, title, resume };

      navigate(`/notifications/preform/${JSON.stringify(params)}`);
    }
  };

  return (
    <Formik
      initialValues={init()}
      validationSchema={Yup.object().shape({
        title: Yup.string()
          .min(12, t("less_char", { n: 12 }))
          .max(120)
          .required("Title is required"),
        alias: Yup.string()
          .min(6, t("less_char", { n: 6 }))
          .max(200),
        dateStart: Yup.date().required("Message is required"),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, resetForm }
      ) => {
        try {
          const params = {
            postId: values.postId,
            title: values.title,
            alias: values.alias,
            content: editorRef.current.getContent(),
            dateStart: `${values.dateStart} ${
              values.timeStart !== "" ? values.timeStart : "00:00"
            } Z`,
            dateEnd:
              values.dateEnd !== "" &&
              typeof values.dateEnd !== "undefined" &&
              values.dateEnd !== "Invalid date"
                ? `${values.dateEnd}  ${
                    values.timeEnd !== "" ? values.timeEnd : "00:00"
                  } Z`
                : null,
            subtitle: values.subtitle,
            hazardId: values.hazardId,
            push: values.push,
            auto: values.auto,
            postCategoryId: values.postCategoryId,
            active: values.active,
            customerId: user.levelId > 13 ? values.customerId : user.customerId,
          };

          if (params.postId === null) {
            delete params.postId;
            axios
              .put(`/news/insert`, params)
              .then((response) => {
                setErrors({
                  submit: response.data.message,
                  status: "success",
                });

                setSelectedRowValues({
                  postId: response.data.data.postId,
                  title: response.data.data.title,
                  alias: response.data.data.alias,
                  dateStart: response.data.data.dateStart,
                  dateEnd: response.data.data.dateEnd,
                  content: response.data.data.content,
                  hazardId: response.data.data.hazardId,
                  customerId: response.data.data.customerId,
                  push: response.data.data.push,
                  auto: response.data.data.auto,
                  active: response.data.data.active ? 1 : 0,
                  submit: response.data.data.submit,
                  postCategoryId: response.data.data.postCategoryId,
                  plan: null,
                });

                resetForm({
                  values: { ...values, postId: response.data.data.postId },
                });
              })
              .catch((error) => {
                setErrors({
                  submit: error,
                  status: "danger",
                });
              });
          } else {
            axios
              .put(`/news/update`, params)
              .then((response) => {
                if (response.status === 200) {
                  setStatus({ success: true });
                  setErrors({
                    submit: response.data.message,
                    status: "success",
                  });
                }
              })
              .catch((error) => console.log(error));
          }
        } catch (error) {
          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit} className="row">
          {errors.submit && (
            <Alert
              className="my-3 p-2"
              variant={
                typeof errors.status === "undefined" ? "danger" : errors.status
              }
            >
              {t(errors.submit)}
            </Alert>
          )}
          <Form.Group className="mb-3 col-md-6">
            <Form.Label>{t("Title")}</Form.Label>
            <Form.Control
              ref={titleRef}
              type="text"
              name="title"
              placeholder={t("Title")}
              value={values.title}
              disabled={values.sended ? true : false}
              isInvalid={Boolean(touched.title && errors.title)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.title && (
              <Form.Control.Feedback type="invalid">
                {errors.title}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3 col-md-6">
            <Form.Label>{t("Alias")}</Form.Label>
            <Form.Control
              ref={aliasRef}
              type="text"
              name="alias"
              placeholder={t("Click here to auto-generate the alias")}
              value={values.alias}
              disabled={values.sended ? true : false}
              isInvalid={Boolean(touched.alias && errors.alias)}
              onBlur={handleBlur}
              onChange={(e) => {
                handleAlias(e);
                handleChange(e);
              }}
              onFocus={(e) => {
                if (e.target.value === "") {
                  aliasRef.current.value = handleAlias2(titleRef.current.value);
                  handleChange(e);
                }
              }}
            />
            {!!touched.alias && (
              <Form.Control.Feedback type="invalid">
                {errors.alias}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3 col-lg-2 col-md-3">
            <Form.Label>{t("Start Date")}</Form.Label>
            <Form.Control
              type="date"
              name="dateStart"
              value={
                values.dateStart !== null
                  ? moment(values.dateStart, "YYYY-MM-DD").format("YYYY-MM-DD")
                  : ""
              }
              disabled={values.sended ? true : false}
              isInvalid={Boolean(touched.dateStart && errors.dateStart)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.dateStart && (
              <Form.Control.Feedback type="invalid">
                {errors.dateStart}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3 col-lg-2 col-md-3">
            <Form.Label>{t("Start Time")}</Form.Label>
            <Form.Control
              type="time"
              name="timeStart"
              value={values.timeStart}
              isInvalid={Boolean(touched.timeStart && errors.timeStart)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.timeStart && (
              <Form.Control.Feedback type="invalid">
                {errors.timeStart}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3 col-lg-2 col-md-3">
            <Form.Label>{t("End Date")}</Form.Label>
            <Form.Control
              type="date"
              name="dateEnd"
              value={
                values.dateEnd !== null
                  ? moment(values.dateEnd, "YYYY-MM-DD HH:mm:ss Z").format(
                      "YYYY-MM-DD"
                    )
                  : ""
              }
              disabled={values.sended ? true : false}
              isInvalid={Boolean(touched.dateEnd && errors.dateEnd)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.dateEnd && (
              <Form.Control.Feedback type="invalid">
                {errors.dateEnd}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3 col-lg-2 col-md-3">
            <Form.Label>{t("End Time")}</Form.Label>
            <Form.Control
              type="time"
              name="timeEnd"
              value={values.timeEnd}
              isInvalid={Boolean(touched.timeEnd && errors.timeEnd)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.timeEnd && (
              <Form.Control.Feedback type="invalid">
                {errors.timeEnd}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3 col-lg-4 col-md-5">
            <Form.Label>{t("Hazard Type")}</Form.Label>
            <SelectHazardId
              values={values}
              user={user}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-md-2">
            <Form.Label>{t("Push")}</Form.Label>
            <Form.Control
              as="select"
              name="push"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.push}
              aria-label="Default select example"
              disabled={values.sended ? true : false}
            >
              <option>{t("Open this select menu")}</option>
              {pushList.map((item, index) => {
                return (
                  <option value={item.value} key={index}>
                    {t(item.title)}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3 col-md-3">
            <Form.Label>{t("Category")}</Form.Label>
            <SelectPostCategoryId
              values={values}
              user={user}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-md-2">
            <Form.Label>{t("State")}</Form.Label>
            <Form.Control
              as="select"
              name="active"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.active}
              aria-label="Default select example"
              disabled={values.sended ? true : false}
            >
              <option>{t("Open this select menu")}</option>
              {stateList.map((state, index) => {
                return (
                  <option value={state.value} key={index}>
                    {t(state.title)}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3 col-md-3">
            <Form.Label>{t("Auto Forecast")}</Form.Label>
            <Form.Control
              as="select"
              name="auto"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.auto}
              aria-label="Default select example"
              disabled={values.sended ? true : user.levelId > 13 ? false : true}
            >
              <option>{t("Open this select menu")}</option>
              {stateList.map((state, index) => {
                return (
                  <option value={state.value} key={index}>
                    {t(state.title)}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3 col-lg-4 col-md-5">
            <Form.Label>{t("Owner")}</Form.Label>
            <SelectCustomerId
              values={values}
              user={user}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("Content")}</Form.Label>
            <Editor
              isInvalid={Boolean(touched.content && errors.content)}
              name="content"
              onBlur={handleBlur}
              onChange={handleChange}
              apiKey="lucyqqrkzy6x5er3899bg091va8ugje0csmz13rt6qyofdxc"
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={values.content}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | bulletin push | " +
                  "removeformat code wordcount | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                setup: (editor) => {
                  editor.ui.registry.addButton("bulletin", {
                    text: t("Forecast"),
                    icon: "warning",
                    onAction: () => handleForecastButtonClick(editor),
                  });

                  editor.ui.registry.addButton("push", {
                    text: t("Push"),
                    icon: "notice",
                    onAction: () =>
                      handlePushButtonClick(
                        editor,
                        values.title,
                        values.postId
                      ),
                  });
                },
              }}
            />

            {/*!!touched.content && (
              <Form.Control.Feedback type="invalid">
                {errors.content}
              </Form.Control.Feedback>
            )*/}
          </Form.Group>

          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {t("Save")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default EditForm;
