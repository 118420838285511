import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  /*Badge,Dropdown,*/
  Card,
  Table,
  Modal,
  Button,
  Tab,
  Tabs,
} from "react-bootstrap";
/*import { MoreHorizontal } from "react-feather";*/
import { useTable } from "react-table";
import useAuth from "../../hooks/useAuth";
import axios from "../../utils/axios";
import EditForm from "./EditForm";
import DragDropFile from "./Images";
import Videos from "./Vidéos";
import Map from "./Map";
import Timeline from "./Timeline";
import "./Images.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faToggleOn,
  faToggleOff,
  faCloudArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
require("moment/locale/fr");

const News = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [lastReports, setLastReports] = useState([]);
  const [dataResponse, setDataResponse] = useState([]);

  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [fnConfirm, setFnConfirm] = useState(null);

  useEffect(() => {
    if (typeof user.customerId !== "undefined" && !show) {
      axios
        .get(`/news/get/${user.customerId}`)
        .then((response) => {
          var d = [];
          response.data.data.map((item) => {
            var newy = {
              postId: item.postId,
              push: item.push ? (
                <FontAwesomeIcon icon={faStar} size="1x" color={`#FFC500`} />
              ) : null,
              title: item.title,
              date: (
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  {item.sended !== null
                    ? moment(
                        item.dateStart.replace(".000Z", `.000${user.utc}`)
                      ).format("L LT")
                    : `-`}
                </div>
              ),
              auto: item.auto ? (
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCloudArrowDown}
                    size="xl"
                    color={`#FFBF00`}
                  />
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCloudArrowDown}
                    size="xl"
                    color={`#000000`}
                  />
                </div>
              ),
              //active: item.active ? t("Enabled") : t("Disabled"),
              active: item.active ? (
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faToggleOn}
                    size="xl"
                    color={`#006400`}
                  />
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faToggleOff}
                    size="xl"
                    color={`#FF0000`}
                  />
                </div>
              ),
            };
            d = [...d, newy];
            return newy;
          });
          setLastReports(d);
          setDataResponse(response.data.data);
        })
        .catch((error) => console.log(error));
    }
  }, [user, show, t]);

  const data = React.useMemo(() => lastReports, [lastReports]);

  const columns = React.useMemo(
    () => [
      {
        Header: t("#"),
        accessor: "postId",
      },
      {
        Header: <FontAwesomeIcon icon={faStar} size="1x" color={`#FFC500`} />,
        accessor: "push",
      },
      {
        Header: t("Title"),
        accessor: "title",
      },
      {
        Header: t("Date"),
        accessor: "date",
      },
      {
        Header: t("Auto"),
        accessor: "auto",
      },
      {
        Header: t("State"),
        accessor: "active",
      },
    ],
    [t]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const [selectRowValues, setSelectedRowValues] = useState({
    postId: null,
    title: "",
    alias: "",
    content: "",
    hazardId: null,
    push: 0,
    auto: 0,
    active: 1,
    submit: false,
    postCategoryId: null,
  });
  const handleShow = (postId) => {
    let values = dataResponse.filter((item) => item.postId === postId);
    setSelectedRowValues(values[0]);
    setShow(true);
  };

  const handleClose = () => setShow(false);
  const handleCancel = () => setShowConfirm(false);
  const handleCreate = () => {
    setSelectedRowValues({
      postId: null,
      title: "",
      alias: "",
      content: "",
      hazardId: null,
      dateStart: moment().format("YYYY-MM-DD LT"),
      timeStart: "",
      dateEnd: "",
      timeEnd: "",
      push: 0,
      auto: 0,
      active: 1,
      submit: false,
      postCategoryId: 6,
    });
    setShow(true);
  };

  const handleRemove = (postId) => {
    axios
      .delete(`/news/trash/${postId}`)
      .then(() => {
        setShow(false);
      })
      .catch((error) => console.log(error));
  };

  const ModalView = ({ show, handleClose, setShowConfirm }) => {
    return (
      <Modal
        show={show}
        onHide={() => handleClose()}
        size="xl"
        style={{ zIndex: 1053, overlay: { zIndex: 1054 } }}
      >
        <Modal.Header closeButton>
          <h2>{selectRowValues.postId !== null ? t("Edit") : t("New")}</h2>
        </Modal.Header>
        <Modal.Body className="m-3">
          {selectRowValues.postId !== null ? (
            <Tabs
              defaultActiveKey="content"
              id="justify-tab-example"
              className="mb-3"
              justify
            >
              <Tab eventKey="content" title={t("Content")}>
                <EditForm
                  values={selectRowValues}
                  user={user}
                  setShow={setShow}
                />
              </Tab>

              <Tab eventKey="images" title={t("Images")}>
                <DragDropFile values={selectRowValues} user={user} />
              </Tab>
              <Tab eventKey="timeline" title={t("Timeline")}>
                <Timeline values={selectRowValues} user={user} />
              </Tab>
              <Tab eventKey="videos" title={t("Videos")}>
                <Videos values={selectRowValues} user={user} />
              </Tab>
              <Tab eventKey="map" title={t("Map")}>
                <Map values={selectRowValues} user={user} />
              </Tab>
            </Tabs>
          ) : (
            <EditForm
              values={selectRowValues}
              user={user}
              setSelectedRowValues={setSelectedRowValues}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {selectRowValues.sended === null ? (
            <Button
              variant="primary"
              onClick={() => {
                setShowConfirm(true);
                setFnConfirm("send");
              }}
            >
              {t("Send")}
            </Button>
          ) : null}
          {Object.keys(selectRowValues).length > 0 ? (
            <Button
              onClick={() => {
                setShowConfirm(true);
                setFnConfirm("remove");
              }}
              variant="danger"
              className=" rounded"
            >
              <FontAwesomeIcon icon={faTrashAlt} fixedWidth /> {t("Delete")}
            </Button>
          ) : null}
          <Button variant="secondary" onClick={() => handleClose()}>
            {t("Close")}
          </Button>
        </Modal.Footer>
        <ConfirmationView handleCancel={handleCancel} />
      </Modal>
    );
  };

  const ConfirmationView = ({ handleCancel }) => {
    return (
      <Modal
        show={showConfirm}
        onHide={() => handleCancel()}
        centered
        /*style={{ zIndex: 1056, overlay: { zIndex: 1055 } }}*/
      >
        <Modal.Header closeButton>{t("Confirmation")}</Modal.Header>
        <Modal.Body className="m-3">{t("Are you sure?")}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleCancel()}>
            {t("Cancel")}
          </Button>

          <Button
            variant="success"
            onClick={() => {
              switch (fnConfirm) {
                case "remove":
                  handleRemove(selectRowValues.postId);
                  break;
                case "send":
                  break;
                default:
                  break;
              }
              setShowConfirm(false);
            }}
          >
            {t("Yes")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div>
      <Button className="mb-3" variant="primary" onClick={() => handleCreate()}>
        {t("New")}
      </Button>

      <Card className="flex-fill w-100">
        <Card.Header>
          <Card.Title className="mb-0">{t("News")}</Card.Title>
        </Card.Header>
        <Table
          className="my-0"
          striped
          bordered
          hover
          size="sm"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        onClick={() => handleShow(cell.row.values.postId)}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <ModalView
          show={show}
          handleClose={handleClose}
          setShowConfirm={setShowConfirm}
          setFnConfirm={setFnConfirm}
        />
      </Card>
    </div>
  );
};

export default News;
