import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import Statistics from "./charts/Statistics";
import LineChart from "./charts/Line";
import BarChart from "./charts/BarChart";
import Pie from "./charts/Pie";
import Doughnut from "./charts/Doughnut";
import axios from "../../utils/axios";
import RadarChart from "./charts/Radar";
/*import Languages from "./charts/Languages";
import Traffic from "./charts/Traffic";
import WorldMap from "./charts/WorldMap";*/

const Analytics = ({ data, printComponentRef }) => {
  const { t } = useTranslation();

  const [fields] = useState(
    data.fields !== null ? JSON.parse(data.fields) : []
  );
  const [fieldsCharted, setFieldCharted] = useState([]);
  const [surveys, setSurveys] = useState([]);

  useEffect(() => {
    axios
      .get(`/stats/getSurveys/${data.channelId}`)
      .then((response) => {
        var surveyParse = [];
        let surveysData = response.data.data.map((survey) => [
          ...surveyParse,
          JSON.parse(survey.dataJson),
        ]);
        console.log(surveysData);
        setSurveys(surveysData.flat(1));
      })
      .catch((error) => console.log(error));
  }, [data.channelId, setSurveys]);

  useEffect(() => {
    let charted = fields.filter(
      (field) =>
        typeof field.options.chart !== "undefined" && field.options.chart !== ""
    );

    setFieldCharted(charted);
  }, [fields]);

  const getRaw = (idKey) => {
    var raw = [];
    raw = surveys.map((survey, index) => {
      return survey ? survey.filter((item) => item.key === idKey) : null;
    });
    let raw2 = raw.filter((s) => s !== null);
    return raw2.flat(1);
  };

  return (
    <Container fluid className="p-0">
      <Row>
        <Col xl="12" className="d-flex">
          <Statistics
            data={data}
            printComponentRef={printComponentRef}
            surveys={surveys}
          />
        </Col>
      </Row>
      <Row>
        {fieldsCharted.map((field, index) => {
          var chart = null;
          switch (parseInt(field.options.chart)) {
            case 1:
              chart = <BarChart field={field} raw={getRaw(field.idKey)} />;
              break;
            case 2:
              chart = <Pie field={field} raw={getRaw(field.idKey)} />;
              break;
            case 3:
              chart = <LineChart field={field} raw={getRaw(field.idKey)} />;
              break;
            case 4:
              chart = <RadarChart field={field} raw={getRaw(field.idKey)} />;
              break;
            case 7:
              chart = <Doughnut field={field} raw={getRaw(field.idKey)} />;
              break;
            default:
              chart = t("Error");
              break;
          }

          return (
            <Col
              xs={12}
              lg={field.options.sizeChart}
              key={index}
              className={``}
            >
              {chart}
            </Col>
          );
        })}
        {/*<Col lg="6" xl="7" className="d-flex">
          <WorldMap />
        </Col>
      </Row>
      <Row>
        <Col lg="4" className="d-flex">
          <Languages />
        </Col>
        <Col lg="4" className="d-flex">
          <BarChart />
        </Col>
        <Col lg="4" className="d-flex">
          <RadarChart />
        </Col>
      </Row>
      <Row>
        <Col lg="5" xl="4" className="d-flex">
          <PieChart />
        </Col>
        <Col lg="7" xl="8" className="d-flex">
          <Traffic />
        </Col>*/}
      </Row>
    </Container>
  );
};

export default Analytics;
