import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import axios from "../../utils/axios";
import illustration from "../../assets/img/illustrations/help.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

const Marketplace = ({ name, badges, description, image, version }) => {
  const { t } = useTranslation();
  return (
    <Card>
      {image ? <Card.Img src={image} alt={`${t(name)} illustration`} /> : ""}
      <Card.Header className="px-4 pt-4">
        <Card.Title className="mb-0 text-sm">Module</Card.Title>
        <Card.Title className="mb-0">{t(name)}</Card.Title>
        {badges.length > 0 &&
          badges.map((badge, index) => (
            <Badge className="my-2 me-1" bg={badge.color} key={index}>
              {t(badge.state)}
            </Badge>
          ))}
        <Badge className="my-2 me-1 text-black" bg={`light`}>
          v {version}
        </Badge>
      </Card.Header>
      <Card.Body className="px-4 pt-2">
        <p
          className=""
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 4,
            overflow: "hidden",
          }}
        >
          {description}
        </p>

        {badges.length > 0 &&
          badges.map((badge, index) => (
            <Button
              className="my-2 me-1"
              variant={`outline-${
                badge.state === `Available`
                  ? `danger`
                  : badge.state === `Disabled`
                  ? "success"
                  : badge.color
              }`}
              key={index}
              size="sm"
            >
              {badge.state === `Demo`
                ? t(`Test`)
                : badge.state === `Available`
                ? t(`Disable`)
                : badge.state === `Disabled`
                ? t(`Enable`)
                : t(`Discover`)}
            </Button>
          ))}
      </Card.Body>
    </Card>
  );
};

const Projects = () => {
  const { t } = useTranslation();
  const [help, setHelp] = useState(false);

  const [modules, setModules] = useState([]);

  useEffect(() => {
    axios
      .get(`marketplace/get`)
      .then((response) => {
        setModules(response.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <React.Fragment>
      <Helmet title={t(`Marketplace`)} />
      <Container fluid className="p-0">
        <Button
          className="float-end mt-n1 ms-1"
          variant="warning"
          onClick={() => setHelp(!help)}
        >
          <FontAwesomeIcon icon={faQuestion} />
        </Button>
        <h1 className="h3 mb-3">{t(`Marketplace`)}</h1>
        <Row className={`m-1 ${!help && `d-none`}`}>
          <Col className="bg-light rounded-lg ps-2 mb-4">
            <Row className="">
              <Col xs={2} className=" text-center p-0 m-0">
                <img
                  src={illustration}
                  alt="Customer Support"
                  className="img-fluid illustration-img"
                />
              </Col>
              <Col
                className="p-4 pt-2 text-lg"
                style={{ whiteSpace: "pre-line" }}
              >
                {t("HELP_MARKETPLACE")}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          {modules.map((module, index) => {
            const badges = [];

            if (module.demo) {
              badges.push({
                state: `Demo`,
                color: "warning",
              });
            }

            if (module.onsale) {
              badges.push({
                state: `Available`,
                color: "success",
              });
            } else {
              badges.push({
                state: `Coming Soon`,
                color: "primary",
              });
            }

            return (
              <Col md="6" lg="3" key={index}>
                <Marketplace
                  name={`TITLE_` + module.alias}
                  badges={badges}
                  version={module.version}
                  description={module.detail}
                  image={`https://objects.pwenter.io/modules/${module.alias}.jpg`}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Projects;
