import React from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";

const customerOptions = {
  5: {
    name: "France",
  },
  2: {
    name: "Guadeloupe",
  },
  1: {
    name: "Pointe-à-Pitre",
  },
  28: {
    name: "Les Abymes",
  },
  30: {
    name: "Le Gosier",
  },
  31: {
    name: "Sainte-Anne",
  },
  32: {
    name: "Saint-François",
  },
  33: {
    name: "La Désirade",
  },
  12: {
    name: "Trois-Rivières",
  },
  14: {
    name: "Bouillante",
  },
  15: {
    name: "Goyave",
  },
  16: {
    name: "Morne-à-l-'Eau",
  },
  17: {
    name: "Le Moule",
  },
  18: {
    name: "Petit-Canal",
  },
  19: {
    name: "Anse-Bertrand",
  },
  20: {
    name: "Port-Louis",
  },
  22: {
    name: "Pointe-Noire",
  },
  23: {
    name: "Saint-Claude",
  },
  11: {
    name: "CA Cap Excellence",
  },
  21: {
    name: "CA Nord Grande-Terre",
  },
  10: {
    name: "Guyane",
  },
  4: {
    name: "Martinique",
  },
  9: {
    name: "Saint-Barthélémy",
  },
  8: {
    name: "Nouvelle-Calédonie",
  },
  6: {
    name: "Saint-Martin",
  },
  7: {
    name: "La Réunion",
  },
  36: {
    name: "Mayotte",
  },
  24: {
    name: "EDF Archipel Guadeloupe",
  },
  25: {
    name: "Routes de Guadeloupe",
  },
  26: {
    name: "SMGEAG",
  },
  27: {
    name: "SDIS 971",
  },
  35: {
    name: "Prévention MAIF",
  },
};

const NavbarCustomers = () => {
  const { user, changeCustomer } = useAuth();
  const { t } = useTranslation();

  const CurrentCustomer = ({ selectedCustomer }) => {
    return (
      <div>
        <img
          src={`https://objects.pwenter.io/logos/customers/${user.customerId}.png`}
          alt={selectedCustomer.name}
          className="me-1"
        />
        <span className="align-middle text-sm">
          {t(customerOptions[user.customerId].name)}
        </span>
      </div>
    );
  };

  const Menu = () => {
    const selectedCustomer = customerOptions[user.customerId];

    return (
      <Dropdown className="me-2 nav-item" align="end">
        <Dropdown.Toggle as="a" className="nav-link nav-flag">
          <CurrentCustomer selectedCustomer={selectedCustomer} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {Object.keys(customerOptions).map((customer) => {
            return (
              <Dropdown.Item
                key={customer}
                onClick={() =>
                  customer !== user.customerId
                    ? changeCustomer(user.userId, customer)
                    : null
                }
              >
                <img
                  src={`https://objects.pwenter.io/logos/customers/${customer}.png`}
                  alt={t(customerOptions[customer].name)}
                  width="20"
                  className="align-middle me-1"
                />
                <span className="align-middle">
                  {t(customerOptions[customer].name)}
                </span>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return typeof user.customerId !== "undefined" && user.levelId > 13 ? (
    <Menu />
  ) : typeof user.customerId !== "undefined" && user.levelId > 13 ? (
    <CurrentCustomer />
  ) : null;
};

export default NavbarCustomers;
