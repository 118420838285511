import React from "react";
import { Row, Col, Button, Form, Alert } from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import SelectHazardZoneId from "./SelectHazardZoneId";
import SelectHazardId from "./SelectHazardId";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import axios from "../../utils/axios";
import moment from "moment";
require("moment/locale/fr");

function EditFormVideo({ values, videoPost, setVideoPost, setUpdate }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const adviseList = [
    { title: "Yes", value: true },
    { title: "No", value: false },
  ];

  const stateList = [
    { title: "Enabled", value: true },
    { title: "Disabled", value: false },
  ];

  return (
    <Row>
      <Col>
        <h3>{t("Form")}</h3>

        <Button
          className="mt-1 mb-3"
          type="submit"
          variant="primary"
          size="sm"
          disabled={values.hazardZoneVideoId ? false : true}
          onClick={() =>
            setVideoPost({
              hazardZoneVideoId: null,
              hazardZoneId: videoPost.hazardZoneId,
              title: "",
              url: "",
              hazardId: videoPost.hazardId,
              createdAt: moment().format("YYYY-MM-DD"),
              eventTime: moment().format("LT"),
              active: true,
              advise: false,
              submit: false,
            })
          }
        >
          {t("New")}
        </Button>

        <Formik
          enableReinitialize={true}
          initialValues={values}
          validationSchema={Yup.object().shape({
            title: Yup.string()
              .min(6, t("less_char", { n: 12 }))
              .max(120)
              .required("Title is required"),
            url: Yup.string().max(200),
            createdAt: Yup.date()
              .max(new Date())
              .required("Message is required"),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm }
          ) => {
            try {
              const params = {
                hazardZoneVideoId: values.hazardZoneVideoId,
                hazardZoneId: values.hazardZoneId,
                title: values.title,
                url: values.url,
                createdAt: moment(
                  `${values.createdAt} ${
                    values.eventTime ? values.eventTime : "00:00"
                  }:00 ${user.utc}`
                ).format("YYYY-MM-DD HH:mm:ss Z"),
                active: values.active,
                advise: values.advise,
              };
              console.log(params);
              if (params.hazardZoneVideoId === null) {
                delete params.hazardZoneVideoId;
                axios
                  .put(`/hazards/hazardZoneVideo/insert`, params)
                  .then((response) => {
                    console.log("insert");
                    let data = response.data.data;
                    setVideoPost({
                      hazardZoneVideoId: data.hazardZoneVideoId,
                      hazardZoneId: data.hazardZoneId,
                      hazardId: values.hazardId,
                      title: data.title,
                      url: data.url,
                      createdAt: moment(data.createdAt).format("YYYY-MM-DD"),
                      eventTime: moment(data.createdAt).format("LT"),
                      active: data.active,
                      submit: false,
                    });
                  })
                  .catch((error) => console.log(error));
              } else {
                axios
                  .put(`/hazards/hazardZoneVideo/update`, params)
                  .then(() => {
                    setUpdate(true);
                  })
                  .catch((error) => console.log(error));
              }
            } catch (error) {
              const message = error.message || "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit} className="row">
              {errors.submit && (
                <Alert className="my-3" variant="danger">
                  {errors.submit}
                </Alert>
              )}
              <Form.Group className="mb-3 col-md-7">
                <Form.Label>{t("Title")}</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  placeholder={t("Title")}
                  value={values.title}
                  disabled={values.sended ? true : false}
                  isInvalid={Boolean(touched.title && errors.title)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.title && (
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3 col-md-5">
                <Form.Label>{t("URL")}</Form.Label>
                <Form.Control
                  type="url"
                  name="url"
                  placeholder={t("URL")}
                  value={values.url}
                  disabled={values.sended ? true : false}
                  isInvalid={Boolean(touched.url && errors.url)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.url && (
                  <Form.Control.Feedback type="invalid">
                    {errors.url}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3 col-md-2">
                <Form.Label>{t("Advise")}</Form.Label>
                <Form.Control
                  as="select"
                  name="advise"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.advise}
                  aria-label="Default select example"
                  disabled={values.sended ? true : false}
                >
                  <option>{t("Open this select menu")}</option>
                  {adviseList.map((item, index) => {
                    return (
                      <option value={item.value} key={index}>
                        {t(item.title)}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3 col-md-3">
                <Form.Label>{t("Date")}</Form.Label>
                <Form.Control
                  type="date"
                  name="createdAt"
                  value={moment(values.createdAt).format("YYYY-MM-DD")}
                  disabled={values.sended ? true : false}
                  isInvalid={Boolean(touched.createdAt && errors.createdAt)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.createdAt && (
                  <Form.Control.Feedback type="invalid">
                    {errors.createdAt}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3 col-md-2">
                <Form.Label>{t("Time")}</Form.Label>
                <Form.Control
                  type="time"
                  name="eventTime"
                  value={values.eventTime != null ? values.eventTime : ""}
                  disabled={values.sended ? true : false}
                  isInvalid={Boolean(touched.eventTime && errors.eventTime)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.eventTime && (
                  <Form.Control.Feedback type="invalid">
                    {errors.eventTime}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3 col-md-3">
                <Form.Label>{t("Hazard")}</Form.Label>
                <SelectHazardId
                  values={values}
                  user={user}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </Form.Group>

              {values.hazardId !== "" && (
                <Form.Group className="mb-3 col-md-3">
                  <Form.Label>{t("Area")}</Form.Label>
                  <SelectHazardZoneId
                    values={values}
                    user={user}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </Form.Group>
              )}

              {values.hazardZoneVideoId === null ? (
                <Form.Group className="mb-3 col-md-2">
                  <Form.Label>{t("State")}</Form.Label>
                  <Form.Control
                    as="select"
                    name="active"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.active}
                    aria-label="Default select example"
                    disabled={values.sended ? true : false}
                  >
                    <option>{t("Open this select menu")}</option>
                    {stateList.map((state, index) => {
                      return (
                        <option value={state.value} key={index}>
                          {t(state.title)}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              ) : null}

              <div className="text-center mt-3">
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  disabled={isSubmitting}
                >
                  {values.hazardZoneVideoId === null ? t("Add") : t("Edit")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}

export default EditFormVideo;
