import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  /*Badge,Dropdown,*/
  Card,
  Table,
  Modal,
  Button,
  Tab,
  Tabs,
} from "react-bootstrap";
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
} from "react-table";
import { matchSorter } from "match-sorter";
import useAuth from "../../hooks/useAuth";
import axios from "../../utils/axios";
import EditForm from "./EditForm";
import Overview from "./Overview";
import Survey from "./Surveys";
import Chat from "./Chat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
require("moment/locale/fr");

const Collaborations = ({ channelId = null }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [lastReports, setLastReports] = useState([]);
  const [dataResponse, setDataResponse] = useState([]);

  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [fnConfirm, setFnConfirm] = useState(null);

  useEffect(() => {
    if (typeof user.customerId !== "undefined" && !show) {
      const getImageName = (generatedAt) => {
        var date = moment.utc(`${generatedAt}`);
        var image = moment(date.toISOString()).unix();
        return image;
      };

      axios
        .get(`/reports/getReportsShared/${user.customerId}`)
        .then((response) => {
          var d = [];
          response.data.data.reports.map((item, index) => {
            let image = getImageName(item.generatedAt);
            var newy = {
              imageCol: (
                <img
                  src={`https://objects.pwenter.io/uploads/${image}.jpg`}
                  width="60"
                  height="60"
                  className="img-thumbnail me-2"
                  alt="Reports"
                  key={index}
                />
              ),
              typeCol: item.Hazard.title,
              cityCol: item.Channel.Customer.shortName,
              dateCol: moment(item.generatedAt, "YYYY-MM-DD HH:mm:ss").format(
                "llll"
              ),
              commentCol: item.comment !== "null" ? item.comment : t(`None`),
              latCol: item.point.coordinates[1].toFixed(4),
              lngCol: item.point.coordinates[0].toFixed(4),
              statusCol: item.ReportsState.title,
              reportId: item.reportId,
              channelTitle: item.Channel.title ? item.Channel.title : t(`None`),
              active: item.active ? t("Enabled") : t("Disabled"),
            };
            d = [...d, newy];
            return newy;
          });
          setLastReports(d);
          setDataResponse(response.data.data.reports);
        })
        .catch((error) => console.log(error));
    }
  }, [user, show, t, channelId]);

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    const { t } = useTranslation();
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        style={{
          width: "100%",
          borderStyle: "none",
          color: "#CCC",
        }}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">{t("All")}</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const { t } = useTranslation();
    const count = preFilteredRows.length;

    return (
      <input
        style={{
          width: "100%",
          borderStyle: "none",
          color: "#CCC",
        }}
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`${t("Search")} ${count} ${t("records")}...`}
      />
    );
  }

  const data = useMemo(() => lastReports, [lastReports]);

  const columns = useMemo(
    () => [
      {
        Header: t("#"),
        accessor: "reportId",
        disableFilters: true,
      },
      {
        Header: t("Image"),
        accessor: "imageCol",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: t("Type"),
        accessor: "typeCol", // accessor is the "key" in the data
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: t("From"),
        accessor: "cityCol",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: t("Date"),
        accessor: "dateCol",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: t("Comment"),
        accessor: "commentCol",
        disableSortBy: true,
      },
      {
        Header: t("Latitude"),
        accessor: "latCol",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: t("Longitude"),
        accessor: "lngCol",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: t("Channel"),
        accessor: "channelTitle",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: t("Status"),
        accessor: "statusCol",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: t("State"),
        accessor: "active",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
    ],
    [t]
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        filters: [{ id: "active", value: t("Enabled") }],
      },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [selectRowValues, setSelectedRowValues] = useState({
    reportId: null,
    title: "",
    alias: "",
    content: "",
    hazardId: null,
    push: 0,
    active: 1,
    submit: false,
    postCategoryId: null,
  });
  const handleShow = (reportId) => {
    let values = dataResponse.filter((item) => item.reportId === reportId);
    setSelectedRowValues(values[0]);
    setShow(true);
  };

  const handleClose = () => setShow(false);
  const handleCancel = () => setShowConfirm(false);

  const handleRemove = (reportId) => {
    axios
      .delete(`/reports/trash/${reportId}`)
      .then((response) => {
        setShow(false);
      })
      .catch((error) => console.log(error));
  };

  const ModalView = ({ show, handleClose, setShowConfirm }) => {
    return (
      <Modal
        show={show}
        onHide={() => handleClose()}
        size="xl"
        style={{ zIndex: 1053, overlay: { zIndex: 1054 } }}
      >
        <Modal.Header closeButton>
          <h2>{selectRowValues.reportId !== null ? t("Edit") : t("New")}</h2>
        </Modal.Header>
        <Modal.Body className="m-3">
          {selectRowValues.reportId !== null ? (
            <Tabs
              defaultActiveKey="content"
              id="justify-tab-example"
              className="mb-3"
              justify
            >
              <Tab eventKey="content" title={t("Overview")}>
                <EditForm
                  values={selectRowValues}
                  user={user}
                  setShow={setShow}
                />
                <Overview
                  data={{
                    ...selectRowValues,
                    firstname: selectRowValues.User.firstname,
                    lastname: selectRowValues.User.lastname,
                    phone: selectRowValues.User.phone,
                    email: selectRowValues.User.email,
                  }}
                  user={user}
                />
              </Tab>
              {typeof selectRowValues.Surveys !== "undefined" &&
              selectRowValues.Surveys.length > 0 &&
              selectRowValues.Surveys[0].dataJson !== "null" &&
              typeof selectRowValues.Channel.fields !== "undefined" ? (
                <Tab eventKey="response" title={t("Response")}>
                  <Survey
                    data={{
                      dataJson: selectRowValues.Surveys[0].dataJson,
                      fields: selectRowValues.Channel.fields,
                    }}
                    user={user}
                  />
                </Tab>
              ) : null}

              <Tab eventKey="journal" title={t("Log")}>
                <Chat
                  customerId={selectRowValues.customerId}
                  reportId={selectRowValues.reportId}
                />
              </Tab>
            </Tabs>
          ) : (
            <EditForm values={selectRowValues} user={user} setShow={setShow} />
          )}
        </Modal.Body>
        <Modal.Footer>
          {selectRowValues.sended === null ? (
            <Button
              variant="primary"
              onClick={() => {
                setShowConfirm(true);
                setFnConfirm("send");
              }}
            >
              {t("Send")}
            </Button>
          ) : null}
          <Button variant="secondary" onClick={() => handleClose()}>
            {t("Close")}
          </Button>
        </Modal.Footer>
        <ConfirmationView handleCancel={handleCancel} />
      </Modal>
    );
  };

  const ConfirmationView = ({ handleCancel }) => {
    return (
      <Modal
        show={showConfirm}
        onHide={() => handleCancel()}
        centered
        /*style={{ zIndex: 1056, overlay: { zIndex: 1055 } }}*/
      >
        <Modal.Header closeButton>{t("Confirmation")}</Modal.Header>
        <Modal.Body className="m-3">{t("Are you sure?")}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleCancel()}>
            {t("Cancel")}
          </Button>

          <Button
            variant="success"
            onClick={() => {
              switch (fnConfirm) {
                case "remove":
                  handleRemove(selectRowValues.reportId);
                  break;
                case "send":
                  break;
                default:
                  break;
              }
              setShowConfirm(false);
            }}
          >
            {t("Yes")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title className="mb-0">{t("Collaborations")}</Card.Title>
      </Card.Header>
      <Table
        className="my-0"
        striped
        bordered
        hover
        size="sm"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="align-top"
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={faChevronDown} fixedWidth />
                      ) : (
                        <FontAwesomeIcon icon={faChevronUp} fixedWidth />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      onClick={() => handleShow(cell.row.values.reportId)}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <div className="pagination">
        <Button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          variant="outline-none"
          className="mx-2 "
        >
          <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
          <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
        </Button>
        <Button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          variant="outline-none"
          className="mx-2"
        >
          <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
        </Button>
        <Button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          variant="outline-none"
          className="mx-2"
        >
          <FontAwesomeIcon icon={faChevronRight} fixedWidth />
        </Button>
        <Button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          variant="outline-none"
          className="mx-2"
        >
          <FontAwesomeIcon icon={faChevronRight} fixedWidth />
          <FontAwesomeIcon icon={faChevronRight} fixedWidth />
        </Button>
        <span className="px-3">
          {" "}
          {t("Page")}{" "}
          <strong>
            {pageIndex + 1}
            {t(" of ")}
            {pageOptions.length}
          </strong>{" "}
        </span>
        <span className="px-3">
          {t("Go to page")}:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{
              width: "100px",
              borderStyle: "none",
            }}
          />
        </span>{" "}
        <select
          value={pageSize}
          style={{
            borderColor: "#DDD",
            borderStyle: "none",
            border: "1px",
          }}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {t("Show")} {pageSize}
            </option>
          ))}
        </select>
      </div>
      <ModalView
        show={show}
        handleClose={handleClose}
        setShowConfirm={setShowConfirm}
        setFnConfirm={setFnConfirm}
      />
    </Card>
  );
};

export default Collaborations;
