import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import axios from "../../utils/axios";
import illustration from "../../assets/img/illustrations/help.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

const PartnersList = ({
  name,
  shortName,
  image,
  country,
  partner,
  customerId,
  partnerId,
  setUpdate,
}) => {
  const partnership = (state) => {
    if (state) {
      axios
        .put(`account/addPartner`, { customerId, partnerId })
        .then((response) => {
          setUpdate(response);
        })
        .catch((error) => console.log(error));
    } else {
      axios
        .delete(`account/trashPartner/${customerId}/${partnerId}`)
        .then((response) => {
          setUpdate(response);
        })
        .catch((error) => console.log(error));
    }
  };

  const { t } = useTranslation();
  return (
    <Card>
      {image ? (
        <Card.Img src={image} alt={`${name} logo`} className="px-4 pt-2" />
      ) : null}
      <Card.Header className="px-4 pt-4">
        <Card.Title className="mb-0">{shortName}</Card.Title>
        <Badge className="me-1 text-black" bg={`light`}>
          {country}
        </Badge>
      </Card.Header>
      <Card.Body className="px-4 pt-0 text-center">
        <p>{name}</p>
        {partner ? (
          <Button
            className="my-2 me-1"
            variant={`outline-danger`}
            size="sm"
            onClick={() => partnership(false)}
          >
            {t(`Remove`)}
          </Button>
        ) : (
          <Button
            className="my-2 me-1"
            variant={`outline-success`}
            size="sm"
            onClick={() => partnership(true)}
          >
            {t(`Add`)}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

const Partnerships = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [partnersList, setPartnersList] = useState([]);
  const [update, setUpdate] = useState([]);
  const [help, setHelp] = useState(false);

  useEffect(() => {
    axios
      .get(`account/getPartnersList/${user.customerId}`)
      .then((response) => {
        setPartnersList(response.data.data);
      })
      .catch((error) => console.log(error));
  }, [user, update]);

  const countries = {
    GPE: "Guadeloupe",
    MTQ: "Martinique",
    GUF: "Guyane",
    BLM: "Saint Barthélémy",
    MAF: "Saint Martin",
    REU: "Réunion",
    NCL: "Nouvelle Calédonie",
    FRA: "France",
  };
  return (
    <React.Fragment>
      <Helmet title={t(`Partnerships`)} />
      <Container fluid className="p-0">
        <Button
          className="float-end mt-n1 ms-1"
          variant="warning"
          onClick={() => setHelp(!help)}
        >
          <FontAwesomeIcon icon={faQuestion} />
        </Button>
        <h1 className="h3 mb-3">{t(`Partnerships`)}</h1>
        <Row className={`m-1 ${!help && `d-none`}`}>
          <Col className="bg-light rounded-lg ps-2 mb-4">
            <Row className="">
              <Col xs={2} className=" text-center p-0 m-0">
                <img
                  src={illustration}
                  alt="Customer Support"
                  className="img-fluid illustration-img"
                />
              </Col>
              <Col
                className="p-4 pt-2 text-lg"
                style={{ whiteSpace: "pre-line" }}
              >
                {t("HELP_PARTNERS")}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          {partnersList.map((partner, index) => {
            return (
              <Col md="6" lg="3" key={index}>
                <PartnersList
                  name={partner.name}
                  shortName={partner.shortName}
                  country={countries[partner.country]}
                  partner={
                    partner.partners !== null && partner.partners.length > 0
                  }
                  customerId={user.customerId}
                  partnerId={partner.customerId}
                  image={`https://objects.pwenter.io/logos/customers/${partner.customerId}.png`}
                  setUpdate={setUpdate}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Partnerships;
