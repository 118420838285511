import {
  Bell,
  BookOpen,
  Calendar,
  Sliders,
  Users,
  User,
  Briefcase,
  Cloud,
  Edit,
  Eye,
  Activity,
  LifeBuoy,
  Tool,
  Target,
  Copy,
  FileText,
  Save,
  ThumbsUp,
  /*
  MapPin,
  Inbox,
  Info,
  AlertTriangle,
  Share,
  CheckSquare,
  Grid,
  Heart,
  Layout,
  List,
  PieChart,*/
} from "react-feather";

const dashboardSection = [
  {
    href: "/",
    icon: Sliders,
    title: "Overview",
    badge: "",
    level: 3,
  },
];

const newsSection = [
  {
    href: "/news",
    icon: Edit,
    title: "Articles",
    badge: "",
    level: 7,
  },
  {
    href: "/hazards",
    icon: Activity,
    title: "Hazards",
    level: 11,
  },
];

const notificationSection = [
  {
    href: "/notifications",
    icon: Bell,
    title: "Push Messages",
    badge: "",
    level: 9,
    /*children: [
      {
        href: "/notifications/form",
        title: "New",
        level: 9,
      },
    ],*/
  },
  {
    href: "/notifications/models",
    icon: Save,
    title: "Models",
    badge: "",
    level: 9,
  },
];

const reportSection = [
  {
    href: "/reports",
    icon: Eye,
    title: "Reports",
    badge: "",
    level: 9,
  },
  {
    href: "/collaborations",
    icon: Copy,
    title: "Collaborations",
    level: 9,
  },
  {
    href: "/sheets",
    icon: FileText,
    title: "Report Sheets",
    level: 15,
  },
  {
    href: "/channels",
    icon: Target,
    title: "Channels",
    level: 9,
  },
];

const communitySection = [
  {
    href: "/community",
    icon: Users,
    title: "Membres",
    badge: "",
    level: 9,
    children: [
      {
        href: "/community",
        title: "Connected",
        level: 9,
      },
      {
        href: "/community/enrolled",
        title: "Enrolled",
        level: 9,
      },
      {
        href: "/community/positions",
        title: "Positions",
        level: 14,
      },
    ],
  },
  {
    href: "/community/reliefs",
    icon: LifeBuoy,
    title: "Reliefs",
    level: 9,
  },
];

const accountSection = [
  {
    href: "/account/organization",
    icon: Briefcase,
    title: "Organisation",
    badge: "",
    level: 9,
  },
  {
    href: "/account/profile",
    icon: User,
    title: "Profile",
    level: 5,
  },
  {
    href: "/account/users",
    icon: Users,
    title: "Users",
    badge: "",
    level: 9,
  },
  {
    href: "/account/partnerships",
    icon: ThumbsUp,
    title: "Partnerships",
    badge: "",
    level: 9,
  },
  {
    href: "/account/subscriptions",
    icon: Calendar,
    title: "Abonnement",
    level: 10,
  },
  {
    href: "/account/marketplace",
    icon: Cloud,
    title: "Marketplace",
    level: 10,
  },
  {
    href: "/docs/introduction",
    icon: BookOpen,
    title: "Documentation",
    level: 3,
  },
];

const toolsSection = [
  {
    href: "/tools",
    icon: Tool,
    title: "Conversion",
    badge: "",
    level: 14,
  },
  {
    href: "/tools/zones",
    icon: Tool,
    title: "Zones",
    badge: "",
    level: 14,
  },
];

/*const pagesSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboards",
    badge: "",
    children: [
      {
        href: "/dashboard/default",
        title: "Default",
      },
      {
        href: "/dashboard/analytics",
        title: "Analytics",
      },
      {
        href: "/dashboard/saas",
        title: "SaaS",
      },
      {
        href: "/dashboard/social",
        title: "Social",
      },
      {
        href: "/dashboard/crypto",
        title: "Crypto",
      },
    ],
  },
  {
    href: "/pages",
    icon: Layout,
    title: "Pages",
    children: [
      {
        href: "/pages/profile",
        title: "Profile",
      },
      {
        href: "/pages/settings",
        title: "Settings",
      },
      {
        href: "/pages/clients",
        title: "Clients",
      },
      {
        href: "/pages/projects",
        title: "Projects",
      },
      {
        href: "/pages/invoice",
        title: "Invoice",
      },
      {
        href: "/pages/pricing",
        title: "Pricing",
      },
      {
        href: "/pages/tasks",
        title: "Tasks",
      },
      {
        href: "/pages/chat",
        title: "Chat",
        badge: "New",
      },
      {
        href: "/pages/blank",
        title: "Blank Page",
      },
    ],
  },
  {
    href: "/auth",
    icon: Users,
    title: "Auth",
    children: [
      {
        href: "/auth/sign-in",
        title: "Sign In",
      },
      {
        href: "/auth/sign-up",
        title: "Sign Up",
      },
      {
        href: "/auth/reset-password",
        title: "Reset Password",
      },
      {
        href: "/auth/404",
        title: "404 Page",
      },
      {
        href: "/auth/500",
        title: "500 Page",
      },
    ],
  },
  {
    href: "/docs/introduction",
    icon: BookOpen,
    title: "Documentation",
  },
];


const componentsSection = [
  {
    href: "/ui",
    icon: Grid,
    title: "UI Elements",
    children: [
      {
        href: "/ui/alerts",
        title: "Alerts",
      },
      {
        href: "/ui/buttons",
        title: "Buttons",
      },
      {
        href: "/ui/cards",
        title: "Cards",
      },
      {
        href: "/ui/carousel",
        title: "Carousel",
      },
      {
        href: "/ui/embed-video",
        title: "Embed Video",
      },
      {
        href: "/ui/general",
        title: "General",
      },
      {
        href: "/ui/grid",
        title: "Grid",
      },
      {
        href: "/ui/modals",
        title: "Modals",
      },
      {
        href: "/ui/offcanvas",
        title: "Offcanvas",
      },
      {
        href: "/ui/tabs",
        title: "Tabs",
      },
      {
        href: "/ui/typography",
        title: "Typography",
      },
    ],
  },
  {
    href: "/icons",
    icon: Heart,
    title: "Icons",
    badge: "1500+",
    children: [
      {
        href: "/icons/feather",
        title: "Feather",
      },
      {
        href: "/icons/font-awesome",
        title: "Font Awesome",
      },
    ],
  },
  {
    href: "/forms",
    icon: CheckSquare,
    title: "Forms",
    children: [
      {
        href: "/forms/layouts",
        title: "Layouts",
      },
      {
        href: "/forms/basic-inputs",
        title: "Basic Inputs",
      },
      {
        href: "/forms/input-groups",
        title: "Input Groups",
      },
      {
        href: "/forms/floating-labels",
        title: "Floating Labels",
      },
    ],
  },
  {
    href: "/tables",
    icon: List,
    title: "Tables",
  },
];

const pluginsSection = [
  {
    href: "/form-plugins",
    icon: CheckSquare,
    title: "Form Plugins",
    children: [
      {
        href: "/form-plugins/advanced-inputs",
        title: "Advanced Inputs",
      },
      {
        href: "/form-plugins/formik",
        title: "Formik",
        badge: "New",
      },
      {
        href: "/form-plugins/editors",
        title: "Editors",
      },
    ],
  },
  {
    href: "/advanced-tables",
    icon: List,
    title: "Advanced Tables",
    children: [
      {
        href: "/advanced-tables/pagination",
        title: "Pagination",
      },
      {
        href: "/advanced-tables/column-sorting",
        title: "Column Sorting",
      },
      {
        href: "/advanced-tables/column-filtering",
        title: "Column Filtering",
      },
      {
        href: "/advanced-tables/row-expanding",
        title: "Row Expanding",
      },
      {
        href: "/advanced-tables/row-selection",
        title: "Row Selection",
      },
    ],
  },
  {
    href: "/charts",
    icon: PieChart,
    title: "Charts",
    badge: "New",
    children: [
      {
        href: "/charts/chartjs",
        title: "Chart.js",
      },
      {
        href: "/charts/apexcharts",
        title: "ApexCharts",
        badge: "New",
      },
    ],
  },
  {
    href: "/notifications",
    icon: Bell,
    title: "Notifications",
  },
  {
    href: "/maps",
    icon: MapPin,
    title: "Maps",
    children: [
      {
        href: "/maps/google-maps",
        title: "Google Maps",
      },
      {
        href: "/maps/vector-maps",
        title: "Vector Maps",
      },
    ],
  },
  {
    href: "/calendar",
    icon: Calendar,
    title: "Calendar",
  },
  {
    href: "/404",
    icon: Share,
    title: "Multi Level",
    children: [
      {
        href: "/404",
        title: "Two Levels",
        children: [
          {
            href: "/404",
            title: "Item 1",
          },
          {
            href: "/404",
            title: "Item 2",
          },
        ],
      },
      {
        href: "/404",
        title: "Three Levels",
        children: [
          {
            href: "/404",
            title: "Item 1",
            children: [
              {
                href: "/404",
                title: "Item 1",
              },
              {
                href: "/404",
                title: "Item 2",
              },
            ],
          },
          {
            href: "/404",
            title: "Item 2",
          },
        ],
      },
    ],
  },
];*/

const navItems = [
  {
    title: "Dashboard",
    pages: dashboardSection,
    level: 3,
  },
  {
    title: "News",
    pages: newsSection,
    level: 7,
  },
  {
    title: "Notifications",
    pages: notificationSection,
    level: 9,
  },
  {
    title: "Field Data",
    pages: reportSection,
    level: 9,
  },
  {
    title: "Community",
    pages: communitySection,
    level: 6,
  },
  {
    title: "Account",
    pages: accountSection,
    level: 3,
  },
  {
    title: "Tools",
    pages: toolsSection,
    level: 10,
  },
];

export default navItems;
