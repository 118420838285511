import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Row, Form } from "react-bootstrap";

const ToolsConvert = () => {
  const [message, setMessage] = useState({});
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState(null);
  const [type, setType] = useState("default");

  return (
    <React.Fragment>
      <Helmet title="Conversion" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Conversion</h1>

        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>JSON to Marker</Card.Title>
                <h6 className="card-subtitle text-muted">...</h6>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md="12">
                    <Form.Group className="mb-3">
                      <Form.Label>JSON Format</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        className="mb-3"
                        defaultValue="default"
                        onChange={(e) => setType(e.target.value)}
                      >
                        <option value="default">
                          GeoJSON EXPLOIT - Marker
                        </option>
                        <option value="success">Success</option>
                        <option value="warning">Warning</option>
                        <option value="danger">Danger</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <Button
                  onClick={() => {
                    if (message.length > 0 && type !== "") {
                      // CONVERT ENGINE
                      function titleCase(str) {
                        var splitStr = str.toLowerCase().split(" ");
                        for (var i = 0; i < splitStr.length; i++) {
                          // You do not need to check if i is larger than splitStr length, as your for does that for you
                          // Assign it back to the array
                          splitStr[i] =
                            /*
                          /de|à|des|rn|aux|la|le|au/ == splitStr[i]
                            ? splitStr[i]
                            :*/ splitStr[i].charAt(0).toUpperCase() +
                            splitStr[i].substring(1);
                        }
                        // Directly return the joined string
                        return splitStr.join(" ");
                      }

                      //console.log(JSON.parse(JSON.stringify(message)));

                      let json = JSON.parse(message);
                      let dj = json.features;
                      console.log(json);

                      if (typeof dj !== "undefined") {
                        console.log(dj);
                        var new_dj = { markers: [] };
                        dj.map((item, index) => {
                          let p = item.properties;
                          let g = item.geometry;
                          let title = titleCase(p.nom_site).includes(
                            p.nature_sit
                          )
                            ? titleCase(p.nom_site)
                            : `${titleCase(p.nom_site)} (${p.nature_sit})`;
                          let e = {
                            title: title,
                            content: `Espaces dont l’altitude > 15m au dessus du niveau de la mer garantit une mise à l’abri temporaire des évacués. À rejoindre à pied de manière spontanée suite à la reconnaissance des signes annonciateurs d’un tsunami et/ou suite à une alerte officielle diffusée par les autorités.
Source: ${p.source.replace(/_/g, " ")}
${p.date}`,
                            type: "point",
                            geo: {
                              latitude: g.coordinates[1],
                              longitude: g.coordinates[0],
                            },
                            icon: "hiker",
                          };
                          new_dj.markers.push(e);
                          return null;
                        });
                        setResult(new_dj);
                        setShowResult(true);
                      }
                    }
                  }}
                >
                  Genarate
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {showResult ? (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title>JSON to Marker</Card.Title>
                  <h6
                    className="card-subtitle text-muted"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        JSON.stringify(result.markers)
                      );
                    }}
                  >
                    COPIER
                  </h6>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md="12">{JSON.stringify(result.markers)}</Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : null}
      </Container>
    </React.Fragment>
  );
};

export default ToolsConvert;
