import axios from "axios";

const config = {
  baseURL: "https://pwenter.io/v1.1/web", //pprocess.env.REACT_APP_API_URL,
  responseType: "json",
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
};

const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use((request) => {
  //console.log(request);
  return request;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data && error.response.data.message) ||
        "Something went wrong"
    )
);

export default axiosInstance;
