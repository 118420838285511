import React, { useEffect, useState } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import axios from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapPin } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
require("moment/locale/fr");

const Map = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const [markers, setMarkers] = useState([]);
  const [heatMap, setHeatMap] = useState([]);

  const [position, setPosition] = useState({ latitude: null, longitude: null });

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  const Marker = ({ text }) => (
    <Container>
      <Row>
        <Col>
          <FontAwesomeIcon
            icon={faMapPin}
            className="text-danger align-middle"
            size="2x"
          />
        </Col>
      </Row>
    </Container>
  );

  useEffect(() => {
    if (typeof user.customerId !== "undefined") {
      axios
        .get(`/reports/getLast/${user.customerId}`)
        .then((response) => {
          var d = [];
          response.data.data.map((item, index) => {
            var newy = (
              <Marker
                key={index}
                lat={item.latitude}
                lng={item.longitude}
                text={`Signalement du ${moment(item.generatedAt).format(
                  "llll"
                )}`}
              />
            );

            d = [...d, newy];
            return newy;
          });

          if (position.latitude !== null) {
            d.push(
              <Marker
                key={position}
                lat={position.longitude}
                lng={position.longitude}
                text="Ma position actuelle"
              />
            );
          }

          setMarkers(d);

          var dataHeatmap = [];
          response.data.data.map((item) => {
            var newy2 = { lat: item.latitude, lng: item.longitude, weight: 1 };

            dataHeatmap = [...dataHeatmap, newy2];
            return newy2;
          });

          setHeatMap({
            positions: dataHeatmap,
            options: {
              radius: 20,
              opacity: 1,
            },
          });
        })
        .catch((error) => console.log(error));
    }
  }, [user, position]);

  const getMapOptions = (maps) => {
    return {
      fullscreenControl: true,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      scrollwheel: false,
      streetViewControl: true,
    };
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title>{t("Map Overview")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Col style={{ height: 550, width: "100%" }}>
          {position.latitude !== null && (
            <GoogleMapReact
              options={getMapOptions}
              bootstrapURLKeys={{
                key: "AIzaSyCF7Np6MtgOFp8Yp8RqYyXAOwOw_YOLns4",
                language: "fr",
                region: "fr",
                libraries: ["visualization"],
              }}
              center={{
                lat: position.latitude ? position.latitude : 16.240204807607356,
                lng: position.longitude
                  ? position.longitude
                  : -61.53768946560625,
              }}
              heatmap={{ heatMap }}
              defaultZoom={12}
            >
              {markers}
            </GoogleMapReact>
          )}
        </Col>
      </Card.Body>
    </Card>
  );
};

export default Map;
