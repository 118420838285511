import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faPhone,
  faEnvelope,
  faLanguage,
  faClock,
  faGlobe,
  faAddressBook,
  faFingerprint,
  faToggleOff,
  faToggleOn,
  faIdBadge,
  faBuilding,
  faUserShield,
  faShieldAlt,
  faCheckCircle,
  faQuestionCircle,
  faStopCircle,
  faQrcode,
  faCamera,
  faHandsHelping,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
require("moment/locale/fr");

function Overview({ data, user }) {
  const { t } = useTranslation();
  const [imgError, setImgError] = useState(false);

  const handleImgError = () => {
    setImgError(true);
  };

  var stateList = [
    { title: "Enabled", value: true },
    { title: "Disabled", value: false },
  ];

  const getState = (state) => {
    return stateList.filter((l) => l.value === Boolean(state))[0].title;
  };

  var languageList = [{ title: "French", value: "fr_FR" }];

  const getLanguage = (lang) => {
    return lang ? languageList.filter((l) => l.value === lang)[0].title : "";
  };

  return (
    <Card className="flex-fill w-full">
      <Card.Header>
        <Card.Title>{t("Overview")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row className="mb-3">
          <Col lg={5}>
            {imgError ? (
              <p>L'image ne peut pas être chargée</p>
            ) : (
              <img
                src={`https://objects.pwenter.io/users/${data.userId}/images/pp.jpg`}
                alt={`${data.firstname} ${data.lastname}`}
                onError={handleImgError}
                className="w-100 mb-3"
              />
            )}
          </Col>
          <Col lg={7}>
            <Row className="mb-4">
              <Col className="fs-1">
                <Row>
                  <Col xs={2}>
                    <FontAwesomeIcon icon={faUser} fixedWidth size="lg" />
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        {data.firstname} {data.lastname}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-sm">
                        {moment(data.generatedAt).format("LLLL")}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="text-center text-sm">
                <Row>
                  <Col className="fs-5">
                    <a href={`tel: ${data.phone}`} className="fs-3">
                      <FontAwesomeIcon icon={faPhone} fixedWidth />
                    </a>{" "}
                    {data.phone}
                  </Col>
                </Row>
              </Col>
              <Col sm={7} className="text-center text-sm">
                <Row className="fs-5 mb-3">
                  <Col>
                    <a href={`mailto: ${data.email}`} className="fs-3">
                      <FontAwesomeIcon icon={faEnvelope} fixedWidth />
                    </a>{" "}
                    {data.email}
                  </Col>
                </Row>
              </Col>
            </Row>
            {data.address ? (
              <Row className="fs-5 mb-3">
                <Col>{data.address}</Col>
              </Row>
            ) : null}
            <Row className="fs-5 mb-4">
              {data.postalCode || data.city ? (
                <Col sm={8}>
                  <FontAwesomeIcon
                    icon={faAddressBook}
                    fixedWidth
                    className="fs-3"
                  />
                  {data.postalCode} {data.city}
                </Col>
              ) : null}
              <Col sm={4}>
                <FontAwesomeIcon icon={faGlobe} fixedWidth className="fs-3" />{" "}
                {data.country}
              </Col>
            </Row>
            <Row>
              <Col className="h3 mb-3">{t("Account settings")}</Col>
            </Row>
            <Row className="fs-5 mb-4">
              <Col sm={4}>
                <FontAwesomeIcon icon={faIdBadge} fixedWidth className="fs-2" />{" "}
                {t(data.Level.title)}
              </Col>
              <Col sm={4}>
                <FontAwesomeIcon
                  icon={faBuilding}
                  fixedWidth
                  className="fs-3"
                />{" "}
                {data.Customer ? data.Customer.name : t("No affected")}
              </Col>
              <Col sm={4}>
                <FontAwesomeIcon icon={faClock} fixedWidth className="fs-3" />{" "}
                {data.utc}
              </Col>
            </Row>
            <Row className="fs-5 mb-4">
              <Col sm={4}>
                <FontAwesomeIcon
                  icon={faLanguage}
                  fixedWidth
                  className="fs-2"
                />{" "}
                {t(getLanguage(data.language))}
              </Col>
              <Col sm={4}>
                <FontAwesomeIcon
                  icon={faFingerprint}
                  fixedWidth
                  className="fs-3"
                />{" "}
                {data.type ? data.type : "AlerteCata"}
              </Col>
              <Col sm={4}>
                <FontAwesomeIcon
                  icon={data.active ? faToggleOn : faToggleOff}
                  fixedWidth
                  className="fs-3"
                />{" "}
                {t(getState(data.active))}
              </Col>
            </Row>
            {user.levelId >= 14 ? (
              <>
                <Row>
                  <Col className="h4 mb-3">{t("Private settings")}</Col>
                </Row>
                <Row className="fs-5 mb-4">
                  <Col md={6}>
                    <FontAwesomeIcon
                      icon={faShieldAlt}
                      fixedWidth
                      className="fs-2"
                    />{" "}
                    {t("Reset Code")}
                    {":"}{" "}
                    {data.resetCode ? data.resetCode.split("_")[0] : t("None")}
                  </Col>
                  <Col md={6}>
                    <FontAwesomeIcon
                      icon={faUserShield}
                      fixedWidth
                      className="fs-3"
                    />{" "}
                    {t("Verify Code")}
                    {":"} {data.verifyCode ? data.verifyCode : t("None")}
                  </Col>
                </Row>
              </>
            ) : null}

            <Row>
              <Col className="h3 mb-3">{t("Skills")}</Col>
            </Row>
            {data.Skills.length > 0
              ? data.Skills.map((skill, index) => {
                  console.log(skill);
                  return (
                    <Row
                      className={`fs-5 p-1 ${
                        index % 2 ? `bg-transparent` : `bg-light`
                      }`}
                      key={index}
                    >
                      <Col>
                        <Row>
                          <Col md={5}>
                            <FontAwesomeIcon
                              icon={
                                skill.UsersSkills.controlStatus === 0
                                  ? faQuestionCircle
                                  : skill.UsersSkills.controlStatus === 1
                                  ? faCheckCircle
                                  : faStopCircle
                              }
                              fixedWidth
                              className="fs-4"
                              color={
                                skill.UsersSkills.controlStatus === 0
                                  ? "#ff8f00"
                                  : skill.UsersSkills.controlStatus === 1
                                  ? "#1ea018"
                                  : "#F00"
                              }
                            />{" "}
                            {skill.title}
                          </Col>

                          <Col md={3}>
                            {skill.control === "Scan" ? (
                              <a
                                href={`https://objects.pwenter.io/users/${data.userId}/skillProofs/skillId_${skill.skillId}.jpg`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <FontAwesomeIcon
                                  icon={faCamera}
                                  fixedWidth
                                  className="fs-5"
                                />
                              </a>
                            ) : skill.control === "QRCode" ? (
                              <FontAwesomeIcon
                                icon={faQrcode}
                                fixedWidth
                                className="fs-5"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faHandsHelping}
                                fixedWidth
                                className="fs-5"
                              />
                            )}{" "}
                            {t(skill.control)}
                          </Col>
                          {user.levelId >= 14 &&
                          skill.UsersSkills.authUserId ? (
                            <Col md={3}>
                              <FontAwesomeIcon
                                icon={faUser}
                                fixedWidth
                                className="fs-5"
                              />{" "}
                              {skill.UsersSkills.authUserId}
                            </Col>
                          ) : null}
                        </Row>
                      </Col>
                    </Row>
                  );
                })
              : t("None")}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default Overview;
