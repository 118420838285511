import React, { useState } from "react";
import { searchVideos } from "../../utils/youtubeService";
import { Card, Container, Row, Col, Button, Form } from "react-bootstrap";
import EditFormVideo from "./EditFormVideo";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
require("moment/locale/fr");

export default function VideoSearch() {
  const { t } = useTranslation();
  const [query, setQuery] = useState({ q: "", channelId: "" });
  const [videos, setVideos] = useState([]);

  const [setUpdate] = useState(true);
  const [videoPost, setVideoPost] = useState({
    hazardZoneVideoId: null,
    hazardZoneId: "",
    title: "",
    url: "",
    hazardId: "",
    createdAt: moment().format("YYYY-MM-DD"),
    active: true,
    advise: false,
    submit: false,
    eventTime: moment().format("LT"),
  });

  const [values, setValues] = useState({
    hazardZoneVideoId: null,
    hazardZoneId: "",
    title: "",
    url: "",
    hazardId: "",
    createdAt: moment().format("YYYY-MM-DD"),
    active: true,
    advise: false,
    submit: false,
    eventTime: moment().format("LT"),
  });

  const handleSearch = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const results = await searchVideos(query);
    setVideos(results);
  };

  const handle = ({ title, url, date, channelId }) => {
    console.log(channelId);
    console.log(videoPost);
    setValues({
      hazardZoneVideoId: null,
      title: title,
      url: url,
      createdAt: moment(date).format("YYYY-MM-DD"),
      active: true,
      advise: false,
      submit: false,
      eventTime: moment().format("LT"),
      hazardZoneId: videoPost.hazardZoneId,
      hazardId: videoPost.hazardId,
    });
  };

  const channelIds = [
    { name: "Tous", channelId: "" },
    { name: "TF1 INFO", channelId: "UCsrPUA0ZSDCNZC6wyRlR7ZA" },
    { name: "France 24", channelId: "UCCCPCZNChQdGa9EkATeye4g" },
    { name: "Le Parisien", channelId: "UCfHn_8-ehdem86fEvlFg-Gw" },
    { name: "Ouest-France", channelId: "UCrpsdF7UreiF-2r5Jp0YpRg" },
    { name: "France 3 Bretagne", channelId: "UCW3mewW-ZMdk3byz5F8gfHA" },
    {
      name: "France 3 Provence-Alpes-Côte d'Azur",
      channelId: "UCMPnD8fhwPMk_9XsYfc96JQ",
    },
    { name: "France 3 Grand Est ", channelId: "UC6kvMQP9gzv6qwwzMLuwZTg" },
    { name: "France 3 Occitanie", channelId: "UC20dglUTkVqzY9ipx_iOnng" },
    {
      name: "France 3 Nouvelle-Aquitaine",
      channelId: "UCwTvW0qRQXV-yvBghNVgqKg",
    },
    { name: "France 3 Hauts-de-France", channelId: "UCFC3nJ8GXl5yONe3tB_vhsw" },
    { name: "France 3 Normandie", channelId: "UCWiQCbdC05zfzNg83F2kc3A" },
    {
      name: "France 3 Bourgogne-Franche-Comté",
      channelId: "UCMPIMCKpq5TRd92Jwj2D7_Q",
    },
    {
      name: "France 3 Auvergne-Rhône-Alpes ",
      channelId: "UC_GxHrDkwwADSaAjmXxqtXA",
    },
    {
      name: "France 3 Centre-Val de Loire",
      channelId: "UCUwtTJir4sZL06om7-KRl1A",
    },
    {
      name: "National Geographic Wild France",
      channelId: "UCT60XBtfRQzf5NKFGDNbfCw",
    },
    {
      name: "C dans l'air - France Télévisions",
      channelId: "UCvg4_wSz4Cmo4xRPXaKU47A",
    },
    { name: "Jamy - Epicurieux", channelId: "UCG9G2dyRv04FDSH1FSYuLBg" },
    { name: "C'est pas sorcier", channelId: "UCENv8pH4LkzvuSV_qHIcslg" },
    { name: "TV5MONDE Info", channelId: "UCzKH70qfN_yuXq3s91fdwmg" },
    { name: "LeHuffPost ", channelId: "UC9GGzAhhvhJO1hL10-BcgNA" },
    {
      name: "Le Monde De Jamy - France Télévisions ",
      channelId: "UCrRKlEDktKeraMTgAN_DIzw",
    },
    { name: "Le Monde ", channelId: "UCYpRDnhk5H8h16jpS84uqsA" },
    { name: "Le Nouvel Obs", channelId: "UC1ObaaFz4XHVPN2T5IFsU4w" },
    { name: "Public Sénat", channelId: "UCTa1bPArjpvNMgSHAb_pflA" },
    { name: "France Info", channelId: "UCO6K_kkdP-lnSCiO3tPx7WA" },
    { name: "Brut", channelId: "UCSKdvgqdnj72_SLggp7BDTg" },
    { name: "EuroNews Fr", channelId: "UCW2QcKZiU8aUGg4yxCIditg" },
    { name: "Info ou Mytho ?", channelId: "UCLmlUMA_bGiMWWgfDwfNDgw" },
    { name: "Nat Geo France", channelId: "UC6dMVNhZmJYFf7SPv1WLMuQ" },
    { name: "Lumni", channelId: "UCR9raBqPQisip_qnuCaUL1w" },
  ];

  return (
    <Container fluid className="p-0">
      <EditFormVideo
        values={values}
        setUpdate={setUpdate}
        setVideoPost={setVideoPost}
        videoPost={videoPost}
      />
      <Row>
        <Col className="bg-light rounded m-2 p-4">
          <h2 className="pb-3">{t("Youtube search")}</h2>
          <Form className="row">
            <Form.Group className="mb-3 col-md-6">
              <Form.Label>{t("Title")}</Form.Label>
              <Form.Control
                type="text"
                value={query.q}
                onChange={(e) =>
                  setQuery({ q: e.target.value, channelId: query.channelId })
                }
                placeholder="Entrez un mot-clé"
              />
            </Form.Group>
            <Form.Group className="mb-3 col-md-4">
              <Form.Label>{t("Channel ID")}</Form.Label>
              <Form.Select
                name="channelId"
                onChange={(e) =>
                  setQuery({ q: query.q, channelId: e.target.value })
                }
                defaultValue=""
                aria-label="Default select example"
                className="md-3"
              >
                {channelIds.map((channelId, index) => (
                  <option key={index} value={channelId.channelId}>
                    {channelId.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <div className="col-md-2 pt-3 my-auto">
              <Button
                type="submit"
                className="mt-n1 ms-1"
                variant="primary"
                onClick={handleSearch}
              >
                <FontAwesomeIcon icon={faSearch} /> {t("Search")}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
      <Row className="my-5">
        {videos.map((video, index) => (
          <Col key={index} md="6" lg="3">
            <Card key={video.id.videoId}>
              <Card.Img
                src={video.snippet.thumbnails.medium.url}
                alt={video.snippet.title}
              />
              <Card.Header>
                <Card.Title>
                  <a
                    href={`https://www.youtube.com/watch?v=${video.id.videoId}`}
                    className="fs-4 text-secondary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {video.snippet.title
                      .replace("&#39;", "'")
                      .replace("&quot;", '"')}
                  </a>
                  <br />
                  <span className="fs-5 text-primary">
                    {video.snippet.channelTitle}
                  </span>
                </Card.Title>
              </Card.Header>
              <Card.Body className="px-4 pt-2">
                <span className="fs-5">
                  {moment(video.snippet.publishedAt).format("lll")}
                </span>
                <Button
                  className="float-end align-self-end"
                  onClick={() => {
                    handle({
                      title: video.snippet.title
                        .replace("&#39;", "'")
                        .replace("&quot;", '"'),
                      url: `https://www.youtube.com/watch?v=${video.id.videoId}`,
                      date: video.snippet.publishedAt,
                      channelId: video.snippet.channelId,
                    });
                  }}
                >
                  Ajouter
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
