import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { /*Badge,*/ Card, Dropdown, Table } from "react-bootstrap";
import { MoreHorizontal } from "react-feather";
import { useTable } from "react-table";
import useAuth from "../../../hooks/useAuth";
import moment from "moment";
import axios from "../../../utils/axios";
require("moment/locale/fr");

const Reports = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [lastReports, setLastReports] = useState([]);

  useEffect(() => {
    if (typeof user.customerId !== "undefined") {
      const getImageName = (generatedAt) => {
        var date = moment.utc(`${generatedAt}`);
        var image = moment(date.toISOString()).unix();
        return image;
      };

      axios
        .get(`/reports/getLast/${user.customerId}`)
        .then((response) => {
          var d = [];
          response.data.data.map((item, index) => {
            let image = getImageName(item.generatedAt);
            var newy = {
              imageCol: (
                <img
                  src={`https://objects.pwenter.io/uploads/${image}.jpg`}
                  width="60"
                  height="60"
                  className="img-thumbnail me-2"
                  alt="Reports"
                  key={index}
                />
              ),
              typeCol: item.title,
              cityCol: item.area,
              dateCol: moment(item.generatedAt).format("llll"),
              commentCol: item.comment !== "null" ? item.comment : `None`,
              latCol: item.latitude.toFixed(4),
              lngCol: item.longitude.toFixed(4),
              statusCol: item.reportStateTitle,
            };
            d = [...d, newy];
            return newy;
          });
          setLastReports(d);
        })
        .catch((error) => console.log(error));
    }
  }, [user]);

  const data = React.useMemo(() => lastReports, [lastReports]);

  const columns = React.useMemo(
    () => [
      {
        Header: t("Image"),
        accessor: "imageCol",
      },
      {
        Header: t("Type"),
        accessor: "typeCol", // accessor is the "key" in the data
      },
      {
        Header: t("City"),
        accessor: "cityCol",
      },
      {
        Header: t("Date"),
        accessor: "dateCol",
      },
      {
        Header: t("Comment"),
        accessor: "commentCol",
      },
      {
        Header: t("Latitude"),
        accessor: "latCol",
      },
      {
        Header: t("Longitude"),
        accessor: "lngCol",
      },
      {
        Header: t("Status"),
        accessor: "statusCol",
      },
    ],
    [t]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Action</Dropdown.Item>
              <Dropdown.Item>Another Action</Dropdown.Item>
              <Dropdown.Item>Something else here</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title className="mb-0">{t("Latest Reports")}</Card.Title>
      </Card.Header>
      <Table
        className="my-0"
        striped
        bordered
        hover
        size="sm"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <th {...column.getHeaderProps()} key={index}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Card>
  );
};

export default Reports;
