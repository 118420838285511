import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Card, Row } from "react-bootstrap";
import { Bell, Eye, Users } from "react-feather";
import illustration from "../../../assets/img/illustrations/customer-support.png";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../utils/axios";

const Statistics = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const [stats, setStats] = useState({
    reportsCount: 0,
    notificationsCount: 0,
    membersCount: 0,
  });

  useEffect(() => {
    if (typeof user.customerId !== "undefined") {
      axios
        .get(`/stats/getResume/${user.customerId}`)
        .then((response) => {
          let data = response.data.data[0];
          setStats({
            reportsCount: data.reportsCount,
            notificationsCount: data.notificationsCount,
            membersCount: data.membersCount,
          });
          return () => null;
        })
        .catch((error) => console.log(error));
    }
  }, [user]);

  return (
    <Row>
      <Col md="6" xl className="d-flex">
        <Card className="illustration flex-fill">
          <Card.Body className="p-0 d-flex flex-fill">
            <Row className="g-0 w-100">
              <Col xs="6">
                <div className="illustration-text p-3 m-1">
                  <h4 className="illustration-text">
                    {t("Welcome back")}, {user.firstname}!
                  </h4>
                  <p className="mb-0">{t("Dashboard")}</p>
                </div>
              </Col>
              <Col xs={6} className="align-self-end text-end">
                <img
                  src={illustration}
                  alt="Customer Support"
                  className="img-fluid illustration-img"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">{stats.reportsCount}</h3>
                <p className="mb-2">{t("reports")}</p>
                <div className="mb-0">
                  {/*<Badge bg="" className="badge-soft-success me-2">
                    +5.35%
                  </Badge>*/}
                  <span className="text-muted">
                    {t("submited from the city")}
                  </span>
                </div>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <Eye className="align-middle text-success" />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">{stats.notificationsCount}</h3>
                <p className="mb-2">alertes</p>
                <div className="mb-0">
                  {/*<Badge bg="" className="badge-soft-danger me-2">
                    -4.25%
                </Badge>*/}
                  <span className="text-muted">diffusées</span>
                </div>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <Bell className="align-middle text-success" />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">{stats.membersCount}</h3>
                <p className="mb-2">connectés</p>
                <div className="mb-0">
                  {/*<Badge bg="" className="badge-soft-success me-2">
                    +8.65%
                </Badge>*/}
                  <span className="text-muted">à votre compte</span>
                </div>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <Users className="align-middle text-success" />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Statistics;
