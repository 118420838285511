import React from "react";
import { withTranslation } from "react-i18next";

const SidebarFooter = ({ t }) => {
  return (
    <div className="sidebar-cta">
      <div className="sidebar-cta-content">
        <strong className="d-inline-block mb-2">{t("Mobile Interface")}</strong>
        <div className="mb-3 text-sm">
          {t("Access the users mobile interface.")}
        </div>

        <div className="d-grid">
          <a
            href="https://store.alertecata.fr"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer"
          >
            {t("Download")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SidebarFooter);
