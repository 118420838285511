import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "../../utils/axios";

const ToolZones = () => {
  const { t } = useTranslation();
  //const [multipolygon, setMultipolygon] = useState({});
  const [id, setId] = useState("");
  const [parentId, setParentId] = useState(0);
  const [level, setLevel] = useState(2);
  const [name, setName] = useState("");
  const [fullName, setFullName] = useState("");
  const [active, setActive] = useState(true);

  var stateList = [
    { title: "Enabled", value: true },
    { title: "Disabled", value: false },
  ];

  return (
    <React.Fragment>
      <Helmet title="Conversion" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{t(`Zones manager`)}</h1>

        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>JSON to Marker</Card.Title>
                <h6 className="card-subtitle text-muted">...</h6>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md="12">
                    <Form.Group className="mb-3">
                      <Form.Group className="mb-3 col-md-10">
                        <Form.Label>{t("Name")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder={t("Name")}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3 col-md-10">
                        <Form.Label>{t("Full Name")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="fullName"
                          placeholder={t("Full Name")}
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3 col-md-10">
                        <Form.Label>{t("ID")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="id"
                          placeholder={t("ID")}
                          value={id}
                          onChange={(e) => setId(e.target.value)}
                        />
                      </Form.Group>
                      {/*<Form.Label>JSON Format</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        onChange={(e) => {
                          setMultipolygon(e.target.value);
                        }}
                      />*/}
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-3">
                      <Form.Label>{t(`Level`)}</Form.Label>
                      <Form.Select
                        className="mb-3"
                        defaultValue="default"
                        onChange={(e) => setLevel(e.target.value)}
                      >
                        <option value="2">Pays</option>
                        <option value="6">Région</option>
                        <option value="7">Département</option>
                        <option value="7">Intercommunalité</option>
                        <option value="8">Ville</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-3">
                      <Form.Label>{t("Parent ID")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="parentId"
                        placeholder={t("Parent ID")}
                        value={parentId}
                        onChange={(e) => setParentId(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3 col-md-2">
                  <Form.Label>{t("State")}</Form.Label>
                  <Form.Control
                    as="select"
                    name="active"
                    onChange={(e) => setActive(e.target.value)}
                    value={active}
                    aria-label="Default select example"
                  >
                    <option>{t("Open this select menu")}</option>
                    {stateList.map((state, index) => {
                      return (
                        <option value={state.value} key={index}>
                          {t(state.title)}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <hr />
                <Button
                  onClick={() => {
                    if (id !== "" && level !== "") {
                      let params = {
                        name,
                        fullName,
                        id,
                        level,
                        active,
                        parentId,
                      };
                      console.log(params);
                      axios
                        .put(`/tools/zone/insert2`, params)
                        .then((response) => {
                          console.log(response);
                        })
                        .catch((error) => console.log(error));
                    }
                    /*if (multipolygon.length > 0 && level !== "") {
                      let mp = JSON.parse(multipolygon);

                      let polygon = { type: "MultiPolygon", coordinates: mp };

                      let params = {
                        name,
                        fullName,
                        multipolygon: polygon,
                        level,
                        active,
                        parentId: 9,
                      };
                      console.log(params);
                      axios
                        .put(`/tools/zone/insert`, params)
                        .then((response) => {
                          console.log(response);
                        })
                        .catch((error) => console.log(error));
                    }*/
                  }}
                >
                  {t(`OK`)}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ToolZones;
