import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "../../utils/axios";
import SelectLevelId from "./SelectLevelId";

function EditForm({ values, user, setShow }) {
  const { t } = useTranslation();

  var stateList = [
    { title: "Enabled", value: true },
    { title: "Disabled", value: false },
  ];

  var languageList = [{ title: "French", value: "fr_FR" }];

  const init = () => {
    if (Object.keys(values).length === 0) {
      return {
        userId: null,
        levelId: "",
        language: "fr_FR",
        active: 1,
        submit: false,
      };
    } else {
      return {
        userId: values.userId,
        levelId: values.levelId,
        language: values.language,
        active: values.active,
        submit: false,
      };
    }
  };

  return (
    <Formik
      initialValues={init()}
      validationSchema={Yup.object().shape({})}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const params = {
            userId: values.userId,
            levelId: values.levelId,
            language: values.language,
            active: values.active,
            customerId: user.customerId,
          };

          axios
            .put(`/reports/update`, params)
            .then((response) => {
              console.log(response);
            })
            .catch((error) => console.log(error));
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit} className="row">
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              {errors.submit}
            </Alert>
          )}

          <Form.Group className="mb-3 col-md-3">
            <Form.Label>{t("Roles")}</Form.Label>
            <SelectLevelId
              values={values}
              user={user}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-md-2">
            <Form.Label>{t("Languages")}</Form.Label>
            <Form.Control
              as="select"
              name="active"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.language}
              aria-label="Default select example"
              disabled={values.sended ? true : false}
            >
              <option>{t("Open this select menu")}</option>
              {languageList.map((lang, index) => {
                return (
                  <option value={lang.value} key={index}>
                    {t(lang.title)}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3 col-md-2">
            <Form.Label>{t("State")}</Form.Label>
            <Form.Control
              as="select"
              name="active"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.active}
              aria-label="Default select example"
              disabled={values.sended ? true : false}
            >
              <option>{t("Open this select menu")}</option>
              {stateList.map((state, index) => {
                return (
                  <option value={state.value} key={index}>
                    {t(state.title)}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mt-2 col-md-2">
            <div className="text-center mt-3">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isSubmitting}
              >
                {t("Edit")}
              </Button>
            </div>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
}

export default EditForm;
