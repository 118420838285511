import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
import { withTranslation } from "react-i18next";

function SignIn({ t }) {
  const navigate = useNavigate();
  const { signIn } = useAuth();

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        rememberMe: true,
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("Must be a valid email"))
          .max(255)
          .required(t("Email is required")),
        password: Yup.string().max(255).required(t("Password is required")),
        rememberMe: Yup.boolean(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.email, values.password, values.rememberMe);

          navigate("/");
        } catch (error) {
          const message = error.message || t("Something went wrong");

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {/*<Alert className="my-3" variant="primary">
            <div className="alert-message">
              Use <strong>demo@bootlab.io</strong> and{" "}
              <strong>unsafepassword</strong> to sign in
            </div>
      </Alert>*/}
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}

          <Form.Group className="mb-3">
            <Form.Label>{t("Email")}</Form.Label>
            <Form.Control
              size="lg"
              type="email"
              name="email"
              placeholder={t("Enter your email")}
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("Password")}</Form.Label>
            <Form.Control
              size="lg"
              type="password"
              name="password"
              placeholder={t("Enter your password")}
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
            <small>
              <Link to="/auth/reset-password">{t("Forgot password?")}</Link>
            </small>
          </Form.Group>

          <div>
            <Form.Check
              type="checkbox"
              id="rememberMe"
              name="rememberMe"
              label={t("Remember me next time")}
              defaultChecked
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>

          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {t("Sign in")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default withTranslation()(SignIn);
