import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

const SelectHazardId = ({
  values,
  hazardIds2Select,
  handleBlur,
  handleChange,
}) => {
  const { t } = useTranslation();

  const [select, setSelect] = useState(<></>);

  useEffect(() => {
    if (hazardIds2Select.length > 0) {
      setSelect(
        <div key={`inline-checkbox`} className="mb-3">
          {hazardIds2Select.map((hazard, index) => {
            return (
              <Form.Check
                inline
                type="checkbox"
                onBlur={handleBlur}
                onChange={handleChange}
                aria-label="Default select example"
                name="hazardIds"
                value={hazard.hazardId}
                key={index}
                label={t(hazard.title)}
                defaultChecked={
                  typeof values.hazardIds !== "undefined"
                    ? values.hazardIds.includes(hazard.hazardId.toString())
                    : false
                }
              />
            );
          })}
        </div>
      );
    }
  }, [hazardIds2Select, handleBlur, handleChange, t, values]);

  return select;
};

export default SelectHazardId;
