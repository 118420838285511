import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Form,
  Row,
  Col,
  Container,
  Button,
  Alert,
  Image,
} from "react-bootstrap";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import axios from "../../utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faBell,
  faInfo,
  faBan,
  faTrashAlt,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { Formik } from "formik";
import moment from "moment";
require("moment/locale/fr");

function Timeline({ values, user }) {
  const { t } = useTranslation();

  const [itemMap, setItemMap] = useState({
    postTimelineId: "",
    title: "",
    content: "",
    lineColor: "",
    postTimelineTypeId: "",
    active: "",
    submit: false,
    action: "insert",
    postId: values.postId,
    eventDate: "",
    eventTime: "",
  });
  const [dataResponse, setDataResponse] = useState([]);
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    if (typeof values.postId !== "undefined" && update) {
      axios
        .get(`/news/getPostsTimelines/${values.postId}`)
        .then((response) => {
          setDataResponse(response.data.data);
        })
        .catch((error) => console.log(error));
      setUpdate(false);
    }
  }, [values, update, t]);

  const handleActive = (active, postTimelineId) => {
    axios
      .put(`/news/update/postTimeline`, { active: !active, postTimelineId })
      .then((response) => {
        init();
        setUpdate(true);
      })
      .catch((error) => console.log(error));
  };

  const handleEdit = (item) => {
    setItemMap({
      postTimelineId: item.postTimelineId,
      title: item.title,
      content: item.content,
      lineColor: item.lineColor,
      postTimelineTypeId: item.postTimelineTypeId,
      active: item.active,
      submit: false,
      action: "update",
      postId: item.postId,
      eventDate: moment(item.eventDate).format("YYYY-MM-DD"),
      eventTime: moment(item.eventDate).format("LT"),
    });
  };

  const handleRemove = (postTimelineId) => {
    axios
      .delete(`/news/trash/postTimeline/${postTimelineId}`)
      .then((response) => {
        setUpdate(true);
      })
      .catch((error) => console.log(error));
  };

  const init = () => {
    setItemMap({
      title: "",
      content: "",
      lineColor: "",
      postTimelineTypeId: "",
      active: "",
      submit: false,
      action: "insert",
      postId: values.postId,
      postTimelineId: "",
      eventDate: "",
      eventTime: "",
    });
  };

  var postTimelineTypesList = [
    { value: 1, title: "Actualité" },
    { value: 2, title: "Alerte" },
  ];

  var stateList = [
    { title: "Enabled", value: 1 },
    { title: "Disabled", value: 0 },
  ];

  var colorList = [
    { title: "Green", value: "#007914" },
    { title: "Yellow", value: "#FFCD00" },
    { title: "Orange", value: "#FF6C00" },
    { title: "Red", value: "#FF0000" },
    { title: "Purple", value: "#A600FF" },
    { title: "Gray", value: "#AEAEAE" },
    { title: "Blue", value: "#0093FF" },
    { title: "Scarlet", value: "#ED0000" },
    { title: "Black", value: "#000000" },
  ];

  return (
    <Container>
      <Row>
        <Col>
          <h3>{t("Form")}</h3>

          <Button
            className="mt-1 mb-3"
            type="submit"
            variant="primary"
            size="sm"
            disabled={itemMap.postTimelineId ? false : true}
            onClick={() => init()}
          >
            {t("New")}
          </Button>
          <Formik
            enableReinitialize={true}
            initialValues={itemMap}
            validationSchema={Yup.object().shape({
              title: Yup.string()
                .min(12, t("less_char", { n: 12 }))
                .max(400)
                .required("Title is required"),
              eventDate: Yup.date().required("Date is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                const params = {
                  postTimelineId: values.postTimelineId,
                  title: values.title,
                  content: values.content,
                  postTimelineTypeId: values.postTimelineTypeId,
                  lineColor: values.lineColor,
                  postId: values.postId,
                  active: values.active,
                  eventDate: moment(
                    `${values.eventDate} ${
                      values.eventTime ? values.eventTime : "00:00"
                    }:00 ${user.utc}`
                  ).format("YYYY-MM-DD HH:mm:ss Z"),
                };

                if (itemMap.action === "insert") {
                  delete params.postTimelineId;
                  axios
                    .post(`/news/insert/postTimeline`, params)
                    .then((response) => {
                      let data = response.data.data;
                      setItemMap({
                        postTimelineId: data.postTimelineId,
                        title: data.title,
                        content: data.content,
                        lineColor: data.lineColor,
                        postTimelineTypeId: data.postTimelineTypeId,
                        active: data.active,
                        submit: false,
                        action: "update",
                        postId: data.postId,
                        eventDate: moment(data.eventDate).format("YYYY-MM-DD"),
                        eventTime: moment(data.eventDate).format("LT"),
                      });
                      setUpdate(true);
                    })
                    .catch((error) => console.log(error));
                } else {
                  axios
                    .put(`/news/update/postTimeline`, params)
                    .then((response) => {
                      setUpdate(true);
                    })
                    .catch((error) => console.log(error));
                }
              } catch (error) {
                const message = error.message || "Something went wrong";

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <Form onSubmit={handleSubmit} className="row">
                {errors.submit && (
                  <Alert className="my-3" variant="danger">
                    {errors.submit}
                  </Alert>
                )}
                <Form.Group className="mb-3 col-lg-6 col-md-12">
                  <Form.Label>{t("Title")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder={t("Title")}
                    value={values.title}
                    disabled={values.sended ? true : false}
                    isInvalid={Boolean(touched.title && errors.title)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.title && (
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-3 col-lg-2 col-md-5">
                  <Form.Label>{t("Category")}</Form.Label>
                  <Form.Control
                    as="select"
                    type="select"
                    name="postTimelineTypeId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.postTimelineTypeId}
                    aria-label="Default select example"
                    disabled={values.sended ? true : false}
                  >
                    <option>{t("Open this select menu")}</option>
                    {postTimelineTypesList.map((item, index) => {
                      return (
                        <option value={item.value} key={index}>
                          {item.title}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mb-3 col-lg-2 col-md-4">
                  <Form.Label>{t("Color")}</Form.Label>
                  <Form.Control
                    as="select"
                    name="lineColor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lineColor}
                    aria-label="Default select example"
                    disabled={values.sended ? true : false}
                  >
                    <option>{t("Open this select menu")}</option>
                    {colorList.map((color, index) => {
                      return (
                        <option value={color.value} key={index}>
                          {t(color.title)}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mb-3 col-lg-2 col-md-3">
                  <Form.Label>{t("State")}</Form.Label>
                  <Form.Control
                    as="select"
                    name="active"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.active}
                    aria-label="Default select example"
                    disabled={values.sended ? true : false}
                  >
                    <option>{t("Open this select menu")}</option>
                    {stateList.map((state, index) => {
                      return (
                        <option value={state.value} key={index}>
                          {t(state.title)}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3 col-lg-7 col-md-12">
                  <Form.Label>{t("Content")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="content"
                    placeholder={t("Content")}
                    value={values.content}
                    disabled={values.sended ? true : false}
                    isInvalid={Boolean(touched.content && errors.content)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.content && (
                    <Form.Control.Feedback type="invalid">
                      {errors.content}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 col-lg-3 col-md-5">
                  <Form.Label>{t("Date")}</Form.Label>
                  <Form.Control
                    type="date"
                    name="eventDate"
                    value={
                      values.eventDate != null
                        ? moment(values.eventDate).format("YYYY-MM-DD")
                        : ""
                    }
                    disabled={values.sended ? true : false}
                    isInvalid={Boolean(touched.eventDate && errors.eventDate)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.eventDate && (
                    <Form.Control.Feedback type="invalid">
                      {errors.eventDate}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-3 col-lg-2 col-md-3">
                  <Form.Label>{t("Time")}</Form.Label>
                  <Form.Control
                    type="time"
                    name="eventTime"
                    value={values.eventTime != null ? values.eventTime : ""}
                    disabled={values.sended ? true : false}
                    isInvalid={Boolean(touched.eventTime && errors.eventTime)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.eventTime && (
                    <Form.Control.Feedback type="invalid">
                      {errors.eventTime}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="col-lg-1 col-md-6 d-flex align-items-center">
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    disabled={isSubmitting}
                  >
                    {itemMap.action === "insert" ? t("Add") : t("Edit")}
                  </Button>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <Row className="pt-4">
        <Col>
          <h3>{t("Timeline")}</h3>
          {Object.keys(dataResponse).length > 0 ? (
            <VerticalTimeline lineColor={"#AAA"}>
              {dataResponse.map((item, index) => {
                return (
                  <VerticalTimelineElement
                    key={index}
                    className=""
                    contentArrowStyle={{
                      borderRight: "7px solid #DDD",
                    }}
                    iconStyle={{
                      background: item.lineColor ? item.lineColor : "#DDD",
                      color: "#fff",
                      alignContent: "center",
                      alignSelf: "center",
                      justifyItems: "center",
                      display: "flex",
                    }}
                    date={moment(item.eventDate).format("LL")}
                    icon={
                      <FontAwesomeIcon
                        icon={item.postTimelineTypeId === 1 ? faInfo : faBell}
                        fixedWidth
                      />
                    }
                  >
                    <h4 className="">{item.title}</h4>
                    <p>{item.content}</p>
                    <Image src={item.url} fluid thumbnail />
                    <p className="small">
                      {moment(item.eventDate).format("LLLL")}
                    </p>
                    <Row>
                      <Col className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          onClick={() =>
                            handleActive(item.active, item.postTimelineId)
                          }
                          variant={
                            item.active
                              ? "outline-success"
                              : "outline-secondary"
                          }
                          className="border-0 rounded"
                        >
                          <FontAwesomeIcon
                            icon={item.active ? faCheckCircle : faBan}
                            fixedWidth
                          />
                        </Button>
                        <Button
                          onClick={() => handleEdit(item)}
                          variant="outline-primary"
                          className="border-0 rounded"
                        >
                          <FontAwesomeIcon icon={faEdit} fixedWidth />
                        </Button>
                        <Button
                          onClick={() => handleRemove(item.postTimelineId)}
                          variant="outline-danger"
                          className="border-0 rounded"
                        >
                          <FontAwesomeIcon icon={faTrashAlt} fixedWidth />
                        </Button>
                      </Col>
                    </Row>
                  </VerticalTimelineElement>
                );
              })}
            </VerticalTimeline>
          ) : (
            <p>{t("No item in timeline")}</p>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Timeline;
