import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import axios from "../../utils/axios";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  InputGroup,
  Form,
  ListGroup,
  Row,
  Alert,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";
require("moment/locale/fr");

const ChatMessage = ({
  position,
  avatar,
  name,
  children,
  time,
  date,
  customer,
}) => (
  <div className={`chat-message-${position} pb-4`}>
    <div>
      <img
        src={avatar}
        className="rounded-circle me-1"
        alt={name}
        width="40"
        height="40"
      />
      <div className="text-muted small text-nowrap mt-2 text-center">
        {time}
      </div>
    </div>
    <div
      className={`flex-shrink-1 bg-light rounded py-2 px-3 ${
        position === "right" ? "me-3" : "ms-3"
      }`}
    >
      <div className="fw-bold mb-1">{name}</div>
      <div className="my-2">{children}</div>
      <div className="fw-semibold mb-1">
        <img
          src={`https://objects.pwenter.io/logos/customers/${customer.customerId}.png`}
          className="rounded-circle me-1"
          alt={customer.name}
          width="20"
          height="20"
        />{" "}
        {customer.shortName} - {date}
      </div>
    </div>
  </div>
);

const Chat = ({ reportId, customerId }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [partnersList, setPartnersList] = useState([]);
  const [logs, setLogs] = useState([]);
  const [update, setUpdate] = useState(null);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    axios
      .get(`/reports/getReportPartners/${reportId}`)
      .then((response) => {
        setPartnersList(response.data.data.ReportsPartners);
      })
      .catch((error) => console.log(error));
  }, [setPartnersList, reportId]);

  useEffect(() => {
    scrollToBottom();
  }, [logs]);

  useEffect(() => {
    axios
      .get(`/reports/getReportLogs/${reportId}`)
      .then((response) => {
        setLogs(response.data.data);
      })
      .catch((error) => console.log(error));
  }, [setLogs, reportId, update]);

  return (
    <React.Fragment>
      <Helmet title={t(`Log`)} />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{t(`Messages`)}</h1>

        <Card>
          <Row className="g-0">
            <Col lg={5} xl={3} className="border-end">
              <div className="px-4 d-none d-md-block">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 ms-3">
                    <Form.Control
                      type="text"
                      className="my-3"
                      placeholder={t(`Search`) + `...`}
                    />
                  </div>
                </div>
              </div>
              {partnersList !== null && partnersList.length > 0
                ? partnersList.map((partner, index) => {
                    let customer = partner.PartnersCustomer.Customer;
                    return (
                      <ListGroup.Item
                        action
                        tag="a"
                        href="#"
                        className="border-0"
                        key={index}
                      >
                        <Badge bg="success" className="float-end">
                          5
                        </Badge>
                        <div className="d-flex">
                          <img
                            src={`https://objects.pwenter.io/logos/customers/${customer.customerId}.png`}
                            className="rounded-circle me-1"
                            alt={partner.name}
                            width="40"
                            height="40"
                          />
                          <div className="flex-grow-1 ms-3">
                            {customer.shortName}
                            <div className="small">
                              <FontAwesomeIcon
                                icon={faCircle}
                                className="chat-online"
                              />{" "}
                              {t(`Online`)}
                            </div>
                          </div>
                        </div>
                      </ListGroup.Item>
                    );
                  })
                : null}

              <hr className="d-block d-lg-none mt-1 mb-0" />
            </Col>
            <Col lg={7} xl={9}>
              <div className="py-2 px-4 border-bottom d-none d-lg-block">
                <div className="d-flex align-items-center py-1">
                  <div className="position-relative">
                    <img
                      src={`https://objects.pwenter.io/users/${user.userId}/images/pp.jpg`}
                      className="rounded-circle me-1"
                      alt={user.displayName}
                      width="40"
                      height="40"
                    />
                  </div>
                  <div className="flex-grow-1 ms-3 ps-3">
                    <strong>{user.displayName}</strong>
                    <div className="text-muted small">
                      <img
                        src={`https://objects.pwenter.io/logos/customers/${user.customerId}.png`}
                        className="rounded-circle me-1"
                        alt={user.Customer.name}
                        width="15"
                        height="15"
                      />{" "}
                      {user.Customer.shortName}
                      {/*<em>{t(`Typing`) + `...`}</em>*/}
                    </div>
                  </div>
                </div>
              </div>

              <div className="position-relative">
                <div className="chat-messages p-4">
                  {logs !== null && logs.length > 0 ? (
                    logs.map((log, index) => {
                      return (
                        <ChatMessage
                          position={
                            user.userId === log.User.userId ? `right` : `left`
                          }
                          name={
                            user.userId === log.User.userId
                              ? t(`You`)
                              : `${log.User.firstname} - ${log.User.lastname}`
                          }
                          avatar={`https://objects.pwenter.io/users/${log.userId}/images/pp.jpg`}
                          time={moment(
                            log.createdAt.replace(".000Z", `.000${user.utc}`)
                          ).format("LT")}
                          date={moment(
                            log.createdAt.replace(".000Z", `.000${user.utc}`)
                          ).format("LL")}
                          customer={log.Customer}
                          key={index}
                        >
                          {log.content}
                        </ChatMessage>
                      );
                    })
                  ) : (
                    <Col>{t(`No message`)}</Col>
                  )}

                  <div ref={messagesEndRef} />
                </div>
              </div>

              <div className="flex-grow-0 py-3 px-4 border-top">
                <Formik
                  enableReinitialize={true}
                  initialValues={{ content: "" }}
                  validationSchema={Yup.object().shape({
                    content: Yup.string()
                      .min(3, t("less_char", { n: 3 }))
                      .max(500)
                      .required("Content is required"),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting, resetForm }
                  ) => {
                    try {
                      const params = {
                        content: values.content,
                        customerId: user.customerId,
                        reportId: reportId,
                        userId: user.userId,
                        public: 0,
                      };

                      axios
                        .put(`/reports/logs/insert`, params)
                        .then((response) => {
                          setErrors({
                            submit: response.data.message,
                            status: "success",
                          });

                          resetForm({
                            values: {
                              ...values,
                              content: "",
                            },
                          });
                          setUpdate(response);
                        })
                        .catch((error) => console.log(error));
                    } catch (error) {
                      const message = error.message || "Something went wrong";

                      setStatus({ success: false });
                      setErrors({ submit: message });
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <Form onSubmit={handleSubmit} className="row">
                      {errors.submit && (
                        <Alert className="my-3 p-1" variant="danger">
                          {errors.submit}
                        </Alert>
                      )}

                      <InputGroup>
                        <Form.Control
                          name="content"
                          type="text"
                          placeholder={t(`Type your message`)}
                          value={values.content}
                          disabled={isSubmitting}
                          isInvalid={Boolean(touched.label && errors.label)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <Button
                          variant="primary"
                          disabled={isSubmitting}
                          type="submit"
                        >
                          {t(`Send`)}
                        </Button>
                      </InputGroup>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Chat;
