import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import {
  /*Badge,Dropdown,*/
  Card,
  Row,
  Col,
} from "react-bootstrap";
import axios from "../../utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn, faToggleOff } from "@fortawesome/free-solid-svg-icons";

const Share = ({ data }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [partners, setPartners] = useState([]);
  const [partnerStates, setPartnersStates] = useState([]);

  useEffect(() => {
    axios
      .get(`/reports/partners/${user.customerId}`)
      .then((response) => {
        setPartners(response.data.data);
        axios
          .get(`/reports/partners/${user.customerId}/${data.reportId}`)
          .then((response) => {
            const partnersCustomersIds = response.data.data.map(
              (item) => item.partnercustomerId
            );
            setPartnersStates(partnersCustomersIds);
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  }, [user, data]);

  const sharing = (partnercustomerId, state) => {
    if (state) {
      axios
        .put(`reports/partners`, {
          reportId: data.reportId,
          partnercustomerId,
        })
        .then(() => {
          var addPartner = partnerStates;
          addPartner.push(partnercustomerId);
          addPartner = addPartner.filter((e) => e !== null);
          setPartnersStates(addPartner);
        })
        .catch((error) => console.log(error));
    } else {
      axios
        .delete(`/reports/partners/${data.reportId}/${partnercustomerId}`)
        .then(() => {
          const deletePartner = partnerStates.filter(
            (e) => e !== partnercustomerId
          );
          setPartnersStates(deletePartner);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title>{t("Share to partners")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          {partners.map((partner, index) => {
            return (
              <Col xl={2} lg={3} md={4} sm={4} xs={12} key={index}>
                <Row className="text-center">
                  <Col xs={12}>
                    <img
                      src={`https://objects.pwenter.io/logos/customers/${partner.customerId}.png`}
                      alt={partner.name}
                      width={120}
                      className=" w-100%"
                    />
                  </Col>
                  <Col xs={12} className="fw-bold">
                    {partner.shortName}
                  </Col>
                  <Col xs={12} className="fw-bold">
                    <FontAwesomeIcon
                      icon={
                        partnerStates.length > 0 &&
                        partnerStates.includes(
                          partner.partners[0].data.partnercustomerId
                        )
                          ? faToggleOn
                          : faToggleOff
                      }
                      size="2x"
                      color={
                        partnerStates.length > 0 &&
                        partnerStates.includes(
                          partner.partners[0].data.partnercustomerId
                        )
                          ? `#006400`
                          : `#FF0000`
                      }
                      onClick={() =>
                        sharing(
                          partner.partners[0].data.partnercustomerId,
                          partnerStates.includes(
                            partner.partners[0].data.partnercustomerId
                          )
                            ? false
                            : true
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Share;
