import axiosInstance from "./axios";

export const searchVideos = async (query) => {
  try {
    const response = await axiosInstance.get(
      `tools/youtube/${encodeURIComponent(query.q)}/50/${query.channelId}`
    );
    return response.data.items;
  } catch (error) {
    console.error("Erreur lors de la récupération des vidéos :", error);
    return [];
  }
};
