import React, { useRef } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "../../utils/axios";
import { Editor } from "@tinymce/tinymce-react";
import moment from "moment";
require("moment/locale/fr");

function EditForm({ values, user, setShow }) {
  const { t } = useTranslation();
  const editorRef = useRef(null);
  const titleRef = useRef(null);

  var stateList = [
    { title: "Enabled", value: true },
    { title: "Disabled", value: false },
  ];

  const init = () => {
    if (Object.keys(values).length === 0) {
      return {
        channelId: null,
        title: "",
        dateStart: "",
        timeStart: "",
        dateEnd: "",
        timeEnd: "",
        resume: "",
        active: 1,
        submit: false,
      };
    } else {
      return {
        channelId: values.channelId,
        title: values.title,
        resume: values.resume,
        dateStart:
          values.dateStart === null
            ? ""
            : moment(values.dateStart, "YYYY-MM-DD HH:mm:ss Z").format(
                "YYYY-MM-DD"
              ),
        timeStart:
          values.dateStart === null
            ? ""
            : moment(values.dateStart, "YYYY-MM-DD HH:mm:ss Z").format("LT"),
        dateEnd:
          values.dateEnd === null
            ? ""
            : moment(values.dateEnd, "YYYY-MM-DD HH:mm:ss Z").format(
                "YYYY-MM-DD"
              ),
        timeEnd:
          values.dateEnd === null ? "" : moment(values.dateEnd).format("LT"),
        active: Boolean(values.active),
        submit: false,
      };
    }
  };

  return (
    <Formik
      initialValues={init()}
      validationSchema={Yup.object().shape({
        title: Yup.string()
          .min(12, t("less_char", { n: 12 }))
          .max(120)
          .required("Title is required"),
        dateStart: Yup.date().required("Message is required"),
        dateEnd: Yup.date().nullable(true),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const params = {
            channelId: values.channelId,
            title: values.title,
            resume: editorRef.current.getContent(),
            dateStart: `${values.dateStart} ${
              values.timeStart !== "" ? values.timeStart : "00:00"
            } ${user.utc}`,
            dateEnd:
              values.dateEnd !== ""
                ? `${values.dateEnd} ${
                    values.timeEnd !== "" ? values.timeEnd : "00:00"
                  } ${user.utc}`
                : null,
            active: values.active,
            customerId: user.customerId,
          };

          if (params.dateEnd === "") {
            delete params.dateEnd;
          }

          if (params.channelId === null) {
            delete params.channelId;
            axios
              .put(`/channels/insert`, params)
              .then((response) => {
                console.log(response);
                setShow(false);
              })
              .catch((error) => console.log(error));
          } else {
            axios
              .put(`/channels/update`, params)
              .then(() => {
                setStatus({ success: true });
              })
              .catch((error) => setErrors({ submit: error }));
          }
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit} className="row">
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              {errors.submit}
            </Alert>
          )}
          <Form.Group className="mb-3 col-md-10">
            <Form.Label>{t("Title")}</Form.Label>
            <Form.Control
              ref={titleRef}
              type="text"
              name="title"
              placeholder={t("Title")}
              value={values.title}
              isInvalid={Boolean(touched.title && errors.title)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.title && (
              <Form.Control.Feedback type="invalid">
                {errors.title}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3 col-md-2">
            <Form.Label>{t("State")}</Form.Label>
            <Form.Control
              as="select"
              name="active"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.active}
              aria-label="Default select example"
            >
              <option>{t("Open this select menu")}</option>
              {stateList.map((state, index) => {
                return (
                  <option value={state.value} key={index}>
                    {t(state.title)}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3 col-md-2">
            <Form.Label>{t("Start Date")}</Form.Label>
            <Form.Control
              type="date"
              name="dateStart"
              value={values.dateStart}
              isInvalid={Boolean(touched.dateStart && errors.dateStart)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.dateStart && (
              <Form.Control.Feedback type="invalid">
                {errors.dateStart}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3 col-md-2">
            <Form.Label>{t("Start Time")}</Form.Label>
            <Form.Control
              type="time"
              name="timeStart"
              value={values.timeStart}
              isInvalid={Boolean(touched.timeStart && errors.timeStart)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.timeStart && (
              <Form.Control.Feedback type="invalid">
                {errors.timeStart}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3 col-md-2">
            <Form.Label>{t("End Date")}</Form.Label>
            <Form.Control
              type="date"
              name="dateEnd"
              value={values.dateEnd}
              isInvalid={Boolean(touched.dateEnd && errors.dateEnd)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.dateEnd && (
              <Form.Control.Feedback type="invalid">
                {errors.dateEnd}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3 col-md-2">
            <Form.Label>{t("End Time")}</Form.Label>
            <Form.Control
              type="time"
              name="timeEnd"
              value={values.timeEnd}
              isInvalid={Boolean(touched.timeEnd && errors.timeEnd)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.timeEnd && (
              <Form.Control.Feedback type="invalid">
                {errors.timeEnd}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("Content")}</Form.Label>
            <Editor
              isInvalid={Boolean(touched.resume && errors.resume)}
              name="resume"
              onBlur={handleBlur}
              onChange={handleChange}
              apiKey="lucyqqrkzy6x5er3899bg091va8ugje0csmz13rt6qyofdxc"
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={values.resume}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />

            {/*!!touched.content && (
              <Form.Control.Feedback type="invalid">
                {errors.content}
              </Form.Control.Feedback>
            )*/}
          </Form.Group>

          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {t("Save")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default EditForm;
