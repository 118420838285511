import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  /*Badge,Dropdown,*/
  Card,
  Table,
  Modal,
  Button,
  Tab,
  Tabs,
} from "react-bootstrap";
/*import { MoreHorizontal } from "react-feather";*/
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
} from "react-table";
import { matchSorter } from "match-sorter";
import useAuth from "../../hooks/useAuth";
import axios from "../../utils/axios";
import EditForm from "../community/EditForm";
import Overview from "../community/Overview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
require("moment/locale/fr");

const Users = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [lastReports, setLastReports] = useState([]);
  const [dataResponse, setDataResponse] = useState([]);

  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [fnConfirm, setFnConfirm] = useState(null);

  const Photo = ({ userId, index }) => {
    let url = `https://objects.pwenter.io/users/${userId}/images/pp.jpg`;

    return (
      <img
        src={url}
        width="50"
        height="50"
        className="img-thumbnail me-2"
        alt="Users"
        key={index}
      />
    );
  };

  useEffect(() => {
    if (typeof user.customerId !== "undefined" && !show) {
      axios
        .get(`/community/getEnrolled/${user.customerId}`)
        .then((response) => {
          //console.log(response);
          var dataFiltered = response.data.data.filter(
            (item) => item.levelId <= user.levelId
          );
          var d = [];
          dataFiltered.map((item, index) => {
            var skills = [];
            if (Object.keys(item.Skills).length > 0) {
              item.Skills.map((itemSkill, index) => {
                skills = [itemSkill.title, ...skills];
                return null;
              });
            } else {
              skills = [t("No specified")];
            }
            let skillsString = skills.join(", ");
            var newy = {
              userId: item.userId,
              imageCol: <Photo userId={item.userId} index={index} />,
              firstnameCol: item.firstname,
              lastnameCol: item.lastname,
              typeCol: item.type ? item.type : t("Normal"),
              dateCol: moment(item.createdAt).format("lll"),
              levelCol: t(item.Level.title),
              skillsColl: skillsString.trim(),
              active: item.active ? t("Enabled") : t("Disabled"),
            };
            d = [...d, newy];
            return newy;
          });
          setLastReports(d);
          setDataResponse(response.data.data);
        })
        .catch((error) => console.log(error));
    }
  }, [user, show, t]);

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    const { t } = useTranslation();
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        style={{
          width: "100%",
          borderStyle: "none",
          color: "#CCC",
        }}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">{t("All")}</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const { t } = useTranslation();
    const count = preFilteredRows.length;

    return (
      <input
        style={{
          width: "100%",
          borderStyle: "none",
          color: "#CCC",
        }}
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`${t("Search")} ${count} ${t("records")}...`}
      />
    );
  }

  const data = useMemo(() => lastReports, [lastReports]);

  const columns = useMemo(
    () => [
      {
        Header: t("#"),
        accessor: "userId",
        disableFilters: true,
      },
      {
        Header: t("Photo"),
        accessor: "imageCol",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: t("Firstname"),
        accessor: "firstnameCol",
        filter: "includes",
      },
      {
        Header: t("Lastname"),
        accessor: "lastnameCol",
        filter: "includes",
      },
      {
        Header: t("Skills"),
        accessor: "skillsColl",
        disableSortBy: true,
      },
      {
        Header: t("Date"),
        accessor: "dateCol",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: t("Role"),
        accessor: "levelCol",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: t("State"),
        accessor: "active",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
    ],
    [t]
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 50,
        pageIndex: 0,
        filters: [{ id: "active", value: t("Enabled") }],
      },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [selectRowValues, setSelectedRowValues] = useState({
    userId: null,
    firstname: "",
    lastname: "",
    address: "",
    city: "",
    language: null,
    active: 1,
    submit: false,
    levelId: 0,
  });
  const handleShow = (userId) => {
    let values = dataResponse.filter((item) => item.userId === userId);
    setSelectedRowValues(values[0]);
    setShow(true);
  };

  const handleClose = () => setShow(false);
  const handleCancel = () => setShowConfirm(false);

  const handleRemove = (userId) => {
    axios
      .delete(`/reports/trash/${userId}`)
      .then((response) => {
        console.log(response);
        setShow(false);
      })
      .catch((error) => console.log(error));
  };

  const ModalView = ({ show, handleClose, setShowConfirm }) => {
    return (
      <Modal
        show={show}
        onHide={() => handleClose()}
        size="xl"
        style={{ zIndex: 1053, overlay: { zIndex: 1054 } }}
      >
        <Modal.Header closeButton>
          <h2>{selectRowValues.userId !== null ? t("Edit") : t("New")}</h2>
        </Modal.Header>
        <Modal.Body className="m-3">
          {selectRowValues.userId !== null ? (
            <Tabs
              defaultActiveKey="content"
              id="justify-tab-example"
              className="mb-3"
              justify
            >
              <Tab eventKey="content" title={t("Overview")}>
                <EditForm
                  values={selectRowValues}
                  user={user}
                  setShow={setShow}
                />
                <Overview data={selectRowValues} user={user} />
              </Tab>
            </Tabs>
          ) : (
            <EditForm values={selectRowValues} user={user} setShow={setShow} />
          )}
        </Modal.Body>
        <Modal.Footer>
          {selectRowValues.sended === null ? (
            <Button
              variant="primary"
              onClick={() => {
                setShowConfirm(true);
                setFnConfirm("send");
              }}
            >
              {t("Send")}
            </Button>
          ) : null}
          {Object.keys(selectRowValues).length > 0 ? (
            <Button
              onClick={() => {
                setShowConfirm(true);
                setFnConfirm("remove");
              }}
              variant="danger"
              className=" rounded"
            >
              <FontAwesomeIcon icon={faTrashAlt} fixedWidth /> {t("Delete")}
            </Button>
          ) : null}
          <Button variant="secondary" onClick={() => handleClose()}>
            {t("Close")}
          </Button>
        </Modal.Footer>
        <ConfirmationView handleCancel={handleCancel} />
      </Modal>
    );
  };

  const ConfirmationView = ({ handleCancel }) => {
    return (
      <Modal
        show={showConfirm}
        onHide={() => handleCancel()}
        centered
        /*style={{ zIndex: 1056, overlay: { zIndex: 1055 } }}*/
      >
        <Modal.Header closeButton>{t("Confirmation")}</Modal.Header>
        <Modal.Body className="m-3">{t("Are you sure?")}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleCancel()}>
            {t("Cancel")}
          </Button>

          <Button
            variant="success"
            onClick={() => {
              switch (fnConfirm) {
                case "remove":
                  handleRemove(selectRowValues.userId);
                  break;
                case "send":
                  break;
                default:
                  break;
              }
              setShowConfirm(false);
            }}
          >
            {t("Yes")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title className="mb-0">{t("Community")}</Card.Title>
      </Card.Header>
      <Table
        className="my-0"
        striped
        bordered
        hover
        size="sm"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="align-top"
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={faChevronDown} fixedWidth />
                      ) : (
                        <FontAwesomeIcon icon={faChevronUp} fixedWidth />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      onClick={() => handleShow(cell.row.values.userId)}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <div className="pagination">
        <Button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          variant="outline-none"
          className="mx-2 "
        >
          <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
          <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
        </Button>
        <Button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          variant="outline-none"
          className="mx-2"
        >
          <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
        </Button>
        <Button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          variant="outline-none"
          className="mx-2"
        >
          <FontAwesomeIcon icon={faChevronRight} fixedWidth />
        </Button>
        <Button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          variant="outline-none"
          className="mx-2"
        >
          <FontAwesomeIcon icon={faChevronRight} fixedWidth />
          <FontAwesomeIcon icon={faChevronRight} fixedWidth />
        </Button>
        <span className="px-3">
          {" "}
          {t("Page")}{" "}
          <strong>
            {pageIndex + 1}
            {t(" of ")}
            {pageOptions.length}
          </strong>{" "}
        </span>
        <span className="px-3">
          {t("Go to page")}:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{
              width: "100px",
              borderStyle: "none",
            }}
          />
        </span>{" "}
        <select
          value={pageSize}
          style={{
            borderColor: "#DDD",
            borderStyle: "none",
            border: "1px",
          }}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[50, 100, 150, 200, 500].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {t("Show")} {pageSize}
            </option>
          ))}
        </select>
      </div>
      <ModalView
        show={show}
        handleClose={handleClose}
        setShowConfirm={setShowConfirm}
        setFnConfirm={setFnConfirm}
      />
    </Card>
  );
};

export default Users;
