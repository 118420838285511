import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import SignIn from "../../components/auth/SignIn";
import avatar from "../../assets/img/avatars/avatar-placeholder.jpeg";
import { withTranslation } from "react-i18next";

const SignInPage = ({ t }) => {
  const [name, setName] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const id = window.localStorage.getItem("id");
    const firstname = window.localStorage.getItem("name");
    if (id !== null) {
      setName(firstname);
      setUserId(id);
    }
  }, []);

  return (
    <React.Fragment>
      <Helmet title={t("Sign In")} />
      <div className="text-center mt-4">
        <h2>
          {t("Welcome back")}
          {name === null ? null : `, ${name}`}
        </h2>
        <p className="lead">{t("Sign in to your account to continue")}</p>
      </div>

      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center">
              <img
                src={
                  userId === null
                    ? avatar
                    : `https://objects.pwenter.io/users/${userId}/images/pp.jpg`
                }
                alt={name}
                className="img-fluid rounded-circle"
                width="132"
                height="132"
              />
            </div>
            <SignIn />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(SignInPage);
