import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  /*Badge,Dropdown,*/
  Card,
  Table,
  Modal,
  Button,
  Tab,
  Tabs,
} from "react-bootstrap";
/*import { MoreHorizontal } from "react-feather";*/
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
} from "react-table";
import { matchSorter } from "match-sorter";
import useAuth from "../../hooks/useAuth";
import axios from "../../utils/axios";
import EditForm from "./EditForm";
import Videos from "./Videos";
import Map from "./Map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import cyclone from "../../assets/img/hazards/cyclone_pwenter.png";
import incendie from "../../assets/img/hazards/incendie_pwenter.png";
import volcan from "../../assets/img/hazards/volcan_pwenter.png";
import mat_danger from "../../assets/img/hazards/mat_danger_pwenter.png";
import mouvement from "../../assets/img/hazards/mouvement_pwenter.png";
import tsunami from "../../assets/img/hazards/tsunami_pwenter.png";
import seisme from "../../assets/img/hazards/seisme_pwenter.png";
import secheresse from "../../assets/img/hazards/secheresse_pwenter.png";
import submersion from "../../assets/img/hazards/submersion_pwenter.png";
import inondation from "../../assets/img/hazards/inondation_pwenter.png";
import air from "../../assets/img/hazards/air_pwenter.png";
import sargassum from "../../assets/img/hazards/sargassum_pwenter.png";
import feuforet from "../../assets/img/hazards/feuforet_pwenter.png";
import neige from "../../assets/img/hazards/neige_pwenter.png";
import wind from "../../assets/img/hazards/wind_pwenter.png";

const Reports = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [lastReports, setLastReports] = useState([]);
  const [dataResponse, setDataResponse] = useState([]);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [fnConfirm, setFnConfirm] = useState(null);

  useEffect(() => {
    if (typeof user.customerId !== "undefined" && !show) {
      axios
        .get(`/hazards/getHazards/${user.customerId}`)
        .then((response) => {
          var d = [];
          response.data.data.map((item, index) => {
            var src = null;
            switch (item.image) {
              case "cyclone-pwenter.png":
                src = cyclone;
                break;
              case "incendie-pwenter.png":
                src = incendie;
                break;
              case "volcan-pwenter.png":
                src = volcan;
                break;
              case "inondation-pwenter.png":
                src = inondation;
                break;
              case "air-pwenter.png":
                src = air;
                break;
              case "sargassum-pwenter.png":
                src = sargassum;
                break;
              case "mouvement-pwenter.png":
                src = mouvement;
                break;
              case "secheresse-pwenter.png":
                src = secheresse;
                break;
              case "seisme-pwenter.png":
                src = seisme;
                break;
              case "submersion-pwenter.png":
                src = submersion;
                break;
              case "tsunami-pwenter.png":
                src = tsunami;
                break;
              case "mat-danger-pwenter.png":
                src = mat_danger;
                break;
              case "neige-pwenter.png":
                src = neige;
                break;
              case "feuforet-pwenter.png":
                src = feuforet;
                break;
              case "wind-pwenter.png":
                src = wind;
                break;

              default:
                src = null;
                break;
            }

            var newy = {
              image: (
                <img
                  src={src}
                  width="60"
                  height="60"
                  className="img-fluid justify-content-center align-self-center text-center"
                  alt="Thumb"
                  key={index}
                />
              ),
              title: item.title,
              subTitle: item.subTitle,
              zoneName: item["Zones.name"],
              hazardZoneId: item["Zones.HazardsZones.hazardZoneId"],
              active: item.active ? t("Enabled") : t("Disabled"),
            };
            d = [...d, newy];
            return newy;
          });
          setLastReports(d);
          setDataResponse(response.data.data);
        })
        .catch((error) => console.log(error));
    }
  }, [user, show, t]);

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    const { t } = useTranslation();
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        style={{
          width: "100%",
          borderStyle: "none",
          color: "#CCC",
        }}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">{t("All")}</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const { t } = useTranslation();
    const count = preFilteredRows.length;

    return (
      <input
        style={{
          width: "100%",
          borderStyle: "none",
          color: "#CCC",
        }}
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`${t("Search")} ${count} ${t("records")}...`}
      />
    );
  }

  const data = useMemo(() => lastReports, [lastReports]);

  const columns = useMemo(
    () => [
      {
        Header: t("#"),
        accessor: "hazardZoneId",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: t("Image"),
        accessor: "image",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: t("Title"),
        accessor: "title",
        disableSortBy: true,
      },
      {
        Header: t("Subtitle"),
        accessor: "subTitle",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: t("Area"),
        accessor: "zoneName",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: t("State"),
        accessor: "active",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
    ],
    [t]
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, filters },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        filters: JSON.parse(localStorage.getItem("tableFilters")) || [
          { id: "active", value: t("Enabled") },
        ],
      },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    localStorage.setItem("tableFilters", JSON.stringify(filters));
  }, [filters]);

  const [selectRowValues, setSelectedRowValues] = useState({
    hazardZoneId: null,
    hazardId: null,
    zoneId: null,
    title: "",
    subTitle: "",
    active: 1,
    submit: false,
  });
  const handleShow = (hazardZoneId) => {
    let values = dataResponse.filter(
      (item) => item["Zones.HazardsZones.hazardZoneId"] === hazardZoneId
    );
    setSelectedRowValues(
      values[0]["Zones.HazardsZones.plan"] === ""
        ? { ...values[0], "Zones.HazardsZones.plan": null }
        : values[0]
    );
    setShow(true);
  };

  const handleClose = () => setShow(false);
  const handleCancel = () => setShowConfirm(false);
  const handleCreate = () => {
    setSelectedRowValues({
      hazardZoneId: null,
      hazardId: null,
      zoneId: null,
      title: "",
      subTitle: "",
      active: 1,
      submit: false,
      "Zones.HazardsZones.hazardZoneId": null,
      "Zones.zoneId": null,
    });
    setShow(true);
  };

  const handleRemove = (hazardZoneId) => {
    axios
      .delete(`/hazards/hazardZone/trash/${hazardZoneId}`)
      .then(() => {
        setShow(false);
      })
      .catch((error) => console.log(error));
  };

  const ModalView = ({ show, handleClose, setShowConfirm }) => {
    return (
      <Modal
        show={show}
        onHide={() => handleClose()}
        size="xl"
        style={{ zIndex: 1053, overlay: { zIndex: 1054 } }}
      >
        <Modal.Header closeButton>
          <h2>
            {selectRowValues.hazardZoneId !== null ? t("Edit") : t("New")}
          </h2>
        </Modal.Header>
        <Modal.Body className="m-3">
          {selectRowValues.hazardZoneId !== null ? (
            <Tabs
              defaultActiveKey="content"
              id="justify-tab-example"
              className="mb-3"
              justify
            >
              <Tab eventKey="content" title={t("Overview")}>
                <EditForm
                  values={selectRowValues}
                  user={user}
                  setShow={setShow}
                />
              </Tab>

              <Tab eventKey="videos" title={t("Videos")}>
                <Videos values={selectRowValues} user={user} />
              </Tab>
              <Tab eventKey="map" title={t("Map")}>
                <Map values={selectRowValues} user={user} />
              </Tab>
            </Tabs>
          ) : (
            <EditForm values={selectRowValues} user={user} setShow={setShow} />
          )}
        </Modal.Body>
        <Modal.Footer>
          {selectRowValues.sended === null ? (
            <Button
              variant="primary"
              onClick={() => {
                setShowConfirm(true);
                setFnConfirm("send");
              }}
            >
              {t("Send")}
            </Button>
          ) : null}
          {Object.keys(selectRowValues).length > 0 ? (
            <Button
              onClick={() => {
                setShowConfirm(true);
                setFnConfirm("remove");
              }}
              variant="danger"
              className=" rounded"
              disabled={selectRowValues.hazardZoneId !== null ? false : true}
            >
              <FontAwesomeIcon icon={faTrashAlt} fixedWidth /> {t("Delete")}
            </Button>
          ) : null}
          <Button variant="secondary" onClick={() => handleClose()}>
            {t("Close")}
          </Button>
        </Modal.Footer>
        <ConfirmationView handleCancel={handleCancel} />
      </Modal>
    );
  };

  const ConfirmationView = ({ handleCancel }) => {
    return (
      <Modal
        show={showConfirm}
        onHide={() => handleCancel()}
        centered
        /*style={{ zIndex: 1056, overlay: { zIndex: 1055 } }}*/
      >
        <Modal.Header closeButton>{t("Confirmation")}</Modal.Header>
        <Modal.Body className="m-3">{t("Are you sure?")}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleCancel()}>
            {t("Cancel")}
          </Button>

          <Button
            variant="success"
            onClick={() => {
              switch (fnConfirm) {
                case "remove":
                  handleRemove(
                    selectRowValues["Zones.HazardsZones.hazardZoneId"]
                  );
                  break;
                case "send":
                  break;
                default:
                  break;
              }
              setShowConfirm(false);
            }}
          >
            {t("Yes")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div>
      <Button className="mb-3" variant="primary" onClick={() => handleCreate()}>
        {t("New")}
      </Button>

      <Card className="flex-fill w-100">
        <Card.Header>
          <Card.Title className="mb-0">{t("Hazards")}</Card.Title>
        </Card.Header>
        <Table
          className="my-0"
          striped
          bordered
          hover
          size="sm"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="align-top"
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faChevronDown} fixedWidth />
                        ) : (
                          <FontAwesomeIcon icon={faChevronUp} fixedWidth />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        onClick={() => handleShow(cell.row.values.hazardZoneId)}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>

        <div className="pagination">
          <Button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            variant="outline-none"
            className="mx-2 "
          >
            <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
            <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
          </Button>
          <Button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            variant="outline-none"
            className="mx-2"
          >
            <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
          </Button>
          <Button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            variant="outline-none"
            className="mx-2"
          >
            <FontAwesomeIcon icon={faChevronRight} fixedWidth />
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            variant="outline-none"
            className="mx-2"
          >
            <FontAwesomeIcon icon={faChevronRight} fixedWidth />
            <FontAwesomeIcon icon={faChevronRight} fixedWidth />
          </Button>
          <span className="px-3">
            {" "}
            {t("Page")}{" "}
            <strong>
              {pageIndex + 1}
              {t(" of ")}
              {pageOptions.length}
            </strong>{" "}
          </span>
          <span className="px-3">
            {t("Go to page")}:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{
                width: "100px",
                borderStyle: "none",
              }}
            />
          </span>{" "}
          <select
            value={pageSize}
            style={{
              borderColor: "#DDD",
              borderStyle: "none",
              border: "1px",
            }}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {t("Show")} {pageSize}
              </option>
            ))}
          </select>
        </div>
        <ModalView
          show={show}
          handleClose={handleClose}
          setShowConfirm={setShowConfirm}
          setFnConfirm={setFnConfirm}
        />
      </Card>
    </div>
  );
};

export default Reports;
