import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "../../utils/axios";
import { Form } from "react-bootstrap";

const PlaceModel = ({
  values,
  user,
  handleBlur,
  handleChange,
  resetForm,
  setDataForm,
}) => {
  const { t } = useTranslation();

  const [models, setModels] = useState([]);
  const [select, setSelect] = useState(<></>);

  useEffect(() => {
    const setData = (modelId) => {
      let model = models.filter(
        (model) => model.modelId === parseInt(modelId)
      )[0];

      if (typeof model !== "undefined") {
        setDataForm({
          notificationId: values.notificationId,
          modelId: values.modelId,
          title: model.title !== "" ? model.title : values.title,
          subtitle: model.subtitle !== "" ? model.subtitle : values.subtitle,
          message: model.message !== "" ? model.message : values.message,
          color: model.color !== "" ? model.color : values.color,
          sound: model.sound !== "" ? model.sound : values.sound,
          postId: values.postId,
          sended: values.sended,
          submit: false,
          category: values.category,
          active: 1,
        });

        resetForm({
          values: {
            notificationId: values.notificationId,
            modelId: values.modelId,
            title: model.title !== "" ? model.title : values.title,
            subtitle: model.subtitle !== "" ? model.subtitle : values.subtitle,
            message: model.message !== "" ? model.message : values.message,
            color: model.color !== "" ? model.color : values.color,
            sound: model.sound !== "" ? model.sound : values.sound,
            postId: values.postId,
            sended: values.sended,
            submit: false,
            category: values.category,
            active: 1,
          },
        });
      }
    };
    if (typeof user.customerId !== "undefined") {
      axios
        .get(`/notifications/getModels/${user.customerId}`)
        .then((response) => {
          setModels(response.data.data);
          setSelect(
            <Form.Select
              name="insertModel"
              onBlur={handleBlur}
              onChange={(e) => setData(e.target.value)}
              value={values.modelId === null ? "" : values.modelId}
              aria-label="Default select example"
              disabled={values.sended ? true : false}
            >
              <option>{t("Open this select menu")}</option>
              {response.data.data.map((model, index) => {
                return (
                  <option value={parseInt(model.modelId)} key={index}>
                    {t(model.title)}
                  </option>
                );
              })}
            </Form.Select>
          );
        })
        .catch((error) => {
          setSelect(
            <Form.Select
              value={values.modelId === null ? "" : values.modelId}
              aria-label="Default select example"
              disabled={values.sended ? true : false}
            >
              <option>{t("Open this select menu")}</option>
            </Form.Select>
          );
        });
    }
  }, [
    user,
    handleBlur,
    handleChange,
    t,
    values,
    models,
    resetForm,
    setDataForm,
  ]);

  return select;
};

export default PlaceModel;
