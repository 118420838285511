import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  /*Badge,Dropdown,*/
  Card,
  Row,
  Col,
} from "react-bootstrap";
import {
  BsInputCursorText,
  BsTextareaT,
  BsCalendar2Date,
  BsTelephone,
  Bs123,
  BsImage,
} from "react-icons/bs";
import {
  MdAlternateEmail,
  MdOutlineCheckBox,
  MdOutlineRadioButtonChecked,
  MdOutlineKey,
} from "react-icons/md";
import { TiDocumentText } from "react-icons/ti";
import {
  TbSquareRoundedPlus,
  TbSquareRoundedMinus,
  TbCategory2,
  TbListDetails,
  TbBookmark,
} from "react-icons/tb";
import moment from "moment";
require("moment/locale/fr");

const Survey = ({ data, user }) => {
  const { t } = useTranslation();

  const [fields] = useState(JSON.parse(data.fields));
  const [dataJson] = useState(JSON.parse(data.dataJson));

  var typeList = [
    { title: "Text", value: "text", icon: <BsInputCursorText /> },
    { title: "Textarea", value: "textarea", icon: <BsTextareaT /> },
    { title: "Number", value: "number", icon: <Bs123 /> },
    { title: "Email", value: "email", icon: <MdAlternateEmail /> },
    { title: "Tel", value: "tel", icon: <BsTelephone /> },
    { title: "Date", value: "date", icon: <BsCalendar2Date /> },
    { title: "Checkbox", value: "checkbox", icon: <MdOutlineCheckBox /> },
    { title: "Radio", value: "radio", icon: <MdOutlineRadioButtonChecked /> },
    { title: "Text Zone", value: "textzone", icon: <TiDocumentText /> },
    { title: "Logo", value: "logo", icon: <BsImage /> },
  ];

  const getIcon = (type) => {
    let item = typeList.filter((e) => e.value === type);
    return item[0].icon;
  };

  const getTitle = (type) => {
    let item = typeList.filter((e) => e.value === type);
    return item[0].title;
  };

  const getResponse = (idKey, type) => {
    let item = dataJson.filter((e) => e.key === idKey);
    var result = "";
    switch (type) {
      case "checkbox":
        result =
          typeof item[0] !== "undefined"
            ? item[0].value.join(", ")
            : t("Error");
        break;

      case "date":
        result =
          typeof item[0] !== "undefined"
            ? moment(item[0].value).format("LLLL")
            : t("Error");
        break;

      default:
        result = typeof item[0] !== "undefined" ? item[0].value : t("Error");
        break;
    }
    return result;
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title>{t("Form Fields")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Row className=" me-2">
              {fields && fields.length > 0 ? (
                fields.map((field, index) => {
                  if (field.type !== "logo" && field.type !== "textzone") {
                    return (
                      <Col
                        className={`p-2 mx-2 col-sm-12 rounded bg-light mb-2`}
                        key={index}
                      >
                        <Row>
                          <Col className="fs-3 col-sm-1">
                            {getIcon(field.type)}
                          </Col>
                          <Col
                            className="fs-4 fw-bold col-sm-8"
                            onClick={() => null}
                          >
                            {getResponse(field.idKey, field.type)}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-sm col-sm-12">
                            <TbBookmark className="fs-4 me-1" />
                            {field.label}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-sm col-sm-12">
                            <TbCategory2 className="fs-4 me-1" />
                            {t(getTitle(field.type))}{" "}
                            {field.type === "text" ||
                            field.type === "textarea" ||
                            field.type === "number" ? (
                              <>
                                <TbSquareRoundedMinus className="fs-4 mb-1 ms-2 me-1" />
                                {field.options.min !== ""
                                  ? field.options.min
                                  : t("None")}
                                {"  "}
                                <TbSquareRoundedPlus className="fs-4 mb-1 ms-2 me-1" />
                                {field.options.max !== ""
                                  ? field.options.max
                                  : t("None")}
                              </>
                            ) : field.type === "date" ? (
                              <>
                                <TbSquareRoundedMinus className="fs-4 mb-1 ms-2 me-1" />
                                {field.options.dateStart !== ""
                                  ? moment(field.options.dateStart).format(
                                      "LL LT"
                                    )
                                  : t("None")}
                                {"  "}
                                <TbSquareRoundedPlus className="fs-4 mb-1 ms-2 me-1" />
                                {field.options.dateEnd !== ""
                                  ? moment(field.options.dateEnd).format(
                                      "LL LT"
                                    )
                                  : t("None")}
                              </>
                            ) : field.type === "radio" ||
                              field.type === "checkbox" ? (
                              <>
                                <TbListDetails className="fs-4 mb-1 ms-2 me-1" />
                                {field.options.responses !== ""
                                  ? field.options.responses.split(",").length
                                  : t("None")}
                              </>
                            ) : null}{" "}
                            <MdOutlineKey className="fs-4 mb-1 ms-2 me-1" />
                            {field.idKey}
                          </Col>
                        </Row>
                      </Col>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <Col>{t("No field")}</Col>
              )}
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <h3>{t("")}</h3>
                <Row>
                  <Col></Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Survey;
