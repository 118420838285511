import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MoreHorizontal } from "react-feather";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../utils/axios";
import moment from "moment";
require("moment/locale/fr");

const News = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [timeline, setTimeline] = useState([]);

  useEffect(() => {
    if (typeof user.customerId !== "undefined") {
      axios
        .get(`/news/getLast/${user.customerId}/3`)
        .then((response) => {
          let data = response.data.data;
          setTimeline(data);
          return () => null;
        })
        .catch((error) => console.log(error));
    }
  }, [user]);

  const goTo = (page) => {
    navigate(`./${page}`);
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => goTo("news")}>
                Toute l'actualité
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title className="mb-0">{t("News")}</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        <ul className="timeline overflow-auto" style={{ overFlow: "auto" }}>
          {timeline.map((news, index) => {
            const regex = /(<([^>]+)>)/gi;
            const result = news.content.replace(regex, "");
            const content =
              result.length > 200 ? result.substring(0, 200) + "..." : result;
            return (
              <li className="timeline-item" key={index}>
                <strong>{news.title}</strong>
                <span className="float-end text-muted text-sm">
                  {moment(news.createdAt).fromNow()}
                </span>
                <p>{content}</p>
              </li>
            );
          })}
        </ul>
      </Card.Body>
    </Card>
  );
};

export default News;
