import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import {
  Card,
  Table,
  Modal,
  Button,
  Tab,
  Tabs,
  Col,
  Row,
  Container,
} from "react-bootstrap";
import { useTable } from "react-table";
import useAuth from "../../hooks/useAuth";
import axios from "../../utils/axios";
import EditForm from "./EditForm";
import Map from "./Map";
import Fields from "./Fields";
import Feedbacks from "./Feedbacks";
import Analytics from "./Analytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faToggleOn,
  faToggleOff,
  faPlus,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import illustration from "../../assets/img/illustrations/help.png";
import moment from "moment";
require("moment/locale/fr");

const Channels = () => {
  const printComponentRef = useRef();
  const { t } = useTranslation();
  const { user } = useAuth();

  const [lastReports, setLastReports] = useState([]);
  const [dataResponse, setDataResponse] = useState([]);

  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [fnConfirm, setFnConfirm] = useState(null);

  useEffect(() => {
    if (typeof user.customerId !== "undefined" && !show) {
      axios
        .get(`/channels/getChannels/${user.customerId}`)
        .then((response) => {
          var d = [];
          response.data.data.map((item) => {
            var newy = {
              channelId: item.channelId,
              title: item.title,
              dateStart:
                item.dateStart !== null
                  ? moment(item.dateStart, "YYYY-MM-DD HH:mm:ss Z").format(
                      "L LT"
                    )
                  : `-`,
              dateEnd:
                item.dateEnd !== null
                  ? moment(item.dateEnd, "YYYY-MM-DD HH:mm:ss Z").format("L LT")
                  : `-`,
              feedbacks: item.Reports ? item.Reports.length : 0,
              //active: item.active ? t("Enabled") : t("Disabled"),
              active: item.active ? (
                <FontAwesomeIcon
                  icon={faToggleOn}
                  size="2x"
                  color={`#006400`}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faToggleOff}
                  size="2x"
                  color={`#FF0000`}
                />
              ),
            };
            d = [...d, newy];
            return newy;
          });
          setLastReports(d);
          setDataResponse(response.data.data);
        })
        .catch((error) => console.log(error));
    }
  }, [user, show, t]);

  const data = React.useMemo(() => lastReports, [lastReports]);

  const columns = React.useMemo(
    () => [
      {
        Header: t("#"),
        accessor: "channelId",
      },
      {
        Header: t("Title"),
        accessor: "title",
      },
      {
        Header: t("Start Date"),
        accessor: "dateStart",
      },
      {
        Header: t("End Date"),
        accessor: "dateEnd",
      },
      {
        Header: t("Feedbacks"),
        accessor: "feedbacks",
      },
      {
        Header: t("State"),
        accessor: "active",
      },
    ],
    [t]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const [selectRowValues, setSelectedRowValues] = useState({
    channelId: null,
    title: "",
    resume: "",
    customerId: user.customerId,
    active: 1,
    submit: false,
  });
  const [help, setHelp] = useState(false);

  const handleShow = (channelId) => {
    let values = dataResponse.filter((item) => item.channelId === channelId);
    setSelectedRowValues(values[0]);
    setShow(true);
  };

  const handleClose = () => setShow(false);
  const handleCancel = () => setShowConfirm(false);
  const handleCreate = () => {
    setSelectedRowValues({
      channelId: null,
      title: "",
      resume: "",
      customerId: user.customerId,
      active: 1,
      submit: false,
      postCategoryId: null,
    });
    setShow(true);
  };

  const handleRemove = (channelId) => {
    axios
      .delete(`/channels/trash/${channelId}`)
      .then(() => {
        setShow(false);
      })
      .catch((error) => console.log(error));
  };

  const ModalView = ({ show, handleClose, setShowConfirm }) => {
    return (
      <Modal
        show={show}
        onHide={() => handleClose()}
        size="xl"
        style={{ zIndex: 1053, overlay: { zIndex: 1054 } }}
      >
        <div ref={printComponentRef}>
          <Modal.Header closeButton>
            <h2>
              {selectRowValues.channelId !== null ? t("Channel") : t("New")}
            </h2>
          </Modal.Header>
          <Modal.Body className="m-3">
            {selectRowValues.channelId !== null ? (
              <Tabs
                defaultActiveKey="resume"
                id="justify-tab-example"
                className="mb-3"
                justify
              >
                {user.levelId > 8 ? (
                  <Tab eventKey="resume" title={t("Content")}>
                    <EditForm
                      values={selectRowValues}
                      user={user}
                      setShow={setShow}
                    />
                  </Tab>
                ) : null}
                {user.levelId > 8 ? (
                  <Tab eventKey="map" title={t("Map")}>
                    <Map values={selectRowValues} user={user} />
                  </Tab>
                ) : null}
                {user.levelId > 8 ? (
                  <Tab eventKey="fields" title={t("Form Fields")}>
                    <Fields values={selectRowValues} user={user} />
                  </Tab>
                ) : null}
                {user.levelId > 6 ? (
                  <Tab eventKey="feedbacks" title={t("Feedbacks")}>
                    <Feedbacks values={selectRowValues} user={user} />
                  </Tab>
                ) : null}
                {user.levelId > 5 ? (
                  <Tab eventKey="analytics" title={t("Analytics")}>
                    <Analytics
                      data={selectRowValues}
                      printComponentRef={printComponentRef}
                    />
                  </Tab>
                ) : null}
              </Tabs>
            ) : (
              <EditForm
                values={selectRowValues}
                user={user}
                setShow={setShow}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            {selectRowValues.sended === null ? (
              <Button
                variant="primary"
                onClick={() => {
                  setShowConfirm(true);
                  setFnConfirm("send");
                }}
              >
                {t("Send")}
              </Button>
            ) : null}
            {Object.keys(selectRowValues).length > 0 ? (
              <Button
                onClick={() => {
                  setShowConfirm(true);
                  setFnConfirm("remove");
                }}
                variant="danger"
                className=" rounded d-print-none"
              >
                <FontAwesomeIcon icon={faTrashAlt} fixedWidth /> {t("Delete")}
              </Button>
            ) : null}
            <Button
              variant="secondary"
              onClick={() => handleClose()}
              className=" d-print-none"
            >
              {t("Close")}
            </Button>
          </Modal.Footer>
          <ConfirmationView handleCancel={handleCancel} />
        </div>
      </Modal>
    );
  };

  const ConfirmationView = ({ handleCancel }) => {
    return (
      <Modal
        show={showConfirm}
        onHide={() => handleCancel()}
        centered
        /*style={{ zIndex: 1056, overlay: { zIndex: 1055 } }}*/
      >
        <Modal.Header closeButton>{t("Confirmation")}</Modal.Header>
        <Modal.Body className="m-3">{t("Are you sure?")}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleCancel()}>
            {t("Cancel")}
          </Button>

          <Button
            variant="success"
            onClick={() => {
              switch (fnConfirm) {
                case "remove":
                  handleRemove(selectRowValues.channelId);
                  break;
                case "send":
                  break;
                default:
                  break;
              }
              setShowConfirm(false);
            }}
          >
            {t("Yes")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      <Helmet title={t(`Channels`)} />
      <Container fluid className="p-0">
        <Button
          className="float-end mt-n1 ms-1"
          variant="warning"
          onClick={() => setHelp(!help)}
        >
          <FontAwesomeIcon icon={faQuestion} />
        </Button>
        <Button
          className="float-end mt-n1"
          variant="primary"
          onClick={() => handleCreate()}
        >
          <FontAwesomeIcon icon={faPlus} /> {t("New")}
        </Button>
        <h1 className="h3 mb-3">{t(`Channels`)}</h1>

        <Row className={`m-1 ${!help && `d-none`}`}>
          <Col className="bg-light rounded-lg ps-2 mb-4">
            <Row className="">
              <Col xs={2} className=" text-center p-0 m-0">
                <img
                  src={illustration}
                  alt="Customer Support"
                  className="img-fluid illustration-img"
                />
              </Col>
              <Col
                className="p-4 pt-2 text-lg"
                style={{ whiteSpace: "pre-line" }}
              >
                {t("HELP_CHANNELS")}
              </Col>
            </Row>
          </Col>
        </Row>

        <Card className="flex-fill w-100">
          <Card.Header>
            <Card.Title className="mb-0">{t("Channels")}</Card.Title>
          </Card.Header>
          <Table
            className="my-0"
            striped
            bordered
            hover
            size="sm"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          onClick={() => handleShow(cell.row.values.channelId)}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <ModalView
            show={show}
            handleClose={handleClose}
            setShowConfirm={setShowConfirm}
            setFnConfirm={setFnConfirm}
          />
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Channels;
