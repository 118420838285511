import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { Card, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCircleDot } from "@fortawesome/free-solid-svg-icons";

import usePalette from "../../../hooks/usePalette";

const BarChart = ({ field, raw }) => {
  const { t } = useTranslation();

  const palette = usePalette();
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);
  const [amount, setAmount] = useState([]);
  const [colors] = useState([
    palette.primary,
    palette.warning,
    palette.danger,
    "#E8EAED",
  ]);

  const getFrequencies = (arr) => {
    const occurrences = arr.reduce((acc, element) => {
      acc[element] = (acc[element] || 0) + 1;
      return acc;
    }, {});

    const result = Object.entries(occurrences).map(([label, value]) => ({
      label: label.toString(),
      value,
    }));
    return result;
  };

  useEffect(() => {
    const getValues = () => {
      var val = [];
      val = raw.map((r) => [...val, r.value]);
      return val.flat();
    };

    let res = getFrequencies(getValues());

    let labelsData = res.map((obj) => obj.label);
    let valuesData = res.map((obj) => obj.value);
    setLabels(labelsData);
    setValues(valuesData);
    setAmount(valuesData.reduce((acc, curr) => acc + curr, 0));
  }, [raw]);

  const data = {
    labels: labels,
    datasets: [
      {
        label: field.label,
        backgroundColor: palette.primary,
        borderColor: palette.primary,
        hoverBackgroundColor: palette.primary,
        hoverBorderColor: palette.primary,
        data: values,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cornerRadius: 15,
    legend: {
      display: true,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          stacked: true,
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "transparent",
          },
          stacked: true,
        },
      ],
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title className="mb-0">{field.label}</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        <div className="align-self-center w-100">
          <div className="py-3">
            <div className="chart chart-md">
              <Bar data={data} options={options} />
            </div>
          </div>
          {typeof field.options.sizeTable !== "undefined" &&
          field.options.sizeTable > 0 ? (
            <Table className="mb-0">
              <thead>
                <tr>
                  <th>{t("Label")}</th>
                  <th className="text-end">{t("Frequency")}</th>
                  <th className="text-end">{t("%")}</th>
                </tr>
              </thead>
              <tbody>
                {labels.map((label, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <FontAwesomeIcon
                          icon={
                            field.type === "checkbox" ? faSquare : faCircleDot
                          }
                          className={`me-2`}
                          style={{ color: colors[0] }}
                        />
                        {label}
                      </td>
                      <td className="text-end">{values[index]}</td>
                      <td className="text-end text-success">
                        {((values[index] / amount) * 100).toFixed()}%
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : null}
        </div>
      </Card.Body>
    </Card>
  );
};

export default BarChart;
