import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { Card, Table } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCircleDot } from "@fortawesome/free-solid-svg-icons";

import usePalette from "../../../hooks/usePalette";

const PieChart = ({ field, raw }) => {
  const { t } = useTranslation();

  const palette = usePalette();
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);
  const [amount, setAmount] = useState([]);
  const [colors] = useState([
    palette.primary,
    palette.warning,
    palette.danger,
    "#E8EAED",
  ]);

  const getFrequencies = (arr) => {
    const occurrences = arr.reduce((acc, element) => {
      acc[element] = (acc[element] || 0) + 1;
      return acc;
    }, {});

    const result = Object.entries(occurrences).map(([label, value]) => ({
      label: label.toString(),
      value,
    }));
    return result;
  };

  const getFrequencies2 = (arr) => {
    const occurrences = [];
    arr.forEach((subArr) =>
      subArr.forEach((element) => {
        const foundIndex = occurrences.findIndex(
          (item) => item.label === element
        );
        if (foundIndex !== -1) {
          occurrences[foundIndex].value++;
        } else {
          occurrences.push({ label: element, value: 1 });
        }
      })
    );
    return occurrences;
  };

  useEffect(() => {
    const getValues = () => {
      var val = [];
      val = raw.map((r) => [...val, r.value]);
      return val.flat();
    };

    var res = [];
    if (field.type === "radio") {
      res = getFrequencies(getValues());
    } else {
      res = getFrequencies2(getValues());
    }

    let labelsData = res.map((obj) => obj.label);
    let valuesData = res.map((obj) => obj.value);
    setLabels(labelsData);
    setValues(valuesData);
    setAmount(valuesData.reduce((acc, curr) => acc + curr, 0));
  }, [raw, field.type]);

  const data = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: colors,
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title className="mb-0">{field.label}</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        <div className="align-self-center w-100">
          <div className="py-3">
            <div className="chart chart-md">
              <Pie data={data} options={options} />
            </div>
          </div>
          {typeof field.options.sizeTable !== "undefined" &&
          field.options.sizeTable > 0 ? (
            <Table className="mb-0">
              <thead>
                <tr>
                  <th>{t("Label")}</th>
                  <th className="text-end">{t("Frequency")}</th>
                  <th className="text-end">{t("%")}</th>
                </tr>
              </thead>
              <tbody>
                {labels.map((label, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <FontAwesomeIcon
                          icon={
                            field.type === "checkbox" ? faSquare : faCircleDot
                          }
                          className={`me-2`}
                          style={{ color: colors[index] }}
                        />
                        {label}
                      </td>
                      <td className="text-end">{values[index]}</td>
                      <td className="text-end text-success">
                        {((values[index] / amount) * 100).toFixed()}%
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : null}
        </div>
      </Card.Body>
    </Card>
  );
};

export default PieChart;
