import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import usePalette from "../../../hooks/usePalette";
import axios from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import moment from "moment";
require("moment/locale/fr");

const LineChart = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const [labels, setLabels] = useState(null);
  const [values, setValues] = useState(null);

  useEffect(() => {
    if (typeof user.customerId !== "undefined") {
      axios
        .get(`/stats/getReportsStats/${user.customerId}/-04:00`)
        .then((response) => {
          let data = response.data.data;
          var labelData = [];
          var valueData = [];
          data.filter(
            (item) => (labelData = [...labelData, moment(item.day).format("L")])
          );
          data.filter((item) => (valueData = [...valueData, item.units]));

          setLabels(labelData);
          setValues(valueData);
          return () => null;
        })
        .catch((error) => console.log(error));
    }
  }, [user]);

  const palette = usePalette();

  const data = {
    labels: labels,
    datasets: [
      {
        label: `${t("reports")}`,
        fill: true,
        backgroundColor: "transparent",
        borderColor: palette.primary,
        data: values,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: "rgba(0,0,0,0.05)",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 500,
          },
          display: true,
          borderDash: [5, 5],
          gridLines: {
            color: "rgba(0,0,0,0)",
            fontColor: "#fff",
          },
        },
      ],
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        {/*<div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Action</Dropdown.Item>
              <Dropdown.Item>Another Action</Dropdown.Item>
              <Dropdown.Item>Something else here</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
  </div>*/}
        <Card.Title className="mb-0">{t("Trend")}</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        <div className="align-self-center w-100">
          <div className="chart chart-lg">
            <Line data={data} options={options} />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default LineChart;
