import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Search: "Search projects…",
      "Welcome back": "Welcome back",
      less_char: "Must be at least {{n}} characters",
      Transmit: "Transmit {{date}}",
    },
  },
  fr: {
    translation: {
      Search: "Rechercher",
      Dashboard: "Tableau de bord",
      "Welcome back": "Bienvenue",
      "Sign in to your account to continue":
        "Connectez-vous à votre compte pour poursuivre",
      Email: "E-mail",
      "Enter your email": "Tapez votre e-mail",
      Password: "Mot de passe",
      "Enter your password": "Tapez votre mot de passe",
      "Forgot password?": "Mot de passe oublié?",
      "Remember me next time": "Se souvenir de moi",
      "Sign in": "Se connecter",
      "Sign In": "S'identifier",
      "Must be a valid email": "Doit être une adresse e-mail valide",
      "submited from the city": "transmis depuis le territoire",
      reports: "signalements",
      Reports: "Signalements",
      Report: "Signalement",
      "Mobile Interface": "Interface mobile",
      Download: "Télécharger",
      "Access the users mobile interface.":
        "Accéder à l'interface mobile pour les usagers.",
      Overview: "Vue d'ensemble",
      Profile: "Profil",
      "Latest Reports": "Derniers signalements",
      Calendar: "Calendrier",
      Image: "Image",
      Type: "Type",
      Date: "Date",
      Time: "Heure",
      Comment: "Commentaire",
      Status: "Statut",
      City: "Ville",
      Latitude: "Latitude",
      Longitude: "Longitude",
      Languages: "Langues",
      French: "Français",
      English: "Anglais",
      "Map Overview": "Carte interactive",
      Close: "Fermer",
      Beam: "Diffuser",
      Send: "Envoyer",
      New: "Nouveau",
      News: "Actualités",
      Articles: "Articles",
      Edit: "Modifier",
      Save: "Enregistrer",
      Title: "Titre",
      Subtitle: "Sous-titre",
      Message: "Message",
      "Post attached": "Article joint",
      Color: "Couleur",
      Sound: "Sonnerie",
      Category: "Catégorie",
      State: "État",
      "Report State": "État du rapport",
      Enabled: "Activé",
      Disabled: "Désactivé",
      Enable: "Activer",
      Disable: "Désactiver",
      Mute: "Muette",
      "Open this select menu": "Voir la sélection",
      Red: "Rouge",
      Orange: "Orange",
      Yellow: "Jaune",
      Blue: "Bleue",
      Purple: "Violet",
      Gray: "Gris",
      Black: "Noir",
      Scarlet: "Écarlate",
      Green: "Vert",
      less_char: "Doit avoir au moins {{n}} caractères",
      Transmit: "Transmis le {{date}}",
      Yes: "Oui",
      No: "Non",
      Delete: "Supprimer",
      Remove: "Retirer",
      Scope: "Portée",
      "Are you sure?": "Êtes-vous sûr ?",
      Cancel: "Annuler",
      "Hazard Type": "Type de risque",
      Hazards: "Risques",
      Hazard: "Aléa",
      Push: "A la Une",
      Content: "Contenu",
      Images: "Images",
      Timeline: "Fil d'actualité",
      "Drag and drop your image here or":
        "Glissez puis déposez votre image ici ou",
      "Upload an image": "Sélectionnez une image",
      "Any legend": "Aucune légende",
      Cover: "Couverture",
      Featured: "À la Une",
      "Add an image": "Ajouter une image",
      Form: "Formulaire",
      "Videos List": "Liste des vidéos",
      Videos: "Vidéos",
      Icon: "Îcone",
      Legend: "Légende",
      Add: "Ajouter",
      "Start Date": "Date de début",
      "End Date": "Date de fin",
      "Start Time": "Heure de début",
      "End Time": "Heure de fin",
      "No item in timeline": "Pas d'élément dans le fil d'actualité",
      Marker: "Point",
      Line: "Ligne",
      Circle: "Cercle",
      Polygon: "Polygone",
      Trend: "Tendance",
      Account: "Compte",
      Community: "Communauté",
      Show: "Afficher",
      " of ": " sur ",
      "Go to page": "Aller à la page",
      records: "enregistrements",
      All: "Tous",
      Area: "Zone",
      Advise: "Conseil",
      Users: "Utilisateurs",
      Firstname: "Prénom",
      Lastname: "Nom",
      Name: "Nom",
      Level: "Niveau",
      Skills: "Compétences",
      "No specified": "Non renseigné",
      Roles: "Rôles",
      Master: "Master",
      Manager: "Manageur",
      Registrated: "Enregistré",
      Settings: "Paramètres",
      "Account settings": "Paramètres du compte",
      "Private settings": "Paramètres privés",
      "Reset Code": "Code de réinitialisation",
      "Verify Code": "Code de vérification",
      None: "Aucun",
      Scan: "Scan",
      QRCode: "QRCode",
      Select: "Sélection",
      Reliefs: "Assistance",
      Connected: "Connectés",
      Enrolled: "Enrollés",
      "Field Data": "Données terrain",
      Channels: "Canaux de réception",
      Channel: "Canal",
      "Reports Sheet": "Fiches de signalement",
      Map: "Carte",
      "Form Fields": "Champs du formulaire",
      "No field": "Aucun champ",
      "ID Key": "Clé ID",
      "Text Zone": "Zone de texte",
      Text: "Texte",
      Number: "Numérique",
      "Options separated by coma": "Options séparées par des virgules",
      Feedbacks: "Feedbacks",
      Response: "Réponse",
      Analytics: "Analytiques",
      Size: "Taille",
      Group: "Groupe",
      "Chart Type": "Type de graphique",
      "Group 1": "Groupe 1",
      "Group 2": "Groupe 2",
      "Group 3": "Groupe 3",
      "Group 4": "Groupe 4",
      "Group 5": "Groupe 5",
      Bar: "Diagramme en barres",
      "Line Chart": "Linéaire",
      Pie: "Circulaire",
      Radar: "Radar",
      WorldMap: "Cart",
      "Sample size": "Taille de l'échantillon",
      Since: "Depuis le",
      Period: "Période",
      "Extract data": "Extraire les données",
      Frequency: "Fréquence",
      "Table Size": "Taille du tableau",
      "Push Messages": "Messages Push",
      Models: "Modèles",
      Model: "Modèle",
      "Pre-registered model": "Modèles pré-enregistrés",
      "Click here to auto-generate the alias":
        "Cliquez ici pour auto-généré l'alias",
      Address: "Adresse",
      "Short Name": "Nom court",
      Phone: "Téléphone",
      Zip: "Code postal",
      Country: "Pays",
      "Assiociated accounts": "Comptes associés",
      "Public info": "Informations publiques",
      Switcher: "Permuter",
      "Manager account": "Compte gestionnaire",
      Upload: "Téléverser",
      "User Profile": "Profil utilisateur",
      Post: "Post",
      TITLE_MOD_NEWS: "Actualités",
      TITLE_MOD_PUSH: "Notifications",
      TITLE_MOD_LOCALDATA: "Données terrain",
      TITLE_MOD_COMMUNITY: "Communauté",
      Test: "Essayer",
      Discover: "Découvrir",
      "Coming Soon": "Prochainement",
      Organization: "Organisation",
      Available: "Disponible",
      Demo: "Démo",
      Share: "Partager",
      "Share to partners": "Partager aux partenaires",
      From: "De",
      Partnerships: "Partenariats",
      Log: "Journal",
      "Type your message": "Tapez votre message",
      Typing: "En train d'écrire",
      Online: "En ligne",
      "No message": "Aucun message",
      You: "Vous",
      "Auto Forecast": "Bulletin auto",
      Forecast: "Bulletin",
      Back: "Retour",
      HELP_CHANNELS:
        "Le système des canaux de réception, vous permet de collecter des données qualitatives des zones à risque de votre territoire. Le canal ouvert permet de sonder la population à travers un formulaire personnalisable.\n\nUne fois le canal de réception paramétré, il sera disponible uniquement pour les utilisateurs présents dans la zone géographique que vous aurez prédéfinis.",
      HELP_MARKETPLACE:
        "Ajoutez de nouvelles fonctionnalités à votre compte et gérer votre compte sur mesure. Les modules peuvent être acquis séparément, en fonction des besoins spécifiques de votre organisation.\n\nVous avez ainsi la flexibilité de choisir les modules qui correspondent le mieux à vos objectifs et de personnaliser votre licence AlerteCata en conséquence.",
      HELP_PARTNERS:
        "La page des partenariats permet à votre compte de partager des informations vers d'autres institutions. Depuis cette page vous pouvez voir l'ensemble des partenariats disponibles sur la plateforme AlerteCata.\n\nEn cliquant sur le bouton placé en bas de chaque partenaire vous pouvez facilement ajouter ou retirer le lien de partenariat avec votre compte.",
      HELP_NOTIFICATIONS:
        "Diffusez des notifictions PUSH aux utilisateurs de la plateforme. Une notification push est un message envoyé qui s'affiche sur un smartphone.\n\nDepuis cette interface vous pourrez en envoyer à tout moment. Les utilisateurs n'ont pas besoin d'ouvrir l'application ou d'être en train d'utiliser leur appareil pour les recevoir.",
      "News updated": "Article mis à jour.",
      "No affected": "Acune affectation",
      Owner: "Propriétaire",
      "Manage Hazards": "Gestion des risques",
      "Youtube search": "Recherche Youtube",
    },
  },
  de: {
    translation: {
      Search: "Suchen…",
      "Welcome back": "Willkommen zurück",
    },
  },
  nl: {
    translation: {
      Search: "Zoeken…",
      "Welcome back": "Welkom terug",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "fr",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
