import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Card,
  Row,
  Col,
  Image,
  Form,
  Alert,
  Button,
} from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "../../utils/axios";
import GoogleMapReact from "google-map-react";

function Map({ values, user }) {
  const { t } = useTranslation();
  const [markers, setMarkers] = useState([]);
  const [points, setPoints] = useState([{ lat: 0.0, lng: 0.0 }]);
  const [currentPoint, setCurrentPoint] = useState(0);
  const [mapData, setMapData] = useState(null);
  const [formType, setFormType] = useState("point");
  const [itemMap, setItemMap] = useState({
    title: "",
    legend: "",
    latitude: 0,
    longitude: 0,
    geo: points,
    icon: "",
    type: "point",
    submit: false,
    action: "insert",
    postId: values.postId,
  });

  const Marker = ({ title, legend, content, icon, lat, lng, postId }) => (
    <Container
      onClick={() => {
        setItemMap({
          title: title,
          legend: legend,
          content: content,
          latitude: lat,
          longitude: lng,
          geo: points,
          icon: icon,
          type: "point",
          submit: false,
          action: "update",
          lat: lat,
          lng: lng,
          postId: postId,
        });
        setFormType("point");
      }}
    >
      <Row>
        <Col>
          <Image
            style={{ width: "30px", height: "30px" }}
            src={`https://maps.google.com/mapfiles/ms/micons/${icon}.png`}
          />
        </Col>
      </Row>
    </Container>
  );

  useEffect(() => {
    if (values["Zones.HazardsZones.plan"] !== null) {
      var data = JSON.parse(values["Zones.HazardsZones.plan"]);
      setMapData(data);
      var d = [];
      if (data.markers !== null && typeof data.markers !== "undefined") {
        data.markers.map((item, index) => {
          var newy = (
            <Marker
              key={index}
              lat={item.geo.latitude}
              lng={item.geo.longitude}
              title={item.title}
              legend={item.legend}
              content={item.content}
              icon={item.icon}
              postId={values.postId}
            />
          );

          d = [...d, newy];
          return newy;
        });
      }

      setMarkers(d);
    } else {
      setMapData({ markers: [] });
    }
  }, [values]);

  const getMapOptions = (maps) => {
    return {
      fullscreenControl: true,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      scrollwheel: false,
      streetViewControl: true,
    };
  };

  const setGeo = (marker) => {
    var a = itemMap;
    var g = a.geo;
    g[currentPoint] = {
      lat: marker.getPosition().lat(),
      lng: marker.getPosition().lng(),
    };
    console.log(currentPoint);
    console.log(g[currentPoint]);
    console.log(itemMap.geo);
  };

  const loadMap = (map, maps) => {
    const marker = new maps.Marker({
      position: new maps.LatLng(16.240204807607356, -61.53768946560625),
      map,
      draggable: true,
    });

    marker.addListener("dragend", () => {
      setGeo(marker);

      ///////////////////////////
      setItemMap({
        title: "",
        legend: "",
        content: "",
        latitude: marker.getPosition().lat(),
        longitude: marker.getPosition().lng(),
        geo: points,
        icon: "",
        type: "point",
        submit: false,
        action: "insert",
        postId: values.postId,
      });
    });
  };

  const iconList = [
    "bus",
    "water",
    "campfire",
    "campground",
    "caution",
    "convienancestore",
    "earthquake",
    "fallingrocks",
    "ferry",
    "firedept",
    "flag",
    "gas",
    "grocerystore",
    "helicopter",
    "hiker",
    "homegardenbusiness",
    "hospitals",
    "info",
    "info_circle",
    "lodging",
    "man",
    "marina",
    "parkinglot",
    "partly_cloudy",
    "phone",
    "picnic",
    "plane",
    "POI",
    "police",
    "rainy",
    "rangerstation",
    "recycle",
    "restaurant",
    "sailing",
    "shopping",
    "snack_bar",
    "sunny",
    "swimming",
    "toilets",
    "tree",
    "truck",
    "volcano",
    "yellow",
    "yellow-dot",
    "blue",
    "blue-dot",
    "green",
    "green-dot",
    "lightblue",
    "ltblue-dot",
    "orange",
    "orange-dot",
    "pink",
    "pink-dot",
    "purple",
    "purple-dot",
    "red",
    "red-dot",
  ];

  const handleRemove = () => {
    let s = mapData.markers.findIndex(
      (e) =>
        e.geo.latitude === itemMap.latitude &&
        e.geo.longitude === itemMap.longitude
    );

    let del = mapData.markers;
    del.splice(s, s + 1);
    var replace = mapData;
    replace.markers = del;
    setMapData(replace);

    let replaceString = JSON.stringify(replace);
    let p = { postId: values.postId, plan: replaceString };

    axios
      .put(`/news/update`, p)
      .then((response) => {
        init();
      })
      .catch((error) => console.log(error));
  };

  const init = (type = "point") => {
    setItemMap({
      title: "",
      legend: "",
      content: "",
      latitude: 0,
      longitude: 0,
      geo: points,
      icon: "",
      type: type,
      submit: false,
      action: "insert",
      postId: values.postId,
    });
  };

  const FormMarker = () => {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={itemMap}
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .min(3, t("less_char", { n: 3 }))
            .max(60)
            .required("Title is required"),
          latitude: Yup.number().max(200),
          longitude: Yup.number().max(200),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const params = {
              title: values.title,
              legend: values.legend,
              content: values.content,
              latitude: values.latitude,
              longitude: values.longitude,
              icon: values.icon,
              type: values.type,
              action: values.action,
              postId: values.postId,
            };

            if (params.action === "insert") {
              if (params.type === "point") {
                let insert = [
                  ...mapData.markers,
                  {
                    title: params.title,
                    legend: params.legend,
                    type: params.type,
                    icon: params.icon,
                    geo: {
                      latitude: params.latitude,
                      longitude: params.longitude,
                    },
                    content: params.content,
                  },
                ];

                var replace = mapData;
                replace.markers = insert;
                setMapData(replace);
              }

              params.postId = values.postId;
              let replaceString = JSON.stringify(replace);
              let p = { postId: params.postId, plan: replaceString };

              axios
                .put(`/news/update`, p)
                .then((response) => {
                  init();
                })
                .catch((error) => console.log(error));
            } else {
              console.log("ok");

              /*axios
                .put(`/news/postVideo/update`, params)
                .then((response) => {
                })
                .catch((error) => console.log(error));*/
            }
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit} className="row">
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                {errors.submit}
              </Alert>
            )}
            <Form.Group className="mb-3 col-lg-6 col-md-12">
              <Form.Label>{t("Title")}</Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder={t("Title")}
                value={values.title}
                disabled={values.sended ? true : false}
                isInvalid={Boolean(touched.title && errors.title)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.title && (
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3 col-lg-6 col-md-12">
              <Form.Label>{t("Legend")}</Form.Label>
              <Form.Control
                type="text"
                name="legend"
                placeholder={t("Legend")}
                value={values.legend}
                disabled={values.sended ? true : false}
                isInvalid={Boolean(touched.legend && errors.legend)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.legend && (
                <Form.Control.Feedback type="invalid">
                  {errors.legend}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3 col-lg-6 col-md-6">
              <Form.Label>{t("Latitude")}</Form.Label>
              <Form.Control
                type="number"
                name="latitude"
                placeholder={t("Latitude")}
                value={values.latitude}
                disabled={values.sended ? true : false}
                isInvalid={Boolean(touched.latitude && errors.latitude)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.latitude && (
                <Form.Control.Feedback type="invalid">
                  {errors.latitude}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3 col-lg-6 col-md-6">
              <Form.Label>{t("Longitude")}</Form.Label>
              <Form.Control
                type="number"
                name="longitude"
                placeholder={t("Longitude")}
                value={values.longitude}
                disabled={values.sended ? true : false}
                isInvalid={Boolean(touched.longitude && errors.longitude)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.longitude && (
                <Form.Control.Feedback type="invalid">
                  {errors.longitude}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3 col-lg-2 col-md-6">
              <Form.Label>{t("Icon")}</Form.Label>
              <Form.Control
                as="select"
                name="icon"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.icon}
                aria-label="Default select example"
                disabled={values.sended ? true : false}
              >
                <option>{t("Open this select menu")}</option>
                {iconList.map((icon, index) => {
                  return (
                    <option value={icon} key={index}>
                      {icon}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group className="col-lg-1 col-md-6 d-flex align-items-center">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isSubmitting}
              >
                {itemMap.action === "insert" ? t("Add") : t("Edit")}
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    );
  };

  const FormLine = () => {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={itemMap}
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .min(3, t("less_char", { n: 3 }))
            .max(60)
            .required("Title is required"),
          latitude: Yup.number().max(200),
          longitude: Yup.number().max(200),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const params = {
              title: values.title,
              legend: values.legend,
              content: values.content,
              latitude: values.latitude,
              longitude: values.longitude,
              icon: values.icon,
              type: values.type,
              action: values.action,
              postId: values.postId,
            };

            if (params.action === "insert") {
              if (params.type === "point") {
                let insert = [
                  ...mapData.markers,
                  {
                    title: params.title,
                    legend: params.legend,
                    type: params.type,
                    icon: params.icon,
                    geo: {
                      latitude: params.latitude,
                      longitude: params.longitude,
                    },
                    content: params.content,
                  },
                ];

                var replace = mapData;
                replace.markers = insert;
                setMapData(replace);
              }

              params.postId = values.postId;
              let replaceString = JSON.stringify(replace);
              let p = { postId: params.postId, plan: replaceString };

              axios
                .put(`/news/update`, p)
                .then((response) => {
                  init();
                })
                .catch((error) => console.log(error));
            } else {
              console.log("ok");

              /*axios
                .put(`/news/postVideo/update`, params)
                .then((response) => {
                })
                .catch((error) => console.log(error));*/
            }
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit} className="row">
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                {errors.submit}
              </Alert>
            )}
            <Form.Group className="mb-3 col-lg-6 col-md-12">
              <Form.Label>{t("Title")}</Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder={t("Title")}
                value={values.title}
                disabled={values.sended ? true : false}
                isInvalid={Boolean(touched.title && errors.title)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.title && (
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3 col-lg-6 col-md-12">
              <Form.Label>{t("Legend")}</Form.Label>
              <Form.Control
                type="text"
                name="legend"
                placeholder={t("Legend")}
                value={values.legend}
                disabled={values.sended ? true : false}
                isInvalid={Boolean(touched.legend && errors.legend)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.legend && (
                <Form.Control.Feedback type="invalid">
                  {errors.legend}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="col-lg-1 col-md-1 d-flex align-items-center">
              <Button
                onClick={() => {
                  setPoints([...points, { lat: 0.0, lng: 0.0 }]);
                }}
                type="button"
                variant="primary"
                size="sm"
                disabled={isSubmitting}
              >
                {t("Add")}
              </Button>
            </Form.Group>

            {points.map((point, index) => {
              return PointInput(
                point.lat,
                point.lng,
                handleBlur,
                handleChange,
                touched,
                errors,
                isSubmitting,
                index
              );
            })}
          </Form>
        )}
      </Formik>
    );
  };

  const PointInput = (
    lat,
    lng,
    handleBlur,
    handleChange,
    touched,
    errors,
    isSubmitting,
    index
  ) => {
    return (
      <Row key={index}>
        <Form.Group className="mb-3 col-lg-5 col-md-5">
          <Form.Label>{t("Latitude")}</Form.Label>
          <Form.Control
            type="number"
            name={`latitude${index}`}
            placeholder={t("Latitude")}
            value={lat}
            disabled={values.sended ? true : false}
            isInvalid={Boolean(touched.latitude && errors.latitude)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {!!touched.latitude && (
            <Form.Control.Feedback type="invalid">
              {errors.latitude}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="mb-3 col-lg-5 col-md-5">
          <Form.Label>{t("Longitude")}</Form.Label>
          <Form.Control
            type="number"
            name={`longitude${index}`}
            placeholder={t("Longitude")}
            value={lng}
            disabled={values.sended ? true : false}
            isInvalid={Boolean(touched.longitude && errors.longitude)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {!!touched.longitude && (
            <Form.Control.Feedback type="invalid">
              {errors.longitude}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="col-lg-1 col-md-1 d-flex align-items-center">
          <Button
            onClick={() => setCurrentPoint(index)}
            type="button"
            variant="primary"
            size="sm"
            disabled={isSubmitting}
          >
            {t("Edit")}
          </Button>
        </Form.Group>
        {index !== 0 ? (
          <Form.Group className="col-lg-1 col-md-1 d-flex align-items-center">
            <Button
              onClick={() => {
                points.splice(index, 1);
                //setPoints(newPoints);
              }}
              type="submit"
              variant="primary"
              size="sm"
              disabled={isSubmitting}
            >
              {t("Delete")}
            </Button>
          </Form.Group>
        ) : null}
      </Row>
    );
  };

  const FormCircle = () => {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={itemMap}
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .min(3, t("less_char", { n: 3 }))
            .max(60)
            .required("Title is required"),
          latitude: Yup.number().max(200),
          longitude: Yup.number().max(200),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const params = {
              title: values.title,
              legend: values.legend,
              content: values.content,
              latitude: values.latitude,
              longitude: values.longitude,
              icon: values.icon,
              type: values.type,
              action: values.action,
              postId: values.postId,
            };

            if (params.action === "insert") {
              if (params.type === "point") {
                let insert = [
                  ...mapData.markers,
                  {
                    title: params.title,
                    legend: params.legend,
                    type: params.type,
                    icon: params.icon,
                    geo: {
                      latitude: params.latitude,
                      longitude: params.longitude,
                    },
                    content: params.content,
                  },
                ];

                var replace = mapData;
                replace.markers = insert;
                setMapData(replace);
              }

              params.postId = values.postId;
              let replaceString = JSON.stringify(replace);
              let p = { postId: params.postId, plan: replaceString };

              axios
                .put(`/news/update`, p)
                .then((response) => {
                  init();
                })
                .catch((error) => console.log(error));
            } else {
              console.log("ok");

              /*axios
                .put(`/news/postVideo/update`, params)
                .then((response) => {
                })
                .catch((error) => console.log(error));*/
            }
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit} className="row">
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                {errors.submit}
              </Alert>
            )}
            <Form.Group className="mb-3 col-lg-6 col-md-12">
              <Form.Label>{t("Title")}</Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder={t("Title")}
                value={values.title}
                disabled={values.sended ? true : false}
                isInvalid={Boolean(touched.title && errors.title)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.title && (
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3 col-lg-6 col-md-12">
              <Form.Label>{t("Legend")}</Form.Label>
              <Form.Control
                type="text"
                name="legend"
                placeholder={t("Legend")}
                value={values.legend}
                disabled={values.sended ? true : false}
                isInvalid={Boolean(touched.legend && errors.legend)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.legend && (
                <Form.Control.Feedback type="invalid">
                  {errors.legend}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3 col-lg-5 col-md-5">
              <Form.Label>{t("Latitude")}</Form.Label>
              <Form.Control
                type="number"
                name="latitude"
                placeholder={t("Latitude")}
                value={values.latitude}
                disabled={values.sended ? true : false}
                isInvalid={Boolean(touched.latitude && errors.latitude)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.latitude && (
                <Form.Control.Feedback type="invalid">
                  {errors.latitude}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3 col-lg-5 col-md-5">
              <Form.Label>{t("Longitude")}</Form.Label>
              <Form.Control
                type="number"
                name="longitude"
                placeholder={t("Longitude")}
                value={values.longitude}
                disabled={values.sended ? true : false}
                isInvalid={Boolean(touched.longitude && errors.longitude)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.longitude && (
                <Form.Control.Feedback type="invalid">
                  {errors.longitude}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3 col-md-2">
              <Form.Label>{t("Radius")}</Form.Label>
              <Form.Control
                type="number"
                name="radius"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.radius}
                aria-label="Default select example"
                disabled={values.sended ? true : false}
              />
            </Form.Group>
            <Form.Group className="mb-3 col-lg-1 col-md-1">
              <Form.Label>{t("Color")}</Form.Label>
              <Form.Control
                name="fillColor"
                type="color"
                id="exampleColorInput"
                defaultValue="#563d7c"
                title="Choose your color"
                disabled={values.sended ? true : false}
                isInvalid={Boolean(touched.longitude && errors.longitude)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3 col-md-1">
              <Form.Label>{t("Opacity")}</Form.Label>
              <Form.Control
                as="select"
                name="active"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.active}
                aria-label="Default select example"
                disabled={values.sended ? true : false}
              >
                {[0, 10, 20, 30, 40, 50, 60, 70, 80, 100].map((op, index) => {
                  return (
                    <option value={op} key={index}>
                      {t(op)}%
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 col-lg-1 col-md-1">
              <Form.Label>{t("Bordure")}</Form.Label>
              <Form.Control
                name="fillColor"
                type="color"
                id="exampleColorInput"
                defaultValue="#563d7c"
                title="Choose your color"
                disabled={values.sended ? true : false}
                isInvalid={Boolean(touched.longitude && errors.longitude)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3 col-md-1">
              <Form.Label>{t("Size")}</Form.Label>
              <Form.Control
                type="number"
                name="borderSize"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.size}
                aria-label="Default select example"
                disabled={values.sended ? true : false}
              />
            </Form.Group>
            <Form.Group className="col-lg-1 col-md-6 d-flex align-items-center">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isSubmitting}
              >
                {itemMap.action === "insert" ? t("Add") : t("Edit")}
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title>{t("Map Overview")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <h3>{t("Form")}</h3>
            <div className="d-flex align-items-start align-middle">
              {t("New")}
            </div>
            <Button
              className="mt-1 mb-3 m-1"
              type="submit"
              variant="primary"
              size="sm"
              disabled={
                itemMap.action === "update" || formType !== "point"
                  ? false
                  : true
              }
              onClick={() => {
                init("point");
                setFormType("point");
              }}
            >
              {t("Marker")}
            </Button>
            <Button
              className="mt-1 mb-3 m-1"
              type="submit"
              variant="primary"
              size="sm"
              disabled={
                itemMap.action === "update" || formType !== "line"
                  ? false
                  : true
              }
              onClick={() => {
                init("line");
                setFormType("line");
              }}
            >
              {t("Line")}
            </Button>
            <Button
              className="mt-1 mb-3 m-1"
              type="submit"
              variant="primary"
              size="sm"
              disabled={
                itemMap.action === "update" || formType !== "circle"
                  ? false
                  : true
              }
              onClick={() => {
                init("circle");
                setFormType("circle");
              }}
            >
              {t("Circle")}
            </Button>
            <Button
              className="mt-1 mb-3 m-1"
              type="submit"
              variant="primary"
              size="sm"
              disabled={
                itemMap.action === "update" || formType !== "polygon"
                  ? false
                  : true
              }
              onClick={() => {
                init("polygon");
                setFormType("polygon");
              }}
            >
              {t("Polygon")}
            </Button>
            {itemMap.action === "update" ? (
              <Button
                className="mt-1 mb-3 m-3"
                type="submit"
                variant="danger"
                size="sm"
                disabled={itemMap.action === "update" ? false : true}
                onClick={() => handleRemove()}
              >
                {t("Delete")}
              </Button>
            ) : null}
            {formType === "polygon" ? (
              <FormMarker />
            ) : formType === "circle" ? (
              <FormCircle />
            ) : formType === "line" ? (
              <FormLine />
            ) : (
              <FormMarker />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ height: 500, width: "100%" }}>
              <GoogleMapReact
                options={getMapOptions}
                bootstrapURLKeys={{
                  key: "AIzaSyCF7Np6MtgOFp8Yp8RqYyXAOwOw_YOLns4",
                  language: "fr",
                  region: "fr",
                  libraries: ["visualization"],
                }}
                onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
                defaultCenter={{
                  lat: 16.240204807607356,
                  lng: -61.53768946560625,
                }}
                defaultZoom={14}
                yesIWantToUseGoogleMapApiInternals
              >
                {markers}
              </GoogleMapReact>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default Map;
