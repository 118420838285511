import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  /*Badge,Dropdown,*/
  Card,
  Table,
  Modal,
  Button,
} from "react-bootstrap";
/*import { MoreHorizontal } from "react-feather";*/
import { useTable } from "react-table";
import useAuth from "../../hooks/useAuth";
import axios from "../../utils/axios";
import EditForm from "./EditForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

const Models = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [lastReports, setLastReports] = useState([]);
  const [dataResponse, setDataResponse] = useState([]);

  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [fnConfirm, setFnConfirm] = useState(null);

  useEffect(() => {
    if (typeof user.customerId !== "undefined" && !show) {
      axios
        .get(`/notifications/getModels/${user.customerId}`)
        .then((response) => {
          var d = [];
          response.data.data.map((item) => {
            var newy = {
              modelId: item.modelId,
              colorIcon: (
                <FontAwesomeIcon
                  icon={faBell}
                  className={`color: ${item.color}`}
                  size="3x"
                  color={item.color}
                />
              ),
              title: item.title,
              message: item.message,
              sound: item.sound ? t("Yes") : t("No"),
            };
            d = [...d, newy];
            return newy;
          });
          setLastReports(d);
          setDataResponse(response.data.data);
        })
        .catch((error) => console.log(error));
    }
  }, [user, show, t]);

  const data = React.useMemo(() => lastReports, [lastReports]);

  const columns = React.useMemo(
    () => [
      {
        Header: t("#"),
        accessor: "modelId",
      },
      {
        Header: t("Color"),
        accessor: "colorIcon", // accessor is the "key" in the data
      },
      {
        Header: t("Title"),
        accessor: "title",
      },
      {
        Header: t("Message"),
        accessor: "message",
      },
      {
        Header: t("Sound"),
        accessor: "sound",
      },
    ],
    [t]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const [selectRowValues, setSelectedRowValues] = useState([]);
  const handleShow = (modelId) => {
    let values = dataResponse.filter((item) => item.modelId === modelId);
    setSelectedRowValues(values[0]);
    setShow(true);
  };

  const handleClose = () => setShow(false);
  const handleCancel = () => setShowConfirm(false);
  const handleCreate = () => {
    setSelectedRowValues([]);
    setShow(true);
  };

  const handleRemove = (modelId) => {
    axios
      .delete(`/notifications/trash/model/${modelId}`)
      .then((response) => {
        console.log(response);
        setShow(false);
      })
      .catch((error) => console.log(error));
  };

  const ModalView = ({ show, handleClose, setShowConfirm }) => {
    return (
      <Modal
        show={show}
        onHide={() => handleClose()}
        size="xl"
        style={{ zIndex: 1053, overlay: { zIndex: 1054 } }}
      >
        <Modal.Header closeButton>
          {Object.keys(selectRowValues).length > 0 ? t("Edit") : t("New")}
        </Modal.Header>
        <Modal.Body className="m-3">
          <EditForm
            values={selectRowValues}
            user={user}
            setShow={setShow}
            type="model"
          />
        </Modal.Body>
        <Modal.Footer>
          {Object.keys(selectRowValues).length > 0 ? (
            <Button
              onClick={() => {
                setShowConfirm(true);
                setFnConfirm("remove");
              }}
              variant="danger"
              className=" rounded"
            >
              <FontAwesomeIcon icon={faTrashAlt} fixedWidth /> {t("Delete")}
            </Button>
          ) : null}
          <Button variant="secondary" onClick={() => handleClose()}>
            {t("Close")}
          </Button>
        </Modal.Footer>
        <ConfirmationView handleCancel={handleCancel} />
      </Modal>
    );
  };

  const ConfirmationView = ({ handleCancel }) => {
    return (
      <Modal
        show={showConfirm}
        onHide={() => handleCancel()}
        centered
        /*style={{ zIndex: 1056, overlay: { zIndex: 1055 } }}*/
      >
        <Modal.Header closeButton>{t("Confirmation")}</Modal.Header>
        <Modal.Body className="m-3">{t("Are you sure?")}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleCancel()}>
            {t("Cancel")}
          </Button>

          <Button
            variant="success"
            onClick={() => {
              switch (fnConfirm) {
                case "remove":
                  handleRemove(selectRowValues.modelId);
                  break;

                default:
                  break;
              }
              setShowConfirm(false);
            }}
          >
            {t("Yes")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div>
      <Button className="mb-3" variant="primary" onClick={() => handleCreate()}>
        {t("New")}
      </Button>

      <Card className="flex-fill w-100">
        <Card.Header>
          {/*<div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleShow()}>Action</Dropdown.Item>
              <Dropdown.Item>Another Action</Dropdown.Item>
              <Dropdown.Item>Something else here</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>*/}
          <Card.Title className="mb-0">{t("Models")}</Card.Title>
        </Card.Header>
        <Table
          className="my-0"
          striped
          bordered
          hover
          size="sm"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        onClick={() => handleShow(cell.row.values.modelId)}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <ModalView
          show={show}
          handleClose={handleClose}
          setShowConfirm={setShowConfirm}
          setFnConfirm={setFnConfirm}
        />
      </Card>
    </div>
  );
};

export default Models;
