import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Card, Row } from "react-bootstrap";
import { Clock, Users } from "react-feather";
import {
  BsFiletypeXlsx,
  BsFiletypePdf,
  BsFiletypeCsv,
  BsPrinter,
} from "react-icons/bs";

import illustration from "../../../assets/img/illustrations/searching.png";
import ReactToPrint from "react-to-print";

import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

//import { PDFDownloadLink, Document, Page, Text } from "@react-pdf/renderer";

import moment from "moment";
require("moment/locale/fr");

const Statistics = ({ data, printComponentRef, surveys }) => {
  const { t } = useTranslation();
  const [reports] = useState(data.Reports !== null ? data.Reports : []);
  const [channel] = useState(data.Reports !== null ? data : []);

  const exportToCSV = (data2Csv) => {
    // Créer un nouveau classeur
    const workbook = XLSX.utils.book_new();

    // Créer une nouvelle feuille de calcul
    const worksheet = XLSX.utils.aoa_to_sheet(data2Csv);

    // Ajouter la feuille de calcul au classeur
    XLSX.utils.book_append_sheet(workbook, worksheet, "Feuille1");

    // Générer le fichier CSV
    const csvData = XLSX.utils.sheet_to_csv(worksheet);

    // Télécharger le fichier CSV
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const filename = `${data.title
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .replace(/ /g, "-")}_alertecata.csv`;
    if (navigator.msSaveBlob) {
      // Pour Internet Explorer
      navigator.msSaveBlob(blob, filename);
    } else {
      // Pour les autres navigateurs
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const exportToExcel = (data2xlxs) => {
    const worksheet = XLSX.utils.json_to_sheet(data2xlxs);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Feuille 1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const fileData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(
      fileData,
      `${data.title
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .replace(/ /g, "-")}_alertecata.xlsx`
    );
  };

  const handleExportXlsx = () => {
    const transformedArray = surveys.reduce((result, subArray) => {
      const obj = subArray.reduce((acc, item) => {
        acc[item.key] = item.value;
        return acc;
      }, {});

      result.push(obj);
      return result;
    }, []);

    exportToExcel(transformedArray);
  };

  const handleExportCSV = () => {
    // Obtenir l'ordre des clés
    const keyOrder = Array.from(
      new Set(surveys.flatMap((subArray) => subArray.map((item) => item.key)))
    );

    const transformedArray = surveys.reduce((result, subArray) => {
      const tempArray = keyOrder.map((key) => {
        const foundItem = subArray.find((item) => item.key === key);
        return foundItem ? foundItem.value : "";
      });
      result.push(tempArray);
      return result;
    }, []);

    // Insérer l'ordre des clés comme première ligne dans le tableau résultant
    transformedArray.unshift(keyOrder);
    exportToCSV(transformedArray);
  };

  /*const handleExportPDF = () => {
    const blob = new Blob([<MyPDF />], { type: "application/pdf" });
    saveAs(
      blob,
      `${data.title
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .replace(/ /g, "-")}_alertecata.pdf`
    );
  };

  const MyPDF = () => (
    <Document>
      <Page>{printComponentRef.current}</Page>
    </Document>
  );*/

  return (
    <div className="w-100">
      <Row>
        <Col sm="12" md="12" lg="4" className="d-flex col-xxl-4">
          <Card className="illustration flex-fill">
            <Card.Body className="p-0 d-flex flex-fill">
              <Row className="row g-0 w-100">
                <Col xs="7">
                  <div className="illustration-text p-3 m-1">
                    <h4 className="illustration-text">
                      {t("Interface statisque AlerteCata")}
                    </h4>
                    <p className="mb-0">{t("Extract data")}</p>
                    <p className="d-flex justify-content-between mt-2">
                      <BsFiletypeXlsx
                        size="25"
                        color="#1d6f42"
                        onClick={() => handleExportXlsx()}
                      />
                      <ReactToPrint
                        trigger={() => (
                          <BsFiletypePdf size="25" color="#FA0F00" />
                        )}
                        content={() => printComponentRef.current}
                      />
                      <BsFiletypeCsv
                        size="25"
                        color="#000"
                        onClick={() => handleExportCSV()}
                      />
                      <ReactToPrint
                        trigger={() => <BsPrinter size="25" color="#000" />}
                        content={() => printComponentRef.current}
                      />
                    </p>
                  </div>
                </Col>
                <Col xs="5" className="align-self-end text-end">
                  <img
                    src={illustration}
                    alt="Searching"
                    className="img-fluid illustration-img"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" md="6" lg="4" className="d-flex col-xxl-4">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("Period")}</h5>
                </Col>

                <Col xs="auto">
                  <div className="stat stat-sm">
                    <Clock className="align-middle text-success" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="mt-0 text-sm">{t("Start Date")}</Col>
              </Row>
              <Row>
                <Col className="mt-0">
                  <span className="fw-bold d-inline-block ">
                    {moment(channel.dateStart, "YYYY-MM-DD HH:mm:ss").format(
                      "lll"
                    )}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col className="mt-0 text-sm">{t("End Date")}</Col>
              </Row>
              <Row>
                <Col className="mt-0">
                  <span className="fw-bold d-inline-block">
                    {moment(channel.dateEnd, "YYYY-MM-DD HH:mm:ss").format(
                      "lll"
                    )}
                  </span>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" md="6" lg="4" className="d-flex col-xxl-4">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("Sample size")}</h5>
                </Col>

                <Col xs="auto">
                  <div className="stat stat-sm">
                    <Users className="align-middle text-success" />
                  </div>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-4">
                {reports.length}
              </span>
              <div className="mb-0">
                <span className="text-muted">
                  {reports.length > 0
                    ? `${t("Since")} ${moment(
                        reports[0].generatedAt,
                        "YYYY-MM-DD HH:mm:ss"
                      ).format("lll")}`
                    : null}
                </span>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Statistics;
