import { createContext, useEffect, useReducer } from "react";

import axiosL from "axios";
import { isValidToken, setSession } from "../utils/jwt";
//import jwtDecode from "jwt-decode";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const CHANGE_CUSTOMER = "CHANGE_CUSTOMER";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const config = {
  baseURL: "https://pwenter.io/v1.1/web", //process.env.REACT_APP_API_URL,
  responseType: "json",
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
};
const accessToken = window.localStorage.getItem("accessToken");

if (accessToken && isValidToken(accessToken)) {
  config.headers.authorization = `Bearer ${accessToken}`;
}

const axios = axiosL.create(config);

//axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

axios.interceptors.request.use((request) => {
  //console.log(request);
  return request;
});

axios.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data && error.response.data.message) ||
        "Something went wrong"
    )
);

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    case CHANGE_CUSTOMER:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get("/auth/my-account");
          const { user } = response.data;
          window.localStorage.setItem("id", user.userId);
          window.localStorage.setItem("name", user.firstname);

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (email, password, rememberMe) => {
    const response = await axios.post("/auth/sign-in", {
      email,
      password,
    });
    const { accessToken, user } = response.data;

    setSession(accessToken);
    if (rememberMe) {
      window.localStorage.setItem("id", user.userId);
      window.localStorage.setItem("name", user.firstname);
    } else {
      window.localStorage.removeItem("id");
      window.localStorage.removeItem("name");
    }
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  const changeCustomer = async (userId, customerId) => {
    const response = await axios.post("/auth/change-customer", {
      userId,
      customerId,
    });
    const { accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: CHANGE_CUSTOMER,
      payload: {
        user,
      },
    });
  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (email, password, firstName, lastName) => {
    const response = await axios.post("/api/auth/sign-up", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    window.localStorage.setItem("ids", [user.firstname, user.userId]);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        user: {
          displayName:
            `${state?.user?.firstname} ${state?.user?.lastname}` || "Undefined",
          role: "user",
          ...state.user,
        },
        signIn,
        signOut,
        signUp,
        resetPassword,
        changeCustomer,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
