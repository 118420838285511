import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "../../utils/axios";
import { Dropdown } from "react-bootstrap";
import { ChevronRight } from "react-feather";

const SelectCustomerId = ({ values, user, handleBlur, handleChange }) => {
  const { t } = useTranslation();
  const [customerOptions, setCustomerOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  useEffect(() => {
    if (typeof user.customerId !== "undefined") {
      setLoading(true);
      setError(false);
      axios
        .get(`/tools/hazardszones/${values.hazardId}`)
        .then((response) => {
          setCustomerOptions(response.data.data);
          const currentCustomer = response.data.data.find(
            ({ Hazards }) =>
              Hazards[0].HazardsZones.hazardZoneId === values.hazardZoneId
          );

          setSelectedCustomer(currentCustomer || null);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user, values.hazardZoneId, values.hazardId]);

  // Fonction récursive pour afficher les sous-dropdowns
  const renderDropdownItems = (zones) => {
    return zones.map(({ name, Hazards }, index) => (
      <Dropdown key={index} as="div" className="w-100" align="end">
        {Hazards &&
          Hazards.length > 0 &&
          Hazards.map((hazard) => (
            <Dropdown.Item
              key={hazard.HazardsZones.hazardZoneId}
              as="button"
              type="button"
              className="text-start"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation(); // Empêche la fermeture automatique
                setSelectedCustomer(hazard);
                handleChange({
                  target: {
                    name: "hazardZoneId",
                    value: hazard.HazardsZones.hazardZoneId,
                  },
                });
              }}
            >
              {hazard.HazardsZones.hazardZoneId !== undefined && (
                <ChevronRight className="align-self-center feather-sm" />
              )}{" "}
              {t(name)}
            </Dropdown.Item>
          ))}
      </Dropdown>
    ));
  };

  return (
    <Dropdown className="me-2 nav-item w-100" align="end">
      <Dropdown.Toggle
        as="button"
        type="button"
        className="btn btn-outline-primary text-truncate d-flex align-items-center justify-content-between pe-4"
      >
        {loading
          ? t("Loading...")
          : error
          ? t("Error loading customers")
          : selectedCustomer
          ? t(selectedCustomer.name)
          : t("Select a customer")}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {loading ? (
          <Dropdown.ItemText>{t("Loading...")}</Dropdown.ItemText>
        ) : error ? (
          <Dropdown.ItemText>{t("Error loading customers")}</Dropdown.ItemText>
        ) : (
          renderDropdownItems(customerOptions)
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectCustomerId;
