import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "../../utils/axios";
import { Form } from "react-bootstrap";

const SelectZoneId = ({
  values,
  zoneIds2Select,
  setPath,
  handleBlur,
  handleChange,
}) => {
  const { t } = useTranslation();

  const [select, setSelect] = useState(<></>);

  useEffect(() => {
    const getPolygon = (zoneId) => {
      axios
        .get(`/channels/getPolygon/${zoneId}`)
        .then((res) => {
          let multipolygon = res.data.data.multipolygon.coordinates;
          const transformedArray = multipolygon.map((polygon) =>
            polygon[0].map((coordinate) => ({
              lat: coordinate[1],
              lng: coordinate[0],
            }))
          );
          if (
            transformedArray &&
            transformedArray.length > 2 &&
            transformedArray[0].length > 0
          ) {
            //console.log(transformedArray.length);
            setPath(transformedArray);
          } else {
            //console.log(transformedArray.flat(1));
            setPath(
              transformedArray.flat(
                1
              ) /*.filter((_, index) => index % 2 === 0)*/
            );
          }

          //console.log(transformedArray.flat(1));
        })
        .catch((error) => console.log(error));
    };

    if (zoneIds2Select.length > 0) {
      setSelect(
        <>
          <Form.Select
            name="zoneId"
            onBlur={handleBlur}
            onChange={(e) => getPolygon(e.target.value)}
            defaultValue={values.zoneId}
            aria-label="Default select example"
            disabled={values.sended ? true : false}
          >
            <option>{t("Open this select menu")}</option>
            {zoneIds2Select.map((zone, index) => {
              return (
                <>
                  <option value={parseInt(zone.zoneId)} key={`z-${index}`}>
                    {zone.fullName !== "" ? zone.fullName : zone.name}
                  </option>

                  {zone.subZones.map((subZone, index1) => {
                    return (
                      <>
                        <option
                          value={parseInt(subZone.zoneId)}
                          key={`z1-${index}-${index1}`}
                          className="p-2"
                        >
                          {`|__ 
                            ${
                              subZone.fullName !== ""
                                ? subZone.fullName
                                : subZone.name
                            }`}
                        </option>
                        {subZone.subZones.map((subZone2, index2) => {
                          return (
                            <option
                              value={parseInt(subZone2.zoneId)}
                              key={`z2-${index}-${index1}-${index2}`}
                              className="p-4"
                            >
                              {`|______ 
                            ${
                              subZone2.fullName !== ""
                                ? subZone2.fullName
                                : subZone2.name
                            }`}
                            </option>
                          );
                        })}
                      </>
                    );
                  })}
                </>
              );
            })}
          </Form.Select>
        </>
      );
    } else {
      setSelect(
        <Form.Select
          defaultValue={values.zoneId}
          aria-label="Default select example"
          disabled={values.sended ? true : false}
        >
          <option>{t("Open this select menu")}</option>
        </Form.Select>
      );
    }
  }, [zoneIds2Select, handleBlur, handleChange, t, values, setPath]);

  return select;
};

export default SelectZoneId;
