import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "../../utils/axios";
import { Form } from "react-bootstrap";

const SelectLevelId = ({ values, user, handleBlur, handleChange }) => {
  const { t } = useTranslation();

  const [select, setSelect] = useState(<></>);

  useEffect(() => {
    if (typeof user.levelId !== "undefined") {
      axios
        .get(`/community/getLevelsList`)
        .then((response) => {
          const levelsFiltered = response.data.data.filter(
            (item) => item.levelId <= user.levelId
          );
          setSelect(
            <Form.Select
              name="levelId"
              onBlur={handleBlur}
              onChange={handleChange}
              defaultValue={values.levelId}
              aria-label="Default select example"
              disabled={values.sended ? true : false}
            >
              <option>{t("Open this select menu")}</option>
              {levelsFiltered.map((level, index) => {
                return (
                  <option value={parseInt(level.levelId)} key={index}>
                    {t(level.title)}
                  </option>
                );
              })}
            </Form.Select>
          );
        })
        .catch((error) => {
          setSelect(
            <Form.Select
              defaultValue={values.levelId}
              aria-label="Default select example"
              disabled={values.sended ? true : false}
            >
              <option>{t("Open this select menu")}</option>
            </Form.Select>
          );
        });
    }
  }, [user, handleBlur, handleChange, t, values]);

  return select;
};

export default SelectLevelId;
