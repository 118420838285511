import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "../../utils/axios";
import SelectPostId from "./SelectPostId";
import ScopeForm from "./ScopeForm";
import PlaceModel from "./PlaceModel";
import moment from "moment";
require("moment/locale/fr");

function EditForm({ values, user, setSelectedRowValues, type }) {
  const { t } = useTranslation();

  var colorList = [
    { title: "Green", value: "#007914" },
    { title: "Yellow", value: "#FFCD00" },
    { title: "Orange", value: "#FF6C00" },
    { title: "Red", value: "#FF0000" },
    { title: "Purple", value: "#A600FF" },
    { title: "Gray", value: "#AEAEAE" },
    { title: "Blue", value: "#0093FF" },
    { title: "Scarlet", value: "#ED0000" },
    { title: "Black", value: "#000000" },
  ];

  var soundList = [
    { title: "Enabled", value: 1 },
    { title: "Mute", value: 0 },
  ];

  var stateList = [
    { title: "Enabled", value: 1 },
    { title: "Disabled", value: 0 },
  ];

  var categoryList = [];
  const [dataForm, setDataForm] = useState(null);

  useEffect(() => {
    if (Object.keys(values).length === 0) {
      setDataForm({
        notificationId: null,
        modelId: null,
        title: "",
        subtitle: "",
        message: "",
        color: "#000000",
        sound: 1,
        active: 1,
        postId: null,
        sended: null,
        submit: false,
        category: null,
      });
    } else {
      const postIdItem = values["Post.postId"];

      setDataForm({
        notificationId: values.notificationId,
        modelId: values.modelId,
        title: values.title,
        subtitle: values.subtitle,
        message: values.message,
        color: values.color,
        sound: values.sound,
        active: values.active,
        postId: parseInt(postIdItem),
        sended: values.sended,
        submit: false,
        category: values.category,
      });
    }
  }, [setDataForm, values]);

  return (
    <>
      {dataForm !== null ? (
        <Formik
          initialValues={dataForm}
          enableReinitialize
          validationSchema={Yup.object().shape({
            title: Yup.string()
              .min(12, t("less_char", { n: 12 }))
              .max(60)
              .required("Title is required"),
            subtitle: Yup.string().max(90),
            message: Yup.string()
              .min(20, t("less_char", { n: 20 }))
              .max(255)
              .required("Message is required"),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm }
          ) => {
            try {
              const params = {
                notificationId: values.notificationId,
                modelId: values.modelId,
                title: values.title,
                message: values.message,
                subtitle: values.subtitle,
                color: values.color,
                sound: values.sound,
                category: values.category,
                active: values.active,
                postId: values.postId,
                customerId: user.customerId,
              };

              let typeUrl = type === "model" ? "/model" : "";

              if (params.notificationId === null && params.modelId === null) {
                delete params.notificationId;
                delete params.modelId;
                axios
                  .put(`/notifications/insert${typeUrl}`, params)
                  .then((response) => {
                    setStatus({ success: true });
                    setErrors({
                      submit: response.data.message,
                      status: "success",
                    });

                    resetForm({
                      values: {
                        ...values,
                        notificationId: response.data.data.notificationId,
                      },
                    });

                    setSelectedRowValues({
                      notificationId: response.data.data.notificationId,
                      modelId: response.data.data.modelId,
                      title: response.data.data.title,
                      subtitle: response.data.data.subtitle,
                      message: response.data.data.message,
                      color: response.data.data.color,
                      sound: response.data.data.sound,
                      active: response.data.data.active,
                      postId: response.data.data.postId,
                      sended: null,
                      category: response.data.data.category,
                    });
                  })
                  .catch((error) => console.log(error));
              } else {
                axios
                  .put(`/notifications/update${typeUrl}`, params)
                  .then((response) => {
                    if (response.status === 200) {
                      setStatus({ success: true });
                      setErrors({
                        submit: response.data.message,
                        status: "success",
                      });
                      /*resetForm({
                        values: {
                          ...values,
                          sended: true
                        },
                      });*/

                      //moment().format("YYYY-MM-DD LTS")

                      setSelectedRowValues({
                        notificationId: params.notificationId,
                        modelId: params.modelId,
                        title: params.title,
                        subtitle: params.subtitle,
                        message: params.message,
                        color: params.color,
                        sound: params.sound,
                        active: params.active,
                        postId: params.postId,
                        sended: params.sended,
                        category: params.category,
                      });
                    }
                  })
                  .catch((error) => console.log(error));
              }
            } catch (error) {
              const message = error.message || "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            resetForm,
          }) => (
            <Form onSubmit={handleSubmit} className="row">
              {errors.submit && (
                <Alert
                  className="my-3 p-2"
                  variant={
                    typeof errors.status === "undefined"
                      ? "danger"
                      : errors.status
                  }
                >
                  {errors.submit}
                </Alert>
              )}

              {type === "notification" && values.sended === null ? (
                <Form.Group className="mb-3 col-md-12">
                  <Form.Label>{t("Pre-registered model")}</Form.Label>
                  <PlaceModel
                    values={values}
                    user={user}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setDataForm={setDataForm}
                    resetForm={resetForm}
                  />
                </Form.Group>
              ) : null}

              <Form.Group className="mb-3 col-md-6">
                <Form.Label>{t("Title")}</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  placeholder={t("Title")}
                  value={values.title}
                  disabled={values.sended ? true : false}
                  isInvalid={Boolean(touched.title && errors.title)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.title && (
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3 col-md-6">
                <Form.Label>{t("Subtitle")}</Form.Label>
                <Form.Control
                  type="text"
                  name="subtitle"
                  placeholder={t("Subtitle")}
                  value={values.subtitle}
                  disabled={values.sended ? true : false}
                  isInvalid={Boolean(touched.subtitle && errors.subtitle)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.subtitle && (
                  <Form.Control.Feedback type="invalid">
                    {errors.subtitle}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>{t("Message")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  placeholder={t("Message")}
                  value={values.message}
                  disabled={values.sended ? true : false}
                  isInvalid={Boolean(touched.message && errors.message)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.message && (
                  <Form.Control.Feedback type="invalid">
                    {errors.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              {type === "notification" ? (
                <Form.Group className="mb-3">
                  <Form.Label>{t("Post attached")}</Form.Label>
                  <SelectPostId
                    values={values}
                    user={user}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </Form.Group>
              ) : null}
              <Form.Group className="mb-3 col-md-3">
                <Form.Label>{t("Color")}</Form.Label>
                <Form.Select
                  name="color"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.color}
                  aria-label="Default select example"
                  disabled={values.sended ? true : false}
                >
                  <option>{t("Open this select menu")}</option>
                  {colorList.map((color, index) => {
                    return (
                      <option value={color.value} key={index}>
                        {t(color.title)}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3 col-md-3">
                <Form.Label>{t("Sound")}</Form.Label>
                <Form.Select
                  name="sound"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.sound}
                  aria-label="Default select example"
                  disabled={values.sended ? true : false}
                >
                  <option>{t("Open this select menu")}</option>
                  {soundList.map((sound, index) => {
                    return (
                      <option value={sound.value} key={index}>
                        {t(sound.title)}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              {type === "notification" ? (
                <Form.Group className="mb-3 col-md-3">
                  <Form.Label>{t("Category")}</Form.Label>
                  <Form.Select
                    name="category"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    aria-label="Default select example"
                    disabled={values.sended ? true : false}
                  >
                    <option>{t("Open this select menu")}</option>
                    {categoryList.map((category, index) => {
                      return (
                        <option value={category.categoryId} key={index}>
                          {t(category.title)}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              ) : null}
              <Form.Group className="mb-3 col-md-3">
                <Form.Label>{t("State")}</Form.Label>
                <Form.Select
                  name="active"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.active}
                  aria-label="Default select example"
                  disabled={values.sended ? true : false}
                >
                  <option>{t("Open this select menu")}</option>
                  {stateList.map((state, index) => {
                    return (
                      <option value={state.value} key={index}>
                        {t(state.title)}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              {type === "notification" && values.notificationId !== null ? (
                <ScopeForm values={values} user={user} />
              ) : null}
              <div className="text-center mt-3">
                {values.sended !== null && type === "notification" ? (
                  <Button
                    type="submit"
                    variant="danger"
                    size="lg"
                    disabled={values.sended ? true : false}
                  >
                    {t("Transmit", {
                      date: moment(values.sended).format("LLL"),
                    })}
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    disabled={isSubmitting}
                  >
                    {t("Save")}
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      ) : null}
    </>
  );
}

export default EditForm;
