import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "../../utils/axios";
import SelectHazardId from "./SelectHazardId";
import SelectZoneId from "./SelectZoneId";

function EditForm({ values, user, setShow }) {
  const { t } = useTranslation();

  var stateList = [
    { title: "Enabled", value: 1 },
    { title: "Disabled", value: 0 },
  ];

  const init = () => {
    if (Object.keys(values).length === 0) {
      return {
        hazardZoneId: null,
        title: "",
        subTitle: "",
        hazardId: "",
        zoneId: null,
        active: 1,
        submit: false,
      };
    } else {
      return {
        hazardZoneId: values["Zones.HazardsZones.hazardZoneId"],
        title: values.title,
        subTitle: values.subTitle,
        hazardId: values.hazardId,
        zoneId: values["Zones.zoneId"],
        active: values.active,
        submit: false,
      };
    }
  };

  return (
    <Formik
      initialValues={init()}
      validationSchema={Yup.object().shape({
        /*title: Yup.string()
          .min(3, t("less_char", { n: 12 }))
          .max(60)
          .required("Title is required"),
        subTitle: Yup.string().max(50),*/
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const params = {
            hazardZoneId: values.hazardZoneId,
            title: values.title,
            subTitle: values.subTitle,
            hazardId: values.hazardId,
            zoneId: values.zoneId,
            active: values.active,
          };

          if (params.dateEnd === "") {
            delete params.dateEnd;
          }

          if (params.hazardZoneId === null) {
            delete params.hazardZoneId;
            delete params.title;
            delete params.subTitle;
            axios
              .put(`hazards/hazardZone/insert`, {
                hazardId: params.hazardId,
                zoneId: params.zoneId,
                active: params.active,
                plan: null,
              })
              .then((response) => {
                console.log(response);
                setShow({ status: false, mode: 1 });
              })
              .catch((error) => console.log(error));
          } else {
            axios
              .put(`hazards/hazardZone/update`, params)
              .then((response) => {
                console.log(response);
              })
              .catch((error) => console.log(error));
          }
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit} className="row">
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              {errors.submit}
            </Alert>
          )}
          {values.hazardZoneId !== null ? (
            <>
              <Form.Group className="mb-3 col-md-3">
                <Form.Label>{t("Title")}</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  placeholder={t("Title")}
                  value={values.title}
                  disabled={values.sended ? true : false}
                  isInvalid={Boolean(touched.title && errors.title)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.title && (
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3 col-md-3">
                <Form.Label>{t("Subtitle")}</Form.Label>
                <Form.Control
                  type="text"
                  name="subTitle"
                  placeholder={t("Subtitle")}
                  value={values.subTitle}
                  disabled={values.sended ? true : false}
                  isInvalid={Boolean(touched.subTitle && errors.subTitle)}
                  onBlur={(e) => handleBlur(e)}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {!!touched.subTitle && (
                  <Form.Control.Feedback type="invalid">
                    {errors.subTitle}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </>
          ) : null}
          <Form.Group className="mb-3 col-md-2">
            <Form.Label>{t("Hazard Type")}</Form.Label>
            <SelectHazardId
              values={values}
              user={user}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-md-2">
            <Form.Label>{t("Area")}</Form.Label>
            <SelectZoneId
              values={values}
              user={user}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-md-2">
            <Form.Label>{t("State")}</Form.Label>
            <Form.Control
              as="select"
              name="active"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.active}
              aria-label="Default select example"
              disabled={values.sended ? true : false}
            >
              <option>{t("Open this select menu")}</option>
              {stateList.map((state, index) => {
                return (
                  <option value={state.value} key={index}>
                    {t(state.title)}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>

          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {values.hazardZoneId !== null ? t("Edit") : t("Save")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default EditForm;
