import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Card, Row, Col, Form, Alert, Button } from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import { AiOutlineRadarChart, AiOutlineLineChart } from "react-icons/ai";
import {
  BsInputCursorText,
  BsTextareaT,
  BsCalendar2Date,
  BsTelephone,
  Bs123,
  BsImage,
  BsFillPieChartFill,
  BsFillBarChartFill,
} from "react-icons/bs";
import {
  MdAlternateEmail,
  MdOutlineCheckBox,
  MdOutlineRadioButtonChecked,
  MdOutlineKey,
} from "react-icons/md";
import { TiDocumentText } from "react-icons/ti";
import {
  TbSquareRoundedPlus,
  TbSquareRoundedMinus,
  TbCategory2,
  TbChevronUp,
  TbChevronDown,
  TbListDetails,
  TbChartBarOff,
  TbChartDonut3,
} from "react-icons/tb";
import { TiChartPieOutline } from "react-icons/ti";

import axios from "../../utils/axios";
import moment from "moment";
require("moment/locale/fr");

const Fields = ({ values, user }) => {
  const { t } = useTranslation();
  const labelRef = useRef(null);
  const idKeyRef = useRef(null);

  var typeList = [
    { title: "Text", value: "text", icon: <BsInputCursorText /> },
    { title: "Textarea", value: "textarea", icon: <BsTextareaT /> },
    { title: "Number", value: "number", icon: <Bs123 /> },
    { title: "Email", value: "email", icon: <MdAlternateEmail /> },
    { title: "Tel", value: "tel", icon: <BsTelephone /> },
    { title: "Date", value: "date", icon: <BsCalendar2Date /> },
    { title: "Checkbox", value: "checkbox", icon: <MdOutlineCheckBox /> },
    { title: "Radio", value: "radio", icon: <MdOutlineRadioButtonChecked /> },
    { title: "Text Zone", value: "textzone", icon: <TiDocumentText /> },
    { title: "Logo", value: "logo", icon: <BsImage /> },
  ];

  var typeChart = [
    { title: "None", value: 0 },
    { title: "Bar", value: 1 },
    { title: "Pie", value: 2 },
    { title: "Line Chart", value: 3 },
    { title: "Radar", value: 4 },
    { title: "Polar", value: 5 },
    { title: "Doughnut", value: 7 },
    /* Traffic
    { title: "WorldMap", value: 6 },*/
  ];

  var groupChart = [
    { title: "None", value: 0 },
    { title: "Group 1", value: 1 },
    { title: "Group 2", value: 2 },
    { title: "Group 3", value: 3 },
    { title: "Group 4", value: 4 },
    { title: "Group 5", value: 5 },
  ];

  const getIcon = (type) => {
    let item = typeList.filter((e) => e.value === type);
    return item[0].icon;
  };

  const getTitle = (type) => {
    let item = typeList.filter((e) => e.value === type);
    return item[0].title;
  };
  const [formType, setFormType] = useState(null);
  const [fieldsList, setFieldsList] = useState(
    values.fields !== null ? JSON.parse(values.fields) : []
  );

  const [itemMap, setItemMap] = useState({
    title: "",
    email: "",
    dateStart: "",
    timeStart: "",
    dateEnd: "",
    timeEnd: "",
    fillColor: "#563d7c",
    opacity: 50,
    borderColor: "#563d7c",
    borderSize: 1,
    type: "",
    hazardIds: [],
    submit: false,
    action: "insert",
    channelId: values.channelId,
  });

  const init = () => {
    setItemMap({
      title: "",
      email: "",
      dateStart: "",
      timeStart: "",
      dateEnd: "",
      timeEnd: "",
      fillColor: "#563d7c",
      opacity: 50,
      borderColor: "#563d7c",
      borderSize: 1,
      type: "",
      submit: false,
      action: "insert",
      channelId: values.channelId,
    });
  };

  const setUpdate = (field, index) => {
    let selectFormType = typeList.filter((e) => e.value === field.type)[0];

    setFormType(selectFormType);
    setItemMap({
      label: field.label,
      idKey: field.idKey,
      content: field.options.content,
      responses: field.options.responses,
      dateStart:
        field.options.dateStart !== ""
          ? moment(field.options.dateStart, "YYYY-MM-DD HH:mm:ss").format(
              "YYYY-MM-DD"
            )
          : "",
      timeStart:
        field.options.dateStart !== ""
          ? moment(field.options.dateStart, "YYYY-MM-DD HH:mm:ss").format("LT")
          : "",
      dateEnd:
        field.options.dateEnd !== ""
          ? moment(field.options.dateEnd, "YYYY-MM-DD HH:mm:ss").format(
              "YYYY-MM-DD"
            )
          : "",
      timeEnd:
        field.options.dateEnd !== ""
          ? moment(field.options.dateEnd, "YYYY-MM-DD HH:mm:ss").format("LT")
          : "",
      min: field.options.min,
      max: field.options.max,
      chart:
        typeof field.options.chart !== "undefined" ? field.options.chart : "",
      sizeChart:
        typeof field.options.sizeChart !== "undefined"
          ? field.options.sizeChart
          : 6,
      sizeTable:
        typeof field.options.sizeTable !== "undefined"
          ? field.options.sizeTable
          : 0,
      groupChart:
        typeof field.options.groupChart !== "undefined"
          ? field.options.groupChart
          : 0,
      type: field.type,
      submit: false,
      action: "update",
      index: index,
      channelId: values.channelId,
    });
  };

  const handleAlias2 = (str) => {
    var analyse = str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/([^\w]+|\s+)/g, "-") // Replace space and other characters by hyphen
      .replace(/--+/g, "-") // Replaces multiple hyphens by one hyphen
      .replace(/(^-+|-+$)/g, "") // Remove extra hyphens from beginning or end of the string
      .toLowerCase();

    var idKeysArray = [];

    let a = fieldsList.map((field) => [...idKeysArray, field.idKey]).flat();

    if (a.some((e) => e === analyse) && itemMap.action === "insert") {
      analyse = `${analyse}-${randomId(2)}`;
    }

    return analyse;
  };

  const randomId = function (length = 6) {
    return Math.random()
      .toString(36)
      .substring(2, length + 2);
  };

  const handleAlias = (e) => {
    let str = e.target.value;
    e.target.value = handleAlias2(str);
  };

  const handleMoveElement = (index, direction) => {
    var arr = [...fieldsList];

    var toIndex;
    if (direction === "up") {
      toIndex = index - 1;
    } else {
      toIndex = index + 1;
    }
    var element = fieldsList[index];
    arr.splice(index, 1);
    arr.splice(toIndex, 0, element);

    let fields = JSON.stringify(arr);
    let p = {
      channelId: values.channelId,
      fields,
    };

    axios
      .put(`/channels/update`, p)
      .then(() => {
        setFieldsList(arr);
        setFormType(null);
        init();
      })
      .catch((error) => console.log(error));
  };

  const removeHandle = (idKey) => {
    let trash = fieldsList.filter((item) => item.idKey !== idKey);

    let fields = JSON.stringify(trash);
    let p = {
      channelId: values.channelId,
      fields,
    };

    axios
      .put(`/channels/update`, p)
      .then(() => {
        setFieldsList(trash);
        setFormType(null);
        init();
      })
      .catch((error) => console.log(error));
  };

  const SelectType = () => {
    return (
      <Row>
        {typeList.map((type, index) => (
          <Col
            key={index}
            onClick={() => setFormType(type)}
            className="rounded bg-light m-1 justify-center text-center"
          >
            <Row>
              <Col className="my-2 fs-1 fw-bold">{type.icon}</Col>
            </Row>
            <Row>
              <Col className="text-nowrap">{t(type.title)}</Col>
            </Row>
          </Col>
        ))}
      </Row>
    );
  };

  const FormMaker = () => {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={itemMap}
        validationSchema={Yup.object().shape({
          label: Yup.string()
            .min(3, t("less_char", { n: 3 }))
            .max(50)
            .required("Label is required"),
          idKey: Yup.string()
            .min(3, t("less_char", { n: 3 }))
            .max(40)
            .required("ID is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const params = {
              label: values.label,
              idKey: values.idKey,
              type: formType.value,
              options: {
                content: values.content,
                responses: values.responses,
                min: values.min,
                max: values.max,
                chart: values.chart,
                sizeChart: values.sizeChart,
                sizeTable: values.sizeTable,
                groupChart: values.groupChart,
                dateStart:
                  values.dateStart !== ""
                    ? `${values.dateStart} ${
                        values.timeStart !== "" ? values.timeStart : "00:00"
                      } ${user.utc}`
                    : "",
                dateEnd:
                  values.dateEnd !== ""
                    ? `${values.dateEnd} ${
                        values.timeEnd !== "" ? values.timeEnd : "00:00"
                      } ${user.utc}`
                    : "",
              },
              action: values.action,
            };

            var fields = [];

            if (params.action === "insert") {
              fields = JSON.stringify([...fieldsList, params]);
            } else if (params.action === "update") {
              var updateFields = [...fieldsList];
              updateFields[values.index] = params;
              fields = JSON.stringify(updateFields);
            }

            params.channelId = values.channelId;

            let p = {
              channelId: params.channelId,
              fields,
            };
            axios
              .put(`/channels/update`, p)
              .then(() => {
                if (params.action === "insert") {
                  setFieldsList([...fieldsList, params]);
                } else {
                  setFieldsList(updateFields);
                }
                setFormType(null);
                setStatus({ success: true });
                init();
              })
              .catch((error) => setErrors({ submit: error }));
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit} className="row">
            {errors.submit && (
              <Alert className="my-3 p-1" variant="danger">
                {errors.submit}
              </Alert>
            )}
            <Form.Group className="mb-3 col-lg-7 col-md-7">
              <Form.Label>{t("Label")}</Form.Label>
              <Form.Control
                ref={labelRef}
                type="text"
                name="label"
                placeholder={t("Label")}
                value={values.label}
                disabled={isSubmitting}
                isInvalid={Boolean(touched.label && errors.label)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.label && (
                <Form.Control.Feedback type="invalid">
                  {errors.label}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3 col-lg-5 col-md-5">
              <Form.Label>{t("ID Key")}</Form.Label>
              <Form.Control
                ref={idKeyRef}
                type="text"
                name="idKey"
                placeholder={t("ID Key")}
                value={values.idKey}
                disabled={isSubmitting}
                isInvalid={Boolean(touched.idKey && errors.idKey)}
                onBlur={(e) => {
                  handleBlur(e);
                }}
                onChange={(e) => {
                  handleAlias(e);
                  handleChange(e);
                }}
                onFocus={(e) => {
                  if (e.target.value === "") {
                    idKeyRef.current.value = handleAlias2(
                      labelRef.current.value
                    );
                    handleChange(e);
                  }
                }}
              />
              {!!touched.idKey && (
                <Form.Control.Feedback type="invalid">
                  {errors.idKey}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            {formType.value === "number" ? (
              <>
                <Form.Group className="mb-3 col-lg-6 col-md-6">
                  <Form.Label>{t("Min")}</Form.Label>
                  <Form.Control
                    name="min"
                    type="number"
                    value={values.min}
                    isInvalid={Boolean(touched.min && errors.min)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                </Form.Group>

                <Form.Group className="mb-3 col-lg-6 col-md-6">
                  <Form.Label>{t("Max")}</Form.Label>
                  <Form.Control
                    name="max"
                    type="number"
                    value={values.max}
                    isInvalid={Boolean(touched.max && errors.max)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                </Form.Group>
                <Row>
                  <Col className="mt-4 mb-2">
                    <h4>{t("Analytics")}</h4>
                  </Col>
                </Row>
                <Form.Group className="mb-3 col-lg-6 col-md-6">
                  <Form.Label>{t("Chart Type")}</Form.Label>

                  <Form.Select
                    name="chart"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    defaultValue={values.chart}
                    aria-label="Chart select"
                    disabled={isSubmitting}
                  >
                    {typeChart.map((chart, index) => {
                      return (
                        <option value={parseInt(chart.value)} key={index}>
                          {t(chart.title)}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3 col-lg-4 col-md-4">
                  <Form.Label>{t("Group")}</Form.Label>

                  <Form.Select
                    name="groupChart"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    defaultValue={parseInt(values.groupChart)}
                    aria-label="Chart select"
                    disabled={isSubmitting}
                  >
                    {groupChart.map((group, index) => {
                      return (
                        <option value={group.value} key={index}>
                          {t(group.title)}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3 col-lg-2 col-md-2">
                  <Form.Label>{t("Size")}</Form.Label>

                  <Form.Select
                    name="sizeChart"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    defaultValue={values.sizeChart}
                    aria-label="Chart Size select"
                    disabled={isSubmitting}
                  >
                    <option value={12}>12</option>
                    <option value={8}>8</option>
                    <option value={6}>6</option>
                    <option value={4}>4</option>
                    <option value={2}>2</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3 col-md-&2">
                  <Form.Label>
                    {t("Table Size")}:{" "}
                    <span className="fw-bold">{values.sizeTable}</span>
                  </Form.Label>

                  <Form.Range
                    name="sizeTable"
                    min="0"
                    max="100"
                    step="5"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    defaultValue={values.sizeTable}
                    disabled={isSubmitting}
                  />
                </Form.Group>
              </>
            ) : formType.value === "text" || formType.value === "textarea" ? (
              <>
                <Form.Group className="mb-3 col-lg-6 col-md-6">
                  <Form.Label>{t("Min")}</Form.Label>
                  <Form.Control
                    name="min"
                    type="number"
                    value={values.min}
                    isInvalid={Boolean(touched.min && errors.min)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                </Form.Group>

                <Form.Group className="mb-3 col-lg-6 col-md-6">
                  <Form.Label>{t("Max")}</Form.Label>
                  <Form.Control
                    name="max"
                    type="number"
                    value={values.max}
                    isInvalid={Boolean(touched.max && errors.max)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                </Form.Group>
              </>
            ) : formType.value === "logo" ? (
              <></>
            ) : formType.value === "radio" || formType.value === "checkbox" ? (
              <>
                <Form.Group className="mb-3 col-sm-12">
                  <Form.Label>{t("Options separated by coma")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="responses"
                    placeholder={t("Options separated by coma")}
                    value={values.responses}
                    disabled={isSubmitting}
                    isInvalid={Boolean(touched.responses && errors.responses)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Row>
                  <Col className="mt-4 mb-2">
                    <h4>{t("Analytics")}</h4>
                  </Col>
                </Row>
                <Form.Group className="mb-3 col-lg-6 col-md-6">
                  <Form.Label>{t("Chart Type")}</Form.Label>

                  <Form.Select
                    name="chart"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    defaultValue={values.chart}
                    aria-label="Chart select"
                    disabled={isSubmitting}
                  >
                    {typeChart.map((chart, index) => {
                      return (
                        <option value={parseInt(chart.value)} key={index}>
                          {t(chart.title)}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3 col-lg-4 col-md-4">
                  <Form.Label>{t("Group")}</Form.Label>

                  <Form.Select
                    name="groupChart"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    defaultValue={parseInt(values.groupChart)}
                    aria-label="Chart select"
                    disabled={isSubmitting}
                  >
                    {groupChart.map((group, index) => {
                      return (
                        <option value={group.value} key={index}>
                          {t(group.title)}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3 col-lg-2 col-md-2">
                  <Form.Label>{t("Size")}</Form.Label>

                  <Form.Select
                    name="sizeChart"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    defaultValue={values.sizeChart}
                    aria-label="Chart Size select"
                    disabled={isSubmitting}
                  >
                    <option value={12}>12</option>
                    <option value={8}>8</option>
                    <option value={6}>6</option>
                    <option value={4}>4</option>
                    <option value={2}>2</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3 col-md-&2">
                  <Form.Label>
                    {t("Table Size")}:{" "}
                    <span className="fw-bold">{values.sizeTable}</span>
                  </Form.Label>

                  <Form.Range
                    name="sizeTable"
                    min="0"
                    max="100"
                    step="5"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    defaultValue={values.sizeTable}
                    disabled={isSubmitting}
                  />
                </Form.Group>
              </>
            ) : formType.value === "textzone" ? (
              <>
                <Form.Group className="mb-3 col-sm-12">
                  <Form.Label>{t("Content")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="content"
                    placeholder={t("Content")}
                    value={values.content}
                    disabled={isSubmitting}
                    isInvalid={Boolean(touched.content && errors.content)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.content && (
                    <Form.Control.Feedback type="invalid">
                      {errors.content}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </>
            ) : formType.value === "date" ? (
              <>
                <Form.Group className="mb-3 col-lg-8 col-md-8">
                  <Form.Label>{t("Start Date")}</Form.Label>
                  <Form.Control
                    name="dateStart"
                    type="date"
                    value={values.dateStart}
                    isInvalid={Boolean(touched.min && errors.min)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                </Form.Group>
                <Form.Group className="mb-3 col-md-4">
                  <Form.Label>{t("Time")}</Form.Label>
                  <Form.Control
                    type="time"
                    name="timeStart"
                    value={values.timeStart}
                    isInvalid={Boolean(touched.timeStart && errors.timeStart)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.timeStart && (
                    <Form.Control.Feedback type="invalid">
                      {errors.timeStart}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 col-lg-8 col-md-8">
                  <Form.Label>{t("End Date")}</Form.Label>
                  <Form.Control
                    name="dateEnd"
                    type="date"
                    value={values.dateEnd}
                    isInvalid={Boolean(touched.max && errors.max)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                </Form.Group>
                <Form.Group className="mb-3 col-md-4">
                  <Form.Label>{t("Time")}</Form.Label>
                  <Form.Control
                    type="time"
                    name="timeEnd"
                    value={values.timeEnd}
                    isInvalid={Boolean(touched.timeEnd && errors.timeEnd)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.timeEnd && (
                    <Form.Control.Feedback type="invalid">
                      {errors.timeEnd}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </>
            ) : null}

            <Form.Group className="col-12 mt-3">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isSubmitting}
              >
                {itemMap.action === "insert" ? t("Add") : t("Edit")}
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title>{t("Form Fields")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Row className=" me-2">
              {fieldsList && typeof fieldsList === "object" ? (
                fieldsList.map((field, index) => (
                  <Col
                    className={`p-2 mx-2 col-sm-12 rounded bg-light mb-2`}
                    key={index}
                  >
                    <Row>
                      <Col className="fs-3 col-sm-1">{getIcon(field.type)}</Col>
                      <Col
                        className="fs-4 fw-bold col-sm-8"
                        onClick={() => setUpdate(field, index)}
                      >
                        {field.label}
                      </Col>
                      {index !== 0 ? (
                        <Col
                          className="fs-5 fw-bold col-sm-1"
                          onClick={() => handleMoveElement(index, "up")}
                        >
                          <TbChevronUp className="fs-4 mb-1 ms-2 me-1" />
                        </Col>
                      ) : (
                        <Col className="fs-5 fw-bold col-sm-1"></Col>
                      )}
                      {index !== fieldsList.length - 1 ? (
                        <Col
                          className="fs-5 fw-bold col-sm-1"
                          onClick={() => handleMoveElement(index, "down")}
                        >
                          <TbChevronDown className="fs-4 mb-1 ms-2 me-1" />
                        </Col>
                      ) : (
                        <Col className="fs-5 fw-bold col-sm-1"></Col>
                      )}
                    </Row>
                    <Row>
                      <Col className="text-sm col-sm-12">
                        <TbCategory2 className="fs-4 me-1" />
                        {t(getTitle(field.type))}{" "}
                        {field.type === "text" ||
                        field.type === "textarea" ||
                        field.type === "number" ? (
                          <>
                            <TbSquareRoundedMinus className="fs-4 mb-1 ms-2 me-1" />
                            {field.options.min !== ""
                              ? field.options.min
                              : t("None")}
                            {"  "}
                            <TbSquareRoundedPlus className="fs-4 mb-1 ms-2 me-1" />
                            {field.options.max !== ""
                              ? field.options.max
                              : t("None")}
                          </>
                        ) : field.type === "date" ? (
                          <>
                            <TbSquareRoundedMinus className="fs-4 mb-1 ms-2 me-1" />
                            {field.options.dateStart !== ""
                              ? moment(
                                  field.options.dateStart,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format("LL LT")
                              : t("None")}
                            {"  "}
                            {}
                            <TbSquareRoundedPlus className="fs-4 mb-1 ms-2 me-1" />
                            {field.options.dateEnd !== ""
                              ? moment(
                                  field.options.dateEnd,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format("LL LT")
                              : t("None")}
                          </>
                        ) : field.type === "radio" ||
                          field.type === "checkbox" ? (
                          <>
                            <TbListDetails className="fs-4 mb-1 ms-2 me-1" />
                            {field.options.responses !== ""
                              ? field.options.responses.split(",").length
                              : t("None")}
                          </>
                        ) : null}{" "}
                        <MdOutlineKey className="fs-4 mb-1 ms-2 me-1" />
                        {field.idKey}
                        {typeof field.options.chart !== "undefined" &&
                        parseInt(field.options.chart) === 0 ? (
                          <TbChartBarOff className="fs-4 mb-1 ms-2 me-1" />
                        ) : parseInt(field.options.chart) === 1 ? (
                          <BsFillBarChartFill className="fs-4 mb-1 ms-2 me-1" />
                        ) : parseInt(field.options.chart) === 2 ? (
                          <BsFillPieChartFill className="fs-4 mb-1 ms-2 me-1" />
                        ) : parseInt(field.options.chart) === 3 ? (
                          <AiOutlineLineChart className="fs-4 mb-1 ms-2 me-1" />
                        ) : parseInt(field.options.chart) === 4 ? (
                          <AiOutlineRadarChart className="fs-4 mb-1 ms-2 me-1" />
                        ) : parseInt(field.options.chart) === 5 ? (
                          <TiChartPieOutline className="fs-4 mb-1 ms-2 me-1" />
                        ) : parseInt(field.options.chart) === 7 ? (
                          <TbChartDonut3 className="fs-4 mb-1 ms-2 me-1" />
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                ))
              ) : (
                <Col>{t("No field")}</Col>
              )}
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <h3>{t("Form")}</h3>
                <Row>
                  <Col>
                    {formType !== null ? (
                      <Button
                        className="mt-1 mb-3 m-1 rounded "
                        type="submit"
                        variant="primary"
                        size="sm"
                        disabled={
                          itemMap.action === "update" ||
                          formType.value !== "multipolygon"
                            ? false
                            : true
                        }
                        onClick={() => {
                          init();
                          setFormType(null);
                        }}
                      >
                        {t("New")}
                      </Button>
                    ) : (
                      <p>Choisir un élément à ajouter</p>
                    )}

                    {itemMap.action === "update" ? (
                      <Button
                        className="mt-1 mb-3 m-3"
                        type="submit"
                        variant="danger"
                        size="sm"
                        disabled={itemMap.action === "update" ? false : true}
                        onClick={() => removeHandle(itemMap.idKey)}
                      >
                        {t("Delete")}
                      </Button>
                    ) : null}
                  </Col>
                </Row>
                {formType !== null ? (
                  <Row>
                    <Col className="fs-3 mb-2">
                      {formType.icon} {t(formType.title)}
                    </Col>
                  </Row>
                ) : null}
                {formType !== null ? <FormMaker /> : <SelectType />}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Fields;
